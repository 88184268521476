// Import the initDB function to initialize the database connection
import { initDB } from "../config/initDB";
import notifyDirectoryChanged from "../events/directoryChanged";
/**
 * Save the directory data received from the API to the database.
 * @param {string} parent - The reference of the directory to save the data for.
 * @param {Object} data - The complete data received from the API.
 * @returns {Promise<void>}
 */
export const saveDirectoryData = async (parent, data) => {
  try {
    // Validate the input types
    if (typeof parent !== "string" || parent.trim().length === 0) {
      throw new Error("Invalid type for parent. Expected a non-empty string.");
    }
    if (typeof data !== "object" || data === null) {
      throw new Error("Invalid type for data. Expected a non-null object.");
    }

    // Initialize the database connection
    const db = await initDB();

    // Prepare the data for saving, including using the parent as the key
    const directoryData = {
      parent: parent.trim(), // Use trimmed version of the directory as key
      data, // Save the entire data object
      last_refresh: new Date().toISOString(), // Save the current time as the refresh time
    };

    // Add or update the directory data in the "directories" store
    await db.put("directories", directoryData);
  } catch (error) {
    throw new Error(
      `Error saving directory data for '${parent}': ${error.message}`
    );
  }
};

/**
 * Retrieve the cached directory data for the given directory.
 * @param {string} parent - The reference of the directory to retrieve data for.
 * @returns {Promise<Object|null>} - Returns the cached data object, or null if not found.
 */
export const getDirectoryData = async (parent) => {
  try {
    // Validate the input type
    if (typeof parent !== "string" || parent.trim().length === 0) {
      throw new Error("Invalid type for parent. Expected a non-empty string.");
    }

    // Initialize the database connection
    const db = await initDB();

    // Retrieve the cached data for the given directory
    const cachedRecord = await db.get("directories", parent.trim());

    // Return the cached data if available, otherwise return null
    return cachedRecord || null;
  } catch (error) {
    throw new Error(
      `Error retrieving directory data for '${parent}': ${error.message}`
    );
  }
};

/**
 * Check if the refresh time for a given directory is still valid.
 * @param {string} parent - The reference of the directory to check the refresh time.
 * @returns {Promise<boolean>} - Returns true if refresh time is older than 5 minutes, false otherwise.
 */
export const checkRefreshTime = async (parent) => {
  try {
    const cachedRecord = await getDirectoryData(parent);

    if (!cachedRecord) {
      return true; // No data, needs refresh
    }

    const lastRefresh = new Date(cachedRecord.last_refresh);
    const currentTime = new Date();

    // If data is older than 5 minutes, return true (needs refresh)
    return currentTime - lastRefresh > 0.5 * 60 * 1000;
  } catch (error) {
    throw new Error(
      `Error checking refresh time for '${parent}': ${error.message}`
    );
  }
};

/**
 * Add a new item to either the documents or directories of a given parent directory.
 * @param {string} parent - The reference of the directory to update.
 * @param {Object} newItem - The new item to add to the documents or directories.
 * @param {string} type - The type of item being added: "documents" or "directories".
 * @returns {Promise<void>}
 */
export const addItemToDirectory = async (parent, newItem, type) => {
  try {
    // Validate the input types
    if (typeof parent !== "string" || parent.trim().length === 0) {
      throw new Error("Invalid type for parent. Expected a non-empty string.");
    }
    if (typeof newItem !== "object" || newItem === null) {
      throw new Error("Invalid type for newItem. Expected a non-null object.");
    }
    if (type !== "documents" && type !== "directories") {
      throw new Error(
        "Invalid type for 'type'. Expected 'documents' or 'directories'."
      );
    }

    // Initialize the database connection
    const db = await initDB();

    // Retrieve the existing directory data
    const directoryRecord = await db.get("directories", parent.trim());

    if (!directoryRecord) {
      throw new Error(`Directory with parent '${parent}' not found.`);
    }

    // Update the specified property (either documents or directories)
    const updatedData = { ...directoryRecord };
    updatedData.data[type] = updatedData.data[type] || [];

    // Add the new item to the specified array
    updatedData.data[type].push(newItem);

    // Update the record in IndexedDB
    await db.put("directories", updatedData);

    // Notify listeners of directory change
    notifyDirectoryChanged();
  } catch (error) {
    throw new Error(
      `Error adding item to '${type}' for directory '${parent}': ${error.message}`
    );
  }
};

/**
 * Delete an item from either the documents or directories of a given parent directory.
 * @param {string} parent - The reference of the directory to update.
 * @param {string} itemRef - The REFERENCE of the item to delete from documents or directories.
 * @param {string} type - The type of item being deleted: "documents" or "directories".
 * @returns {Promise<void>}
 */
export const deleteItemFromDirectory = async (parent, itemRef, type) => {
  try {
    // Validate the input types
    if (typeof parent !== "string" || parent.trim().length === 0) {
      throw new Error("Invalid type for parent. Expected a non-empty string.");
    }
    if (typeof itemRef !== "string" || itemRef.trim().length === 0) {
      throw new Error("Invalid type for itemRef. Expected a non-empty string.");
    }
    if (type !== "documents" && type !== "directories") {
      throw new Error(
        "Invalid type for 'type'. Expected 'documents' or 'directories'."
      );
    }

    // Initialize the database connection
    const db = await initDB();

    // Retrieve the existing directory data
    const directoryRecord = await db.get("directories", parent.trim());

    if (!directoryRecord) {
      throw new Error(`Directory with parent '${parent}' not found.`);
    }

    // Update the specified property (either documents or directories)
    const updatedData = { ...directoryRecord };
    updatedData.data[type] = updatedData.data[type].filter(
      (item) => item.reference !== itemRef
    );

    // Update the record in IndexedDB
    await db.put("directories", updatedData);

    // Notify listeners of directory change
    notifyDirectoryChanged();
  } catch (error) {
    throw new Error(
      `Error deleting item from '${type}' for directory '${parent}': ${error.message}`
    );
  }
};

/**
 * Edit an existing item in either the documents or directories of a given parent directory.
 * @param {string} parent - The reference of the directory to update.
 * @param {string} itemRef - The REFERENCE of the item to edit.
 * @param {Object} updatedFields - The fields and their new values to update.
 * @param {string} type - The type of item being edited: "documents" or "directories".
 * @returns {Promise<void>}
 */
export const editItemInDirectory = async (
  parent,
  itemRef,
  updatedFields,
  type
) => {
  try {
    // Validate the input types
    if (typeof parent !== "string" || parent.trim().length === 0) {
      throw new Error("Invalid type for parent. Expected a non-empty string.");
    }
    if (typeof itemRef !== "string" || itemRef.trim().length === 0) {
      throw new Error("Invalid type for itemRef. Expected a non-empty string.");
    }
    if (typeof updatedFields !== "object" || updatedFields === null) {
      throw new Error(
        "Invalid type for updatedFields. Expected a non-null object."
      );
    }
    if (type !== "documents" && type !== "directories") {
      throw new Error(
        "Invalid type for 'type'. Expected 'documents' or 'directories'."
      );
    }

    // Initialize the database connection
    const db = await initDB();

    // Retrieve the existing directory data
    const directoryRecord = await db.get("directories", parent.trim());

    if (!directoryRecord) {
      throw new Error(`Directory with parent '${parent}' not found.`);
    }

    // Find and update the specified item
    const updatedData = { ...directoryRecord };
    updatedData.data[type] = updatedData.data[type].map((item) =>
      item.reference === itemRef ? { ...item, ...updatedFields } : item
    );

    // Update the record in IndexedDB
    await db.put("directories", updatedData);

    // Notify listeners of directory change
    notifyDirectoryChanged();
  } catch (error) {
    throw new Error(
      `Error editing item in '${type}' for directory '${parent}': ${error.message}`
    );
  }
};
