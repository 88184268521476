import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FaBuilding,
  FaUserTie,
  FaUser,
  FaAlignLeft,
  FaImage,
  FaGlobe,
  FaEnvelope,
  FaPhone,
  FaMobileAlt,
} from "react-icons/fa";
import LogoDropzone from "./LogoDropzone";

/**
 * StepGeneralInfo Component.
 *
 * Renders the first step of the multi-step form which includes general and contact information.
 * The Business Name and Description fields appear on the same row, while the Logo upload spans the full width.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.formData - The form data for the profile.
 * @param {function} props.handleChange - Callback to update the form data.
 * @returns {JSX.Element} The rendered step component.
 */
const StepGeneralInfo = ({ formData, handleChange }) => {
  // Health and Social Care categories for the description field
  const healthCategories = [
    "Domiciliary Care",
    "Residential Care",
    "Nursing Care",
    "Supported Living",
    "Community Care",
    "Mental Health Services",
    "Disability Services",
    "Learning Disability Services",
    "Home Health Care",
    "Rehabilitation Services",
    "Adult Day Services",
    "Social Work Services",
    "Palliative Care",
    "Hospice Care",
    "Ambulance Service",
    "NHS Primary Care (GP Services)",
    "NHS Secondary Care (Hospital Services)",
    "Allied Health Professionals (Physio, OT, etc.)",
    "Children's Services (CAMHS, Paediatric Care)",
    "Sexual Health Services",
    "Substance Misuse Services",
    "Pharmacy Services",
    "Telehealth/Telemedicine",
    "Respite Care",
    "Assistive Technology Services",
    "Crisis Intervention Services",
    "GP Out-of-Hours Services",
    "Public Health Services",
    "NHS Continuing Healthcare",
    "Occupational Therapy Services",
    "Speech and Language Therapy",
    "Maternity Services",
    "Veterans' Health Services",
  ];

  return (
    <>
      <h5 className="mb-3">
        <FaUser className="me-2" />
        General &amp; Contact Information
      </h5>

      {/* Row 1: Business Name and Description side by side */}
      <Row className="mb-3 mt-4">
        <Col md={6}>
          <Form.Group controlId="business_name" className="mb-3">
            <Form.Label>
              <FaBuilding className="me-1" />
              Business Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter business name"
              value={formData.business_name}
              onChange={(e) => handleChange("business_name", e.target.value)}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="description" className="mb-3">
            <Form.Label>
              <FaAlignLeft className="me-1" />
              Description
            </Form.Label>
            <Typeahead
              id="description-typeahead"
              options={healthCategories}
              placeholder="Select or type a category"
              onChange={(selected) =>
                handleChange("description", selected[0] || "")
              }
              selected={formData.description ? [formData.description] : []}
              allowNew
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 2: Logo upload spanning full width */}
      <Row className="mb-3">
        <Col md={12}>
          <Form.Group controlId="logo" className="mb-3">
            <Form.Label>
              <FaImage className="me-1" />
              Business Logo
            </Form.Label>
            <LogoDropzone
              file={formData.logo}
              onFileAccepted={(file) => handleChange("logo", file)}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 3: Manager and Nominated Individual */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="manager" className="mb-3">
            <Form.Label>
              <FaUserTie className="me-1" />
              Business Manager
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter manager name"
              value={formData.manager}
              onChange={(e) => handleChange("manager", e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="nominated_individual" className="mb-3">
            <Form.Label>
              <FaUser className="me-1" />
              Nominated Individual
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter nominated individual"
              value={formData.nominated_individual}
              onChange={(e) =>
                handleChange("nominated_individual", e.target.value)
              }
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 4: Website and Contact Email */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="website" className="mb-3">
            <Form.Label>
              <FaGlobe className="me-1" />
              Website
            </Form.Label>
            <Form.Control
              type="url"
              placeholder="Enter website URL"
              value={formData.website}
              onChange={(e) => handleChange("website", e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>
              <FaEnvelope className="me-1" />
              Contact Email
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter contact email"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 5: Phone and Mobile */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="phone" className="mb-3">
            <Form.Label>
              <FaPhone className="me-1" />
              Land Line
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter phone number"
              value={formData.phone}
              onChange={(e) => handleChange("phone", e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="mobile" className="mb-3">
            <Form.Label>
              <FaMobileAlt className="me-1" />
              Mobile Phone
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter mobile number"
              value={formData.mobile}
              onChange={(e) => handleChange("mobile", e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default StepGeneralInfo;
