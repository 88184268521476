import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { FaSquare, FaTrash, FaEdit } from "react-icons/fa";

const ChatHistory = ({ threads, activeThread, onNewChat, onThreadSelect, onDeleteThread }) => {
  return (
    <div>
      {/* Header */}
      <div style={{ background: "#fff", padding: "0.5rem 0", marginBottom: "1rem" }} className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Chat History</h5>
        <Button variant="outline-secondary" className="btn-tiny" onClick={onNewChat}>
          <FaEdit /> New Chat
        </Button>
      </div>
      {/* List */}
      <div style={{ overflowY: "auto", maxHeight: "calc(80vh - 3rem)" }} className="small">
        {threads.length > 0 ? (
          threads.map((thread, index) => (
            <div
              key={index}
              className="d-flex align-items-center border rounded p-2 mb-3"
              onMouseOver={(e) => {
                if (thread.threadId !== activeThread) e.currentTarget.style.backgroundColor = "#f0f0f0";
              }}
              onMouseOut={(e) => {
                if (thread.threadId !== activeThread) e.currentTarget.style.backgroundColor = "#fff";
              }}
              onClick={() => onThreadSelect(thread)}
              style={{ cursor: "pointer", backgroundColor: thread.threadId === activeThread ? "#d1e7dd" : "#fff" }}
            >
              <div className="me-2">
                <FaSquare className="text-muted" size={20} />
              </div>
              <span className="text-truncate" style={{ flex: 1, marginRight: "0.5rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={thread.title}>
                {thread.title}
              </span>
              <Button variant="outline-danger" size="sm" onClick={(e) => { e.stopPropagation(); onDeleteThread(thread.threadId); }}>
                <FaTrash />
              </Button>
            </div>
          ))
        ) : (
          <p className="text-center">No chat history available.</p>
        )}
      </div>
    </div>
  );
};

ChatHistory.propTypes = {
  threads: PropTypes.array.isRequired,
  activeThread: PropTypes.any,
  onNewChat: PropTypes.func.isRequired,
  onThreadSelect: PropTypes.func.isRequired,
  onDeleteThread: PropTypes.func.isRequired,
};

export default ChatHistory;
