import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FaCog } from "react-icons/fa";
import { useProcessing } from "../../context/ProcessingModal";
import { useReport } from "../../context/ReportModal";
import { runData } from "../../context/processor";

const Settings = () => {
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    userProfessionDetails: "",
    assistantTraits: "",
    userAdditionalInfo: "",
    customInstructions: "",
  });
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  useEffect(() => {
    const fetchSettings = async () => {
      setProcessing(true);
      try {
        const response = await runData({}, "/api/composer/settings/");

        if (response.status === 200 && response.data.settings) {
          const s = response.data.settings;
          // Convert null values to empty strings for the form
          setFormData({
            userName: s.userName || "",
            userProfessionDetails: s.userProfessionDetails || "",
            assistantTraits: s.assistantTraits || "",
            userAdditionalInfo: s.userAdditionalInfo || "",
            customInstructions: s.customInstructions || "",
          });
          setIsEditable(false);
        } else {
          setReport({
            show: true,
            message: response.data.message || "Failed to fetch settings.",
            type: "error",
          });
        }
      } catch (error) {
        setReport({
          show: true,
          message: "Error: " + error.message,
          type: "error",
        });
      } finally {
        setProcessing(false);
      }
    };

    fetchSettings();
  }, []); // Removed extra dependencies to run only once on mount

  const handleCustomizeChat = () => {
    setShowCustomizeModal(true);
  };

  const handleCloseCustomizeModal = () => {
    setShowCustomizeModal(false);
    setIsEditable(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await runData(formData, "/api/composer/settings/save/");
      if (response.status === 200) {
        setReport({
          show: true,
          message: "Settings updated successfully.",
          type: "success",
        });
        handleCloseCustomizeModal();
      } else {
        setReport({
          show: true,
          message: response.data.message || "Update failed.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: "Error: " + error.message,
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        onClick={handleCustomizeChat}
        title="Customize Chat"
      >
        <FaCog />
      </Button>

      <Modal
        show={showCustomizeModal}
        onHide={handleCloseCustomizeModal}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Customize Your Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                placeholder="Name"
                disabled={!isEditable}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Profession</Form.Label>
              <Form.Control
                type="text"
                name="userProfessionDetails"
                value={formData.userProfessionDetails}
                onChange={handleChange}
                placeholder="Your profession"
                disabled={!isEditable}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>What traits should the composer have?</Form.Label>
              <Form.Control
                as="textarea"
                name="assistantTraits"
                rows={3}
                value={formData.assistantTraits}
                onChange={handleChange}
                placeholder="Be formal. Provide detailed responses."
                disabled={!isEditable}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Additional Information (e.g., location, interests)
              </Form.Label>
              <Form.Control
                type="text"
                name="userAdditionalInfo"
                value={formData.userAdditionalInfo}
                onChange={handleChange}
                placeholder="Additional information"
                disabled={!isEditable}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Custom Instructions (if any)</Form.Label>
              <Form.Control
                as="textarea"
                name="customInstructions"
                rows={3}
                value={formData.customInstructions}
                onChange={handleChange}
                placeholder="Optional custom instructions"
                disabled={!isEditable}
              />
            </Form.Group>
            <Modal.Footer className="d-flex justify-content-between">
              {isEditable ? (
                <>
                  <Button 
                    variant="secondary" 
                    onClick={handleCloseCustomizeModal}
                    type="button" // Prevents accidental submit
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button 
                    variant="secondary" 
                    onClick={handleCloseCustomizeModal}
                    type="button" // Prevents submit on close
                  >
                    Close
                  </Button>
                  <Button 
                    variant="outline-primary"
                    onClick={(e) => { e.preventDefault(); setIsEditable(true); }} // Prevent submit and enable edit
                    type="button"
                  >
                    Edit
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Settings;
