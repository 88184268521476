import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaUserCheck, FaUserSlash, FaTimes } from "react-icons/fa";
import PropTypes from "prop-types";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

/**
 * ToggleStatus component renders a self-contained modal for toggling a user's status.
 * It can be used as <ToggleStatus customer={customer} />.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.customer - The customer object.
 * @returns {JSX.Element} The ToggleStatus modal component.
 */
const ToggleStatus = ({ customer }) => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [modalShow, setModalShow] = useState(false);

  /**
   * Handles toggling the customer's status via an API call.
   *
   * @async
   * @function handleToggle
   */
  const handleToggle = async () => {
    setModalShow(false);
    setProcessing(true);
    try {
      // Use the customer reference in the URL.
      const response = await runData(
        {},
        `/api/customers/${customer.reference}/toggle-status/`
      );
      if (response.status === 200) {
        setReport({
          show: true,
          message: "Status updated.",
          type: "success",
        });
        window.dispatchEvent(new Event("customers:reload"));
      } else {
        setReport({
          show: true,
          message: response?.data?.message || "Error updating status.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: "Error: " + error.message,
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant={customer.status === 1 ? "warning" : "success"}
        size="sm"
        style={{ flex: 1 }}
        onClick={() => setModalShow(true)}
      >
        {customer.status === 1 ? (
          <>
            <FaUserSlash className="me-1" />
            Suspend
          </>
        ) : (
          <>
            <FaUserCheck className="me-1" />
            Activate
          </>
        )}
      </Button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {customer.status === 1
              ? "Confirm Suspend Customer"
              : "Confirm Activate Customer"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {customer.status === 1 ? "suspend" : "activate"} {customer.name}?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            <FaTimes className="me-1" />
            Cancel
          </Button>
          <Button
            variant={customer.status === 1 ? "warning" : "success"}
            onClick={handleToggle}
          >
            {customer.status === 1 ? "Suspend" : "Activate"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ToggleStatus.propTypes = {
  customer: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
};

export default ToggleStatus;
