import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { FaArrowRight, FaArrowLeft, FaTimes, FaCheck } from "react-icons/fa";
import StepGeneralInfo from "./StepGeneralInfo";
import StepAddressInfo from "./StepAddressInfo";
import StepAuthorityInfo from "./StepAuthorityInfo";

/**
 * MultiStepProfileModal Component.
 *
 * Renders a multi-step modal form for creating a company profile.
 * The form is divided into three steps:
 *   1. General & Contact Information
 *   2. Address Information
 *   3. Authority Information
 *
 * All fields are required except for the Authority Representative.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls modal visibility.
 * @param {function} props.onHide - Callback to hide the modal.
 * @param {function} props.onSubmit - Callback when the form is submitted.
 * @param {Object} props.formData - The form data for the profile.
 * @param {function} props.setFormData - Callback to update the form data.
 * @returns {JSX.Element} The rendered MultiStepProfileModal component.
 */
const MultiStepProfileModal = ({
  show,
  onHide,
  onSubmit,
  formData,
  setFormData,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const stepsCount = 3;

  /**
   * Reset current step to 0 when the modal is opened.
   */
  useEffect(() => {
    if (show) {
      setCurrentStep(0);
    }
  }, [show]);

  /**
   * Generic change handler for updating form data.
   *
   * @param {string} field - The field name to update.
   * @param {any} value - The new value for the field.
   */
  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  /**
   * Validates whether all required fields are filled.
   * The only optional field is Authority Representative.
   *
   * @returns {boolean} True if the form is valid, otherwise false.
   */
  const isFormValid = () => {
    return (
      formData.business_name &&
      formData.description &&
      formData.email &&
      formData.address &&
      formData.city &&
      formData.region &&
      formData.county &&
      formData.postcode &&
      formData.logo && // Logo must be provided
      formData.authority_name &&
      formData.authority_address &&
      formData.authority_phone &&
      formData.authority_email &&
      formData.authority_website
    );
  };

  /**
   * Handles navigation to the next step or submits the form on the final step.
   *
   * @param {Event} e - The event object.
   */
  const handleNext = (e) => {
    e.preventDefault();
    if (currentStep < stepsCount - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      // Only submit if the form is valid
      if (isFormValid()) {
        onSubmit(e);
      }
    }
  };

  /**
   * Handles navigation to the previous step.
   *
   * @param {Event} e - The event object.
   */
  const handleBack = (e) => {
    e.preventDefault();
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  /**
   * Renders the appropriate step component based on the current step.
   *
   * @returns {JSX.Element|null} The rendered step component.
   */
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepGeneralInfo formData={formData} handleChange={handleChange} />
        );
      case 1:
        return (
          <StepAddressInfo formData={formData} handleChange={handleChange} />
        );
      case 2:
        return (
          <StepAuthorityInfo formData={formData} handleChange={handleChange} />
        );
      default:
        return null;
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Create New Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {renderStep()}
          <div className="d-flex justify-content-between mt-4">
            <div>
              {currentStep > 0 ? (
                <Button variant="secondary" onClick={handleBack}>
                  <FaArrowLeft className="me-1" />
                  Back
                </Button>
              ) : (
                <Button variant="secondary" onClick={onHide}>
                  <FaTimes className="me-1" />
                  Cancel
                </Button>
              )}
            </div>
            <div>
              <Button
                variant="success"
                onClick={handleNext}
                disabled={currentStep === stepsCount - 1 && !isFormValid()}
              >
                {currentStep === stepsCount - 1 ? (
                  <>
                    <FaCheck className="me-1" />
                    Create Profile
                  </>
                ) : (
                  <>
                    Next
                    <FaArrowRight className="ms-1" />
                  </>
                )}
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MultiStepProfileModal;
