/**
 * @file NavigationHeader.jsx
 * @description A component rendering the navigation header with a back button,
 * title, description, and a create profile action.
 */

import React from "react";
import { NavLink } from "react-router-dom";
import { Col } from "react-bootstrap";
import { FaAngleLeft, FaBriefcase } from "react-icons/fa";
import CreateProfile from "./ProfileModal"; // Assuming CreateProfile is exported from ProfileModal

/**
 * NavigationHeader component renders the page header.
 *
 * @component
 * @returns {JSX.Element} The rendered navigation header.
 */
const NavigationHeader = () => {
  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
        <NavLink
          title="Back"
          className="active"
          style={{
            textDecoration: "none",
            color: "inherit",
            borderRight: "solid 2px red",
            marginRight: "10px",
          }}
          to={`/`}
        >
          <FaAngleLeft className="text-primary" /> Back
        </NavLink>
        <Col className="text-start">
          <h2 className="ms-2">
            Company Profiles <FaBriefcase className="ms-2" />
          </h2>
          <p className="ms-2">
            Manage company profiles and associated information.
          </p>
        </Col>
      </div>
      {/* The CreateProfile component dispatches the "profiles:reload" event on success */}
      <CreateProfile />
    </div>
  );
};

export default NavigationHeader;
