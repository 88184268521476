import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";
import { FaEdit, FaImage, FaTimes, FaSave } from "react-icons/fa";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { runData } from "../context/processor";
import LogoDropzone from "./creator/LogoDropzone";

/**
 * @file EditLogo.jsx
 * @description A reusable component that displays an edit icon for the logo.
 * When clicked, it opens a centered and static modal to update the logo using a dropzone.
 * Upon saving, it makes its own API call via runData and dispatches a custom event to reload profiles.
 */
const EditLogo = ({ profile }) => {
  const [show, setShow] = useState(false);
  // We'll store the selected file using a dropzone.
  const [logoFile, setLogoFile] = useState(null);

  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  useEffect(() => {
    // When profile changes, reset the logoFile.
    setLogoFile(null);
  }, [profile]);

  // The form is valid only if a logo file is selected.
  const isFormValid = logoFile !== null;

  /**
   * Handles saving the updated logo.
   *
   * Closes the modal immediately, shows a processing modal, and makes an API call via runData.
   * Depending on the outcome, it then displays a report modal with a success or error message.
   */
  const handleSave = async () => {
    setShow(false);
    setProcessing(true);
    try {
      // For file uploads, your API might expect a FormData object.
      // Here, we assume runData handles the file appropriately.
      const response = await runData(
        { logo: logoFile },
        `/api/company/${profile.reference}/update-logo/`
      );
      if (response.status === 200) {
        window.dispatchEvent(new CustomEvent("profiles:reload"));
        setReport({
          show: true,
          message: "Logo updated successfully.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message: response?.data?.message || "Failed to update logo",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error updating logo",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button variant="link" onClick={() => setShow(true)} title="Edit Logo">
        <FaEdit />
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="logoFile" className="mb-3">
              <Form.Label>
                <FaImage className="me-1" />
                Logo
              </Form.Label>
              <LogoDropzone file={logoFile} onFileAccepted={setLogoFile} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setShow(false)}>
            <FaTimes className="me-1" />
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!isFormValid}
          >
            <FaSave className="me-1" />
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditLogo.propTypes = {
  profile: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    logo: PropTypes.string,
  }).isRequired,
};

export default EditLogo;
