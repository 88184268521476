/**
 * @file CompanyPage.jsx
 * @description A React component for managing company profiles with CRUD operations.
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import NavigationHeader from "./NavigationHeader";
import ProfileCard from "./ProfileCard";
import CompanyPagination from "./CompanyPagination";
import ActionModal from "./ActionModal";
import ViewModal from "./ViewModal";

import { useUser } from "../hooks/useUser";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import { runData } from "../context/processor";
import CreateProfile from "./ProfileModal";

/**
 * CompanyPage component manages the display and actions on company profiles.
 *
 * @component
 * @returns {JSX.Element} The CompanyPage component.
 */
const CompanyPage = () => {
  // Context hooks for user, processing, and reporting
  const { user } = useUser();
  const navigate = useNavigate();
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  // Local state management
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [actionType, setActionType] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [fetchError, setFetchError] = useState(null);
  const [loading, setLoading] = useState(true);

  const profilesPerPage = 6;

  // Slot validation: Redirect users without the required slot
  useEffect(() => {
    if (user?.slot && ![1, 2, 3, 7, 8].includes(user.slot)) {
      navigate("/");
    }
  }, [user, navigate]);

  /**
   * Loads the list of profiles from the API using runData.
   *
   * @async
   * @function loadProfiles
   */
  const loadProfiles = async () => {
    setProcessing(true);
    setLoading(true);
    try {
      const response = await runData({}, "/api/company/");
      if (response.status === 200) {
        // Assuming the API returns an array of profiles in response.data.profiles
        setProfiles(response.data.profiles);
        setFetchError(null); // Clear any previous error on success
      } else {
        setFetchError(response?.data?.message || "Failed to load profiles.");
      }
    } catch (error) {
      setFetchError(
        error.message || "Unexpected error while loading profiles."
      );
    } finally {
      setProcessing(false);
      setLoading(false);
    }
  };

  // Load profiles when the component mounts
  useEffect(() => {
    loadProfiles();
  }, []);

  // Event listener for reloading the profiles list
  useEffect(() => {
    const handleProfilesReload = () => {
      console.log("Reloading profiles list...");
      loadProfiles();
    };

    window.addEventListener("profiles:reload", handleProfilesReload);
    return () => {
      window.removeEventListener("profiles:reload", handleProfilesReload);
    };
  }, []);

  /**
   * Handles actions on a profile (e.g., delete, verify, toggle status).
   *
   * @async
   * @param {string} action - The type of action to perform.
   * @param {Object} profile - The profile object to act upon.
   */
  const handleProfileAction = async (action, profile) => {
    setProcessing(true);
    try {
      // Simulate an API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setReport({
        show: true,
        message: `Profile ${action} successfully (simulated)`,
        type: "success",
      });

      // Update the profiles state based on the action
      if (action === "delete") {
        setProfiles((prev) => prev.filter((p) => p.id !== profile.id));
      } else {
        setProfiles((prev) =>
          prev.map((p) =>
            p.id === profile.id
              ? {
                  ...p,
                  verified: action === "verify" ? true : p.verified,
                  status:
                    action === "toggle-status"
                      ? p.status === 1
                        ? 0
                        : 1
                      : p.status,
                }
              : p
          )
        );
      }
    } catch (error) {
      setReport({
        show: true,
        message: `Failed to ${action} profile: ${error.message}`,
        type: "error",
      });
    } finally {
      setProcessing(false);
      setShowActionModal(false);
    }
  };

  /**
   * Handles form submission for creating or updating a profile.
   *
   * @async
   */
  const handleFormSubmit = async () => {
    setProcessing(true);
    try {
      // Simulate an API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setReport({
        show: true,
        message: `Profile ${
          selectedProfile ? "updated" : "created"
        } successfully (simulated)`,
        type: "success",
      });

      // Reset form state and close modal
      setShowCreateModal(false);
      setFormData({});
      setSelectedProfile(null);

      // Reload profiles after the operation
      loadProfiles();
    } catch (error) {
      setReport({
        show: true,
        message: `Operation failed: ${error.message}`,
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Calculate pagination values
  const indexOfLastProfile = currentPage * profilesPerPage;
  const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;
  const currentProfiles = profiles.slice(
    indexOfFirstProfile,
    indexOfLastProfile
  );
  const totalPages = Math.ceil(profiles.length / profilesPerPage);

  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Company Profiles - Care Quality Support</title>
      </Helmet>

      {/* Navigation Header */}
      <NavigationHeader />

      <hr />

      {/* Conditional rendering:
            - If still loading, show a loading spinner.
            - Else if error, show error alert.
            - Else if no profiles, show "No Profiles!" message.
            - Else, show the profiles grid and pagination.
      */}
      {fetchError ? (
        <Row className="justify-content-center">
          <Col xs={12} md={8} className="text-center">
            <div className="alert alert-danger" role="alert">
              {fetchError}
            </div>
          </Col>
        </Row>
      ) : profiles.length === 0 && !loading ? (
        <Row className="justify-content-center">
          <Col className="text-center">
            <div className="alert alert-info" role="alert">
              No Profiles!
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {/* Profiles Grid */}
          <Row className="g-4">
            {currentProfiles.map((profile) => (
              <ProfileCard
                key={profile.id}
                profile={profile}
                onView={() => {
                  setSelectedProfile(profile);
                  setShowViewModal(true);
                }}
                onEdit={() => {
                  setSelectedProfile(profile);
                  setShowCreateModal(true);
                }}
                onDelete={() => {
                  setSelectedProfile(profile);
                  setActionType("delete");
                  setShowActionModal(true);
                }}
              />
            ))}
          </Row>

          {/* Pagination */}
          {totalPages > 1 && (
            <CompanyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </>
      )}

      {/* Action Confirmation Modal */}
      <ActionModal
        show={showActionModal}
        onHide={() => setShowActionModal(false)}
        actionType={actionType}
        profile={selectedProfile}
        onConfirm={() => handleProfileAction(actionType, selectedProfile)}
      />

      {/* View Profile Modal */}
      <ViewModal
        show={showViewModal}
        onHide={() => setShowViewModal(false)}
        profile={selectedProfile}
      />

      {/* Create / Update Profile Modal */}
      {showCreateModal && (
        <CreateProfile
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          onSubmit={handleFormSubmit}
          formData={formData}
          setFormData={setFormData}
          profile={selectedProfile}
        />
      )}
    </Container>
  );
};

CompanyPage.propTypes = {
  user: PropTypes.object,
};

export default CompanyPage;
