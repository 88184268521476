import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { runData } from "../../context/processor";

const ImportData = () => {
  const [showModal, setShowModal] = useState(false);
  const [view, setView] = useState("form"); // "form" | "processing" | "report"
  const [packages, setPackages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [reportData, setReportData] = useState(null);
  const { directory } = useParams();

  useEffect(() => {
    if (showModal && view === "form") {
      fetchPackages();
    }
  }, [showModal, view]);

  const fetchPackages = async () => {
    try {
      const response = await runData({}, "/api/directories/import/packages");
      if (response.status === 200 && response.data?.packages) {
        setPackages(response.data.packages);
      } else {
        setReportData({
          message: response.data?.message || "Failed to fetch packages.",
          type: "error",
        });
        setView("report");
      }
    } catch (error) {
      setReportData({
        message: error.message || "An unexpected error occurred.",
        type: "error",
      });
      setView("report");
    }
  };

  const fetchCategories = async (packageReference) => {
    try {
      const response = await runData(
        { packageReference },
        "/api/directories/import/categories"
      );
      if (response.status === 200 && response.data?.categories) {
        setCategories(response.data.categories);
      } else {
        setReportData({
          message: response.data?.message || "Failed to fetch categories.",
          type: "error",
        });
        setView("report");
      }
    } catch (error) {
      setReportData({
        message: error.message || "An unexpected error occurred.",
        type: "error",
      });
      setView("report");
    }
  };

  const handleImportPolicies = async () => {
    setView("processing");
    try {
      const data = {
        packageReference: selectedPackage,
        categoryReference: selectedCategory,
        parent: directory || "root",
      };
      const response = await runData(data, "/api/directories/import/policies");
      if (response.status === 200 && response.data?.message) {
        setReportData({
          message: response.data.message,
          type: "success",
        });
        // Trigger a server refresh upon successful import
        window.dispatchEvent(new Event("serverRefresh"));
      } else {
        setReportData({
          message: response.data?.message || "Failed to import policies.",
          type: "error",
        });
      }
    } catch (error) {
      setReportData({
        message: error.message || "An unexpected error occurred.",
        type: "error",
      });
    } finally {
      setView("report");
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
    // Reset modal state
    setView("form");
    setPackages([]);
    setCategories([]);
    setSelectedPackage("");
    setSelectedCategory("");
    setReportData(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // Reset state on modal close
    setView("form");
    setPackages([]);
    setCategories([]);
    setSelectedPackage("");
    setSelectedCategory("");
    setReportData(null);
  };

  const renderModalBody = () => {
    if (view === "processing") {
      return (
        <div className="text-center py-4">
          <Spinner animation="border" role="status" />
          <div className="mt-2">Processing...</div>
        </div>
      );
    }
    if (view === "report") {
      return (
        <div className="text-center py-4">
          <p
            className={
              reportData?.type === "success" ? "text-success" : "text-danger"
            }
          >
            {reportData?.message}
          </p>
        </div>
      );
    }
    // "form" view
    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>Select Package</Form.Label>
          <Form.Control
            as="select"
            value={selectedPackage}
            onChange={(e) => {
              const pkgRef = e.target.value;
              setSelectedPackage(pkgRef);
              setCategories([]);
              setSelectedCategory("");
              if (pkgRef) {
                fetchCategories(pkgRef);
              }
            }}
          >
            <option value="">Select a package...</option>
            {packages.map((pkg) => (
              <option key={pkg.reference} value={pkg.reference}>
                {pkg.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {categories.length > 0 && (
          <Form.Group className="mb-3">
            <Form.Label>Select Category</Form.Label>
            <Form.Control
              as="select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select a category...</option>
              {categories.map((cat) => (
                <option key={cat.reference} value={cat.reference}>
                  {cat.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
      </>
    );
  };

  const renderModalFooter = () => {
    if (view === "form") {
      return (
        <div className="d-flex justify-content-between w-100">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {selectedCategory && (
            <Button variant="primary" onClick={handleImportPolicies}>
              Import Policies
            </Button>
          )}
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-center w-100">
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </div>
    );
  };

  return (
    <>
      <Button variant="info" size="sm" onClick={handleOpenModal}>
        Import
      </Button>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Import Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderModalBody()}</Modal.Body>
        <Modal.Footer>{renderModalFooter()}</Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportData;
