import React, { useEffect } from "react";
import { FaUser, FaRobot, FaCopy, FaRedo } from "react-icons/fa";
import { marked } from "marked";
import hljs from "highlight.js";
import moment from "moment";

/**
 * MessageBubble component displays a chat message.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.message - The message object containing content and flags.
 * @param {boolean} props.isUser - Flag to indicate if the message is from the user.
 * @param {Function} props.onCopy - Function to handle copying the message.
 * @param {Function} props.onRegenerate - Function to handle regenerating the response.
 * @param {string} props.timestamp - The timestamp for the message.
 */
const MessageBubble = ({
  message,
  isUser,
  onCopy,
  onRegenerate,
  timestamp,
}) => {
  // If message is an array then check its parts.
  const isArray = Array.isArray(message);
  // If message is an array, concatenate text parts; otherwise use message directly.
  const textParts = isArray ? message.filter(part => part.type === "text") : [];
  const textContent = isArray
    ? textParts.map(part => part.text.value).join(" ")
    : message || "Empty Message";


  useEffect(() => {
      const container =
        document.getElementById(`message-content-${timestamp}`) || document;
      container.querySelectorAll("pre code").forEach((block) => {
        hljs.highlightElement(block);
      });
  }, [textContent, timestamp]);

  return (
    <div className="card py-4 px-4 mb-3">
      <div className="d-flex justify-content-between align-items-center mb-2 border-bottom pb-2">
        <div className="d-flex align-items-center gap-2">
          {isUser ? (
            <>
              <FaUser /> <span>You</span>
            </>
          ) : (
            <>
              <FaRobot /> <span>Composer</span>
            </>
          )}
        </div>
        <small>{moment(timestamp).format("HH:mm")}</small>
      </div>
      <div
        id={`message-content-${timestamp}`}
        className="message-content"
        // Render joined text (if any)
        dangerouslySetInnerHTML={{ __html: marked.parse(textContent || "Empty Message") }}
      />
      {!isUser && (
        <div
          className="mt-3 d-flex justify-content-between align-items-center border-top pt-2"
          style={{ fontSize: "0.8rem" }}
        >
          <div className="d-flex gap-3 text-muted">
            <FaCopy
              role="button"
              onClick={() => onCopy(message)}
              title="Copy to clipboard"
            />
            <FaRedo
              role="button"
              onClick={onRegenerate}
              title="Regenerate response"
            />
          </div>
          <small className="text-muted" style={{ whiteSpace: "nowrap" }}>
            Composer is an AI tool and may generate inaccuracies. Please review
            its output.
          </small>
        </div>
      )}
    </div>
  );
};

export default MessageBubble;
