import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";
import { FaEdit, FaBuilding, FaTimes, FaSave } from "react-icons/fa";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { runData } from "../context/processor";

/**
 * @file EditBusinessName.jsx
 * @description A reusable component that displays an edit icon for the business name.
 * When clicked, it opens a centered and static modal to update the business name.
 * Upon saving, it makes its own API call via runData, dispatches a custom event to reload profiles,
 * and utilizes processing and report modals to provide feedback.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.profile - The profile object containing business details.
 * @param {string} props.profile.reference - Unique reference for the profile.
 * @param {string} [props.profile.business_name] - The current business name.
 */
const EditBusinessName = ({ profile }) => {
  const [show, setShow] = useState(false);
  const [businessName, setBusinessName] = useState(profile.business_name || "");
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  useEffect(() => {
    setBusinessName(profile.business_name || "");
  }, [profile]);

  /**
   * Handles the change event for the business name input field.
   *
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    setBusinessName(e.target.value);
  };

  // Form is considered valid only if the businessName field is not empty.
  const isFormValid = businessName.trim() !== "";

  /**
   * Handles saving the updated business name.
   *
   * This function closes the modal immediately, displays a processing modal,
   * and makes an API call using runData. Depending on the outcome, it displays a report modal
   * with either a success or error message.
   */
  const handleSave = async () => {
    setShow(false);
    setProcessing(true);
    try {
      const response = await runData(
        { business_name: businessName },
        `/api/company/${profile.reference}/update-business-name/`
      );
      if (response.status === 200) {
        window.dispatchEvent(new CustomEvent("profiles:reload"));
        setReport({
          show: true,
          message: "Business name updated successfully.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message: response?.data?.message || "Failed to update business name",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error updating business name",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShow(true)}
        title="Edit Business Name"
      >
        <FaEdit />
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Business Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="businessName" className="mb-3">
              <Form.Label>
                <FaBuilding className="me-1" />
                Business Name
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter business name"
                value={businessName}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setShow(false)}>
            <FaTimes className="me-1" />
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!isFormValid}
          >
            <FaSave className="me-1" />
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditBusinessName.propTypes = {
  profile: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    business_name: PropTypes.string,
  }).isRequired,
};

export default EditBusinessName;
