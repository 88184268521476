import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import DownloadModal from "../modals/DownloadModal";
import DuplicateModal from "../modals/DuplicateModal";
import StatusModal from "../modals/StatusModal";
import DeleteModal from "../modals/DeleteModal";
import EditDescriptionModal from "../modals/EditDescriptionModal";
import RenameModal from "../modals/RenameModal";

import { checkPermissions } from "../helpers/checkPermissions";
import { performApiAction } from "../helpers/performApiAction";
import { useReport } from "../../context/ReportModal";
import { useProcessing } from "../../context/ProcessingModal";
import { localData, runData } from "../../context/processor";
import { downloadItemPDF } from "../helpers/downloadItemPdf";
import { useUser } from "../../hooks/useUser";
import SettingsModal from "../modals/SettingsModal";
import PurchaseModal from "../modals/PurchaseModal";
import DetailsModal from "../modals/DetailsModal";
import ActionButtons from "./ActionButtons";
import DetailsContentRenderer from "./DetailsRenderer";
import { useNavigate } from "react-router-dom";

const Details = ({ item: initialItem, onItemUpdated }) => {
  // State to manage the item details
  const [item, setItem] = useState(initialItem);
  const slot = useUser().user.slot;
  const navigate = useNavigate();

  const typeName = item.type === "folder" ? "Directory" : "Document";

  // Sync the internal state with the new item prop
  useEffect(() => {
    setItem(initialItem);
  }, [initialItem]);

  // Centralized modal state
  const [modals, setModals] = useState({
    rename: false,
    editDescription: false,
    delete: false,
    changeStatus: false,
    duplicate: false,
    download: false,
    settings: false,
    purchase: false,
    details: false,
  });

  const [showDetails, setShowDetails] = useState(true);

  // Context Hooks
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();

  const isDescriptionEmpty =
    !item.description || item.description.trim() === "";

  /**
   * Handles the purchase operation by making an API call to the backend.
   *
   * @param {Object} data - The data payload for the purchase request.
   * @param {string} data.reference - The reference ID of the item to be purchased.
   * @param {string} data.type - The type of the item (e.g., "folder", "file", or "service").
   * @param {string} data.profile - The company profile reference.
   */
  const handlePurchase = async (data) => {
    setProcessing(true);

    try {
      // Validate input data
      if (!data?.reference || !data?.type) {
        throw new Error("Invalid input: Item reference and type are required.");
      }

      // Construct the payload for the API request including the profile reference.
      const dataInfo = {
        itemReference: data.reference,
        itemType: data.type,
        profile: data.profile,
      };

      // Make the API call
      const response = await runData(dataInfo, `/api/purchase/`);

      // Process success response
      if (response.status === 200) {
        setReport({
          show: true,
          message:
            response.data?.message ||
            "Your new invoice has been generated successfully!",
          type: "success",
        });

        // Redirect after a delay
        setTimeout(() => {
          navigate(`/billing/`);
        }, 3000);
      } else {
        // Handle non-200 responses without treating them as exceptions
        setReport({
          show: true,
          message:
            response.data?.message ||
            "Purchase failed. Please try again later.",
          type: "error",
        });
      }
    } catch (err) {
      const errorResponse = err.response;
      const errorMessage =
        errorResponse?.data?.message ||
        errorResponse?.statusText ||
        err.message ||
        "An unexpected error occurred.";

      setReport({
        show: true,
        message: errorMessage,
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleDuplicate = async () => {
    try {
      await localData("save", "duplicate", item);
      setReport({
        show: true,
        message: "Item copied successfully! You can now paste it anywhere.",
        type: "success",
      });

      const duplicateEvent = new CustomEvent("duplicateCompleted", {
        detail: { duplicatedItem: item },
      });
      window.dispatchEvent(duplicateEvent);

      handleCloseModal("duplicate");
    } catch (error) {
      setReport({
        show: true,
        message: "Failed to copy the item. Please try again.",
        type: "error",
      });
      console.error("Duplication Error:", error);
    }
  };

  const handleDownload = async () => {
    handleCloseModal("download");
    await downloadItemPDF(item, runData, setProcessing, setReport);
  };

  // Handle action and permission check
  const handleAction = async (action) => {
    const actionPermissionMap = {
      rename: "edit",
      delete: "delete",
      changeStatus: "edit",
      duplicate: "copy",
      editDescription: "edit",
      download: "download",
      settings: "edit",
      details: "view",
      purchase: "purchase",
    };

    const requiredPermission = actionPermissionMap[action];
    if (!requiredPermission) return;

    const allowed = await checkPermissions(
      item,
      requiredPermission,
      runData,
      setProcessing,
      setReport
    );

    if (!allowed) return;

    setModals((prev) => ({ ...prev, [action]: true }));
  };

  // Handle closing all modals immediately
  const handleCloseModal = (modalName) => {
    setModals((prev) => ({ ...prev, [modalName]: false }));
  };

  // Update item state and notify parent after API success
  const handleUpdateItem = async (action, payload, endpoint) => {
    handleCloseModal(action); // Close the modal immediately

    setProcessing(true);

    try {
      const result = await performApiAction(
        item,
        action,
        payload,
        runData,
        setProcessing,
        setReport,
        endpoint
      );

      if (result) {
        // Update the local state
        const updatedItem = {
          ...item,
          ...payload,
        };

        let command = "modify";
        if (action === "delete") {
          command = "delete";
        } else if (action === "changeStatus") {
          // Set status based on response
          let oldStatus = updatedItem.status;
          if (oldStatus === 1) {
            updatedItem.status = 2;
          } else if (oldStatus === 2) {
            updatedItem.status = 1;
          }
          setItem(updatedItem);
        } else {
          setItem(updatedItem);
        }
        // Notify the parent about the update
        if (onItemUpdated) {
          onItemUpdated(command, updatedItem);
        }

        setReport({
          show: true,
          message: `Your request has been successful.`,
          type: "success",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: `Failed to perform this action. Please try again.`,
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleKeyDown = (event) => {
    const isCtrlOrCmd = event.ctrlKey || event.metaKey;
    const activeElement = document.activeElement;
    const isTyping =
      activeElement &&
      (activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.isContentEditable);

    // Prevent shortcuts while typing in an input or textarea
    if (isTyping) return;

    // Duplicate (Ctrl/Cmd + C)
    if (isCtrlOrCmd && (event.key === "c" || event.key === "C")) {
      event.preventDefault();
      if (item) handleAction("duplicate");
    }

    // Delete (Ctrl/Cmd + D)
    if (
      (isCtrlOrCmd && (event.key === "d" || event.key === "D")) ||
      event.key === "Delete"
    ) {
      event.preventDefault();
      if (item) handleAction("delete");
    }

    // Rename (Ctrl/Cmd + R)
    if (isCtrlOrCmd && (event.key === "r" || event.key === "R")) {
      event.preventDefault();
      if (item) handleAction("rename");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [item]);

  const isActive = item.status === 1;

  return (
    <div
      className="details-section d-flex flex-column"
      style={{
        height: "100vh", // Take full viewport height
        overflow: "hidden", // Prevent overall scrolling
      }}
    >
      {/* Tabs Section - Fixed at top */}
      <div
        className="tabs-section mb-3 d-flex"
        style={{
          gap: "1rem",
          flexShrink: 0, // Prevent shrinking
          backgroundColor: "#fff", // Match your background
          zIndex: 2, // Above content shadow
          padding: "0.5rem 0",
        }}
      >
        <Button
          variant={showDetails ? "secondary" : "outline-secondary"}
          onClick={() => setShowDetails(true)}
          style={{ flex: 1 }}
          size="sm"
        >
          <span>Details</span>
        </Button>

        <Button
          variant={!showDetails ? "secondary" : "outline-secondary"}
          onClick={() => setShowDetails(false)}
          style={{ flex: 1 }}
          size="sm"
        >
          <span>Ownership</span>
        </Button>
      </div>

      {/* Scrollable Content Area */}
      <div
        style={{
          flexGrow: 1,
          overflowY: "auto", // Enable vertical scrolling
          padding: "1.5rem", // Space for sticky buttons
        }}
      >
        <DetailsContentRenderer
          item={item}
          slot={slot}
          handleAction={handleAction}
          typeName={typeName}
          showDetails={showDetails}
          isDescriptionEmpty={isDescriptionEmpty}
        />
      </div>

      {/* Sticky Action Buttons */}
      <div
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "#fff", // Match background
          zIndex: 2,
          boxShadow: "0 -2px 10px rgba(0,0,0,0.1)", // Top shadow
          padding: "0.5rem 0",
        }}
      >
        <ActionButtons
          item={item}
          slot={slot}
          isActive={isActive}
          buttonHandleAction={handleAction}
        />
      </div>

      {/* Modals */}
      <RenameModal
        item={item}
        show={modals.rename}
        handleClose={() => handleCloseModal("rename")}
        onConfirm={(newTitle) =>
          handleUpdateItem(
            "rename",
            { title: newTitle },
            `/api/directories/edit/`
          )
        }
      />
      <PurchaseModal
        item={item}
        show={modals.purchase}
        handleClose={() => handleCloseModal("purchase")}
        onConfirm={(data) => {
          handlePurchase(data);
        }}
      />
      <DetailsModal
        item={item}
        show={modals.details}
        handleClose={() => handleCloseModal("details")}
        onConfirm={(newTitle) =>
          handleUpdateItem(
            "details",
            { title: newTitle },
            `/api/directories/edit/`
          )
        }
      />
      <SettingsModal
        item={item}
        show={modals.settings}
        handleClose={() => handleCloseModal("settings")}
        onConfirm={(data) => {
          // data could be { price: 12.34 } or { hideFromCompany: true }
          handleUpdateItem("settings", data, "/api/directories/edit/");
        }}
        slot={slot}
      />

      <EditDescriptionModal
        item={item}
        show={modals.editDescription}
        handleClose={() => handleCloseModal("editDescription")}
        onConfirm={(newDescription) =>
          handleUpdateItem(
            "editDescription",
            { description: newDescription },
            `/api/directories/edit/`
          )
        }
      />
      <DeleteModal
        item={item}
        show={modals.delete}
        handleClose={() => handleCloseModal("delete")}
        onConfirm={() =>
          handleUpdateItem(
            "delete",
            { transform: "delete" },
            `/api/directories/edit/`
          )
        }
      />
      <StatusModal
        item={item}
        show={modals.changeStatus}
        handleClose={() => handleCloseModal("changeStatus")}
        onConfirm={() =>
          handleUpdateItem(
            "changeStatus",
            { transform: item.status === 1 ? "suspend" : "activate" },
            `/api/directories/edit/`
          )
        }
      />
      <DuplicateModal
        item={item}
        show={modals.duplicate}
        handleClose={() => handleCloseModal("duplicate")}
        onConfirm={() => handleDuplicate()}
      />
      <DownloadModal
        item={item}
        show={modals.download}
        handleClose={() => handleCloseModal("download")}
        onConfirm={() => handleDownload()}
      />
    </div>
  );
};

Details.propTypes = {
  item: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    description: PropTypes.string,
  }).isRequired,
  onItemUpdated: PropTypes.func,
};

export default Details;
