import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaUserCheck, FaUserSlash, FaTimes } from "react-icons/fa";
import PropTypes from "prop-types";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

/**
 * ToggleStatus component renders a self-contained modal for toggling a user's status.
 * It can be used as <ToggleStatus user={user} />.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.user - The user object.
 * @returns {JSX.Element} The ToggleStatus modal component.
 */
const ToggleStatus = ({ user }) => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [modalShow, setModalShow] = useState(false);

  /**
   * Handles toggling the user's status via an API call.
   *
   * @async
   * @function handleToggle
   */
  const handleToggle = async () => {
    setModalShow(false);
    setProcessing(true);
    try {
      const payload = { reference: user.reference, currentStatus: user.status };
      const response = await runData(
        payload,
        "/api/team/update-status/"
      );
      if (response.status === 200) {
        setReport({
          show: true,
          message:
            user.status === 1
              ? "User suspended successfully."
              : "User activated successfully.",
          type: "success",
        });
        window.dispatchEvent(new Event("team:reload"));
      } else {
        setReport({
          show: true,
          message: response?.data?.message || "Failed to update user status.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error updating user status.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant={user.status === 1 ? "warning" : "success"}
        size="sm"
        style={{ flex: 1 }}
        onClick={() => setModalShow(true)}
      >
        {user.status === 1 ? (
          <>
            <FaUserSlash className="me-1" />
            Suspend
          </>
        ) : (
          <>
            <FaUserCheck className="me-1" />
            Activate
          </>
        )}
      </Button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {user.status === 1
              ? "Confirm Suspend User"
              : "Confirm Activate User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {user.status === 1 ? "suspend" : "activate"}{" "}
          {user.name} {user.surname}?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            <FaTimes className="me-1" />
            Cancel
          </Button>
          <Button
            variant={user.status === 1 ? "warning" : "success"}
            onClick={handleToggle}
          >
            {user.status === 1 ? "Suspend" : "Activate"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ToggleStatus.propTypes = {
  user: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
};

export default ToggleStatus;
