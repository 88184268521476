// File: /src/context/processor.js

import axios from "axios";
import { initDB } from "../config/initDB";
import { logout } from "../utils/auth";

/**
 * Axios instance for API requests with a default base URL and headers.
 */
export const apiData = axios.create({
  baseURL: "/query",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
});

/**
 * Axios request interceptor
 */
apiData.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(new Error(error.message))
);

/**
 * Axios response interceptor for handling 401 errors
 */
apiData.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      logout(); // Execute logout
      return Promise.reject(
        new Errored("Session expired. Please login again.", 401)
      );
    }
    return Promise.reject(error);
  }
);

/**
 * Executes POST requests
 */
export async function runData(data, path, type) {
  const formData = new FormData();
  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      formData.append(key, data[key]);
    }
  }

  const config = type ? { responseType: type } : {};

  try {
    return await apiData.post(path, formData, config);
  } catch (error) {
    let errorMessage, statusCode;

    if (type === "blob") {
      errorMessage =
        "An error occurred while processing your request, please contact support.";
    } else {
      errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      statusCode = error.response?.status || 402;
    }

    throw new Errored(errorMessage, statusCode);
  }
}

/**
 * Executes GET requests
 */
export async function getData(data, path, type) {
  const config = { params: data };
  if (type) config.responseType = type;

  try {
    return await apiData.get(path, config);
  } catch (error) {
    let errorMessage, statusCode;

    if (type === "blob") {
      errorMessage =
        "An error occurred while processing your request, please contact support.";
    } else {
      errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      statusCode = error.response?.status || 402;
    }

    throw new Errored(errorMessage, statusCode);
  }
}

/**
 * IndexedDB settings management
 */
export async function localData(action, item, value) {
  const db = await initDB();
  const tx = db.transaction("settings", "readwrite");
  const store = tx.objectStore("settings");

  switch (action) {
    case "save":
      await store.put({ setting: item, value });
      await tx.done;
      return true;
    case "remove": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.delete(item);
        await tx.done;
        return true;
      }
      return false;
    }
    case "get": {
      const data = await store.get(item);
      return data?.value ?? null;
    }
    case "update": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.put({ setting: item, value });
        await tx.done;
        return true;
      }
      return false;
    }
    default:
      return null;
  }
}

/**
 * Shopping cart management
 */
export async function cartData(action, item, value) {
  const db = await initDB();
  const tx = db.transaction("shopping", "readwrite");
  const store = tx.objectStore("shopping");

  switch (action) {
    case "save":
      await store.put({ reference: item, ...value });
      await tx.done;
      window.dispatchEvent(new Event("cartChange"));
      return true;
    case "remove": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.delete(item);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "get":
      return await store.getAll();
    case "check":
      return (await store.get(item)) ?? false;
    case "update": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.put({ reference: item, ...value });
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "increase": {
      const existingItem = await store.get(item);
      if (existingItem) {
        existingItem.quantity += value;
        await store.put(existingItem);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "decrease": {
      const existingItem = await store.get(item);
      if (existingItem?.quantity > 1) {
        existingItem.quantity -= value;
        await store.put(existingItem);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    default:
      return false;
  }
}

/**
 * Custom error class with status code
 */
export class Errored extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
    this.name = "Errored";
  }
}
