import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { localData } from "../context/processor";
import { useReport } from "../context/ReportModal";
import useSessionWatch from "../hooks/useSessionWatch"; // Custom hook for session monitoring
import GdprNotification from "../components/GDPRNotification";

const Layout = ({ children }) => {
  const { setReport } = useReport();
  const sessionValid = useSessionWatch();
  const [navClosed, setNavClosed] = useState(window.innerWidth < 850);

  useEffect(() => {
    const fetchNavState = async () => {
      try {
        const savedNavState = await localData("get", "layoutNavClosed");
        if (window.innerWidth >= 850 && savedNavState !== null) {
          setNavClosed(savedNavState);
        }
      } catch (error) {
        console.error("Failed to fetch navigation state:", error);
      }
    };
    fetchNavState();
  }, []);

  const toggleNav = useCallback(async () => {
    const newState = !navClosed;
    setNavClosed(newState);

    if (window.innerWidth >= 850) {
      try {
        await localData("save", "layoutNavClosed", newState);
      } catch (error) {
        console.error("Failed to save navigation state:", error);
      }
    }
  }, [navClosed]);

  useEffect(() => {
    const handleLoggedOutEvent = () => {
      setReport({
        show: true,
        message:
          "Your session has ended. Thank you for visiting, and we look forward to welcoming you back soon.",
        type: "warning",
      });
    };

    window.addEventListener("loggedOut", handleLoggedOutEvent);

    return () => {
      window.removeEventListener("loggedOut", handleLoggedOutEvent);
    };
  }, [setReport]);

  if (sessionValid === null) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      {sessionValid ? (
        <>
          <Header navClosed={navClosed} toggleNav={toggleNav} />
          <div className="main-container">
            <Navigation navClosed={navClosed} toggleNav={toggleNav} />
            <div className="main">
              {/* Wrap the children in a flex item to occupy remaining space */}
              <div className="main-content">{children}</div>
              <Footer />
            </div>
          </div>
        </>
      ) : (
        <>
          {children}
          <GdprNotification />
        </>
      )}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
