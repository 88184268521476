import React from "react";
import PropTypes from "prop-types";
import { FaFolder, FaFileAlt } from "react-icons/fa";

/**
 * @component ItemsGrid
 * @description Displays a grid of directory and file items with highlighting and color differentiation.
 * @param {Object} props
 * @param {Array} props.items - Array of directory and file items.
 * @param {Function} props.onItemClick - Function to handle item click.
 * @param {Function} props.onItemDoubleClick - Function to handle item double-click.
 * @param {Object} props.selectedItem - The currently selected item.
 * @returns {JSX.Element}
 */
const ItemsGrid = ({ items, onItemClick, onItemDoubleClick, selectedItem }) => {
  /**
   * @function getBackgroundColor
   * @description Determines the background color based on resourceType and selection status.
   * @param {Object} item - The current item.
   * @returns {String} - The background color.
   */
  const getBackgroundColor = (item) => {
    if (selectedItem && selectedItem.reference === item.reference) {
      return "#d1ecf1"; // Highlight color for selected items
    }

    switch (item.resourceType) {
      case "global":
        return "#e8f5e9"; // Light green for global
      case "company":
        return "#e3f2fd"; // Light blue for company
      case "personal":
        return "#fce4ec"; // Light pink for personal
      default:
        return "#ffffff"; // Default white
    }
  };

  /**
   * @function getBorderColor
   * @description Determines the border color based on item type.
   * @param {Object} item - The current item.
   * @returns {String} - The border color.
   */
  const getBorderColor = (item) => {
    return item.type === "folder" ? "#6c757d" : "#343a40"; // Different border colors for folders and files
  };

  return (
    <div className="grid-wrapper">
      {items.map((item) => (
        <div
          key={item.reference}
          className="grid-item"
          style={{
            backgroundColor: getBackgroundColor(item),
            border: `2px solid ${getBorderColor(item)}`,
          }}
          onClick={() => onItemClick(item)}
          onDoubleClick={() => onItemDoubleClick(item)}
        >
          {item.type === "folder" ? (
            <FaFolder size={80} className="mb-2 folder-icon" />
          ) : (
            <FaFileAlt size={80} className="mb-2 file-icon" />
          )}
          <div className="title small-text">
            <small>{item.title}</small>
          </div>
        </div>
      ))}
    </div>
  );
};

ItemsGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      resourceType: PropTypes.string, // Include resourceType in props validation
    })
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onItemDoubleClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.object, // Include selectedItem in props validation
};

export default ItemsGrid;