import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MessageBubble from "./components/MessageBubble";

const ChatMessages = ({ messages, onCopy, onRegenerate, endOfMessagesRef }) => {
  // Group messages by day
  const groupedMessages = useMemo(() => {
    return messages.reduce((groups, message) => {
      const groupKey = moment(message.createdAt).startOf("day").format("YYYY-MM-DD");
      groups[groupKey] = groups[groupKey] || [];
      groups[groupKey].push(message);
      return groups;
    }, {});
  }, [messages]);

  const getGroupTitle = (groupKey) => {
    const groupDate = moment(groupKey, "YYYY-MM-DD");
    if (groupDate.isSame(moment(), "day")) return "Today";
    if (groupDate.isSame(moment().subtract(1, "day"), "day")) return "Yesterday";
    return groupDate.format("LL");
  };

  return (
    <div className="chat-messages mb-5" style={messages.length === 0 ? { height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" } : {}}>
      {messages.length === 0 ? (
        <div className="card py-5 px-5">Start Conversation</div>
      ) : (
        <>
          {Object.keys(groupedMessages)
            .sort((a, b) => new Date(a) - new Date(b))
            .map((groupKey) => (
              <React.Fragment key={groupKey}>
                <div className="text-center my-3 mt-5 mb-5">
                  <span className="badge bg-secondary">{getGroupTitle(groupKey)}</span>
                </div>
                {groupedMessages[groupKey].map((message, index) => (
                  <MessageBubble
                    key={`${groupKey}-${index}`}
                    message={message.content}
                    isUser={message.isUser}
                    createdAt={message.createdAt}
                    files={message.files}
                    onCopy={onCopy}
                    onRegenerate={() => onRegenerate(message.content)}
                  />
                ))}
              </React.Fragment>
            ))}
          <div ref={endOfMessagesRef} />
        </>
      )}
    </div>
  );
};

ChatMessages.propTypes = {
  messages: PropTypes.array.isRequired,
  onCopy: PropTypes.func.isRequired,
  onRegenerate: PropTypes.func.isRequired,
  endOfMessagesRef: PropTypes.object.isRequired,
};

export default ChatMessages;
