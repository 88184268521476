/**
 * @file formatCurrency.js
 * @description Helper function to format a number into currency format.
 *
 * @param {number|string} amount - The amount to format.
 * @returns {string} The formatted currency string.
 */
export function formatCurrency(amount) {
    const num = Number(amount);
    if (isNaN(num)) {
      return "£0.00";
    }
    return `£${num.toFixed(2)}`;
  }
  