import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Spinner } from "react-bootstrap";
import { FaPaperPlane, FaEdit } from "react-icons/fa";
import Settings from "./components/Settings";

const ChatInput = ({
  activeThread,
  newChatTitle,
  inputMessage,
  isTyping,
  chatInputRef,
  onNewChatTitleChange,
  onMessageChange,
  onSend,
}) => {
  return (
    <div
      ref={chatInputRef}
      className="chat-input-container"
      style={{
        position: "sticky",
        bottom: 0,
        background: "#fff",
        zIndex: 1,
        padding: "1.5rem",
        width: "100%",
      }}
    >
      <Form onSubmit={onSend} className="mb-0">
        <div
          className="w-100 mx-auto"
          style={{ boxShadow: "0 -1px 4px rgba(0, 0, 0, 0.4)", padding: "1rem", borderRadius: "1rem" }}
        >
          <div className="d-flex flex-column gap-2">
            {!activeThread && (
              <Form.Group className="mb-2">
                <Form.Label>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>New Chat Title</span>
                    <FaEdit />
                  </div>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={newChatTitle}
                  onChange={onNewChatTitleChange}
                  placeholder="Enter chat title"
                />
              </Form.Group>
            )}
            <div className="d-flex align-items-center gap-2">
              <Settings />
              <Form.Control
                as="textarea"
                style={{ height: "60px", resize: "none" }}
                autoFocus
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                maxLength={5000}
                value={inputMessage}
                onChange={onMessageChange}
                placeholder="Type your message here..."
                rows={1}
                className="flex-grow-1"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    onSend(e);
                  }
                }}
                onClick={() => chatInputRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })}
                onFocus={() => chatInputRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })}
              />
              <Button type="submit" variant="primary" disabled={!inputMessage.trim() || isTyping}>
                {isTyping ? (
                  <span className="d-inline-flex align-items-center">
                    <Spinner animation="border" size="sm" role="status" className="me-2" />
                    <span>Wait</span>
                  </span>
                ) : (
                  <span className="d-inline-flex align-items-center">
                    <FaPaperPlane className="me-2" />
                    <span>Send</span>
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

ChatInput.propTypes = {
  activeThread: PropTypes.any,
  newChatTitle: PropTypes.string.isRequired,
  inputMessage: PropTypes.string.isRequired,
  isTyping: PropTypes.bool.isRequired,
  chatInputRef: PropTypes.object.isRequired,
  onNewChatTitleChange: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default ChatInput;
