import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaTrash, FaTimes } from "react-icons/fa";
import PropTypes from "prop-types";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

/**
 * DeleteUser component renders a self-contained modal for confirming user deletion.
 * It can be used as <DeleteUser customer={customer} />.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.customer - The customer object.
 * @returns {JSX.Element} The DeleteUser modal component.
 */
const DeleteUser = ({ customer }) => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [modalShow, setModalShow] = useState(false);

  /**
   * Handles deleting the customer via an API call.
   *
   * @async
   * @function handleDelete
   */
  const handleDelete = async () => {
    setModalShow(false);
    setProcessing(true);
    try {
      const response = await runData({}, `/api/customers/${customer.reference}/delete/`);
      if (response.status === 200) {
        setReport({
          show: true,
          message: "Customer deleted.",
          type: "success",
        });
        window.dispatchEvent(new Event("customers:reload"));
      } else {
        setReport({
          show: true,
          message: response?.data?.message || "Error deleting customer.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: "Error: " + error.message,
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        style={{ flex: 1 }}
        onClick={() => setModalShow(true)}
      >
        <FaTrash className="me-1" />
        Delete
      </Button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {customer.name}? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            <FaTimes className="me-1" />
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            <FaTrash className="me-1" />
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

DeleteUser.propTypes = {
  customer: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteUser;
