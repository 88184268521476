import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

const healthCategories = [
  "Domiciliary Care",
  "Residential Care",
  "Nursing Care",
  "Supported Living",
  "Community Care",
  "Mental Health Services",
  "Disability Services",
  "Learning Disability Services",
  "Home Health Care",
  "Rehabilitation Services",
  "Adult Day Services",
  "Social Work Services",
  "Palliative Care",
  "Hospice Care",
  "Ambulance Service",
  "NHS Primary Care (GP Services)",
  "NHS Secondary Care (Hospital Services)",
  "Allied Health Professionals (Physio, OT, etc.)",
  "Children's Services (CAMHS, Paediatric Care)",
  "Sexual Health Services",
  "Substance Misuse Services",
  "Pharmacy Services",
  "Telehealth/Telemedicine",
  "Respite Care",
  "Assistive Technology Services",
  "Crisis Intervention Services",
  "GP Out-of-Hours Services",
  "Public Health Services",
  "NHS Continuing Healthcare",
  "Occupational Therapy Services",
  "Speech and Language Therapy",
  "Maternity Services",
  "Veterans' Health Services",
];

const AddCustomerModal = ({ show, onHide }) => {
  const [accountFormData, setAccountFormData] = useState({
    companyName: "",
    serviceOffered: [],
    companyEmail: "",
    useCompanyEmailForLogin: true,
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [accountFormPage, setAccountFormPage] = useState(1);

  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  // New effect: Ensure modal closes when a global hide event is dispatched
  useEffect(() => {
    const handleHideAll = () => {
      if (show) onHide();
    };
    window.addEventListener("modal:hideAll", handleHideAll);
    return () => window.removeEventListener("modal:hideAll", handleHideAll);
  }, [show, onHide]);

  const handleAccountCreationSubmit = async () => {
    onHide(); // Close the modal immediately
    setProcessing(true);
    try {
      const response = await runData(
        accountFormData,
        "/web/get-started/create-account/"
      );
      if (response.status === 200) {
        setReport({
          show: true,
          message: response.data.message || "Customer created.",
          type: "success",
        });
        window.dispatchEvent(new Event("customers:reload")); // Trigger reload of customers
      } else {
        setReport({
          show: true,
          message: response.data.message || "Error creating customer.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: "Error: " + error.message,
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {accountFormPage === 1 ? (
          <Form>
            <Form.Group className="mb-4">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter company name"
                value={accountFormData.companyName}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    companyName: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Company Services</Form.Label>
              <Typeahead
                id="company-services-typeahead"
                multiple
                allowNew
                newSelectionPrefix="Add: "
                options={healthCategories}
                placeholder="Select or add services offered"
                onChange={(selected) => {
                  const selections = selected.map((item) =>
                    typeof item === "string" ? item : item.label || item
                  );
                  setAccountFormData({
                    ...accountFormData,
                    serviceOffered: selections,
                  });
                }}
                selected={
                  Array.isArray(accountFormData.serviceOffered)
                    ? accountFormData.serviceOffered
                    : []
                }
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Company Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter company email"
                value={accountFormData.companyEmail}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    companyEmail: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check
                type="checkbox"
                label="Use company email for login?"
                checked={accountFormData.useCompanyEmailForLogin}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    useCompanyEmailForLogin: e.target.checked,
                  })
                }
              />
            </Form.Group>
          </Form>
        ) : (
          <Form>
            <Form.Group className="mb-4">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                value={accountFormData.firstName}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    firstName: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                value={accountFormData.lastName}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    lastName: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            {!accountFormData.useCompanyEmailForLogin && (
              <Form.Group className="mb-4">
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter user email"
                  value={accountFormData.userEmail || ""}
                  onChange={(e) =>
                    setAccountFormData({
                      ...accountFormData,
                      userEmail: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
            )}
            <Form.Group className="mb-4">
              <Form.Label>WhatsApp Number</Form.Label>
              <PhoneInput
                country={"gb"}
                value={accountFormData.phone}
                onChange={(phone) =>
                  setAccountFormData({ ...accountFormData, phone })
                }
                inputStyle={{ width: "100%" }}
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <div>
          {accountFormPage === 1 ? (
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={() => setAccountFormPage(accountFormPage - 1)}
            >
              Back
            </Button>
          )}
        </div>
        <div>
          {accountFormPage < 2 ? (
            <Button
              variant="primary"
              onClick={() => setAccountFormPage(accountFormPage + 1)}
            >
              Continue
            </Button>
          ) : (
            <Button variant="primary" onClick={handleAccountCreationSubmit}>
              Submit
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AddCustomerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AddCustomerModal;
