import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { runData } from "../../context/processor";
import { FaBriefcase, FaShoppingBasket } from "react-icons/fa";
import { Link } from "react-router-dom";

/**
 * PurchaseModal component renders a modal to confirm the purchase of an item.
 * It loads the available company profiles and requires the user to select one.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object|null} props.item - The item to purchase.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {Function} props.handleClose - Callback to close the modal.
 * @param {Function} props.onConfirm - Callback that is invoked with purchase data on confirmation.
 * @returns {JSX.Element} The rendered PurchaseModal component.
 */
const PurchaseModal = ({ item, show, handleClose, onConfirm }) => {
  // State to hold available profiles and the selected profile.
  const [allProfiles, setAllProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState([]);

  /**
   * Loads available company profiles when the modal is shown.
   * Assumes the API returns an object with a 'profiles' array.
   */
  useEffect(() => {
    if (show) {
      const loadProfiles = async () => {
        try {
          const response = await runData({}, "/api/company/");
          if (response.status === 200 && response.data?.profiles) {
            setAllProfiles(response.data.profiles);
            // Optionally pre-select a profile if only one exists.
            if (response.data.profiles.length === 1) {
              setSelectedProfile(response.data.profiles);
            }
          }
        } catch (error) {
          // Log or handle error appropriately
          console.error("Error loading profiles:", error);
        }
      };
      loadProfiles();
    }
  }, [show]);

  /**
   * Handles purchase confirmation.
   * Validates that a profile has been selected and passes purchase data (including the profile reference)
   * to the parent component's onConfirm callback before closing the modal.
   */
  const handleConfirm = () => {
    if (selectedProfile.length === 0) {
      // Optionally, display an alert or error message that a profile selection is required.
      return;
    }
    // Build the payload to send for purchase.
    const purchaseData = {
      reference: item.reference,
      type: item.type,
      profile: selectedProfile[0].reference, // Assumes the profile object has a 'reference' property.
    };
    onConfirm(purchaseData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Purchase {item?.type === "folder" ? "Directory" : "Document"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {item ? (
          <>
            <p>
              Are you sure you want to purchase this{" "}
              {item.type === "folder" ? "folder" : "document"} for{" "}
              <strong>£{item.price}</strong>?<br />
              <br />
              <strong>
                <FaShoppingBasket /> {item.title}
              </strong>
            </p>
            <hr />
            <Form.Group controlId="profile" className="mb-3">
              <Form.Label>
                <FaBriefcase /> Choose Company Profile
              </Form.Label>
              <Typeahead
                id="profile-typeahead"
                labelKey={(option) =>
                  `${option.business_name} - ${option.business_description}`
                }
                options={allProfiles}
                placeholder="Select a profile..."
                onChange={(selected) => setSelectedProfile(selected)}
                selected={selectedProfile}
                clearButton
              />
              <p className="mt-4 text-center">
                <Link to={`/company/`} className="btn text-secondary">
                  If you do not have a business profile please go to manage
                  company and create one then come back.
                </Link>
              </p>
            </Form.Group>
          </>
        ) : (
          <p>Item information is not available.</p>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleConfirm}
          disabled={!item || selectedProfile.length === 0}
        >
          Buy Now
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PurchaseModal.propTypes = {
  item: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PurchaseModal;
