import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
  FaFolderOpen,
  FaDownload,
  FaPause,
  FaPlay,
  FaTrash,
  FaCartPlus,
  FaCog,
} from "react-icons/fa";

// Helper functions to control button visibility

/**
 * Determines whether the "Open" action should be visible based on the item properties and slot.
 *
 * @param {Object} item - An object representing an item. Should contain:
 *  - {string} data_type - The data type of the item (e.g., "write", "upload").
 *  - {string} type - The general type of the item (e.g., "folder").
 *  - {string} resourceType - The resource context (e.g., "global", "company", "personal").
 *  - {string} [shopping] - A string indicating shopping status (if applicable).
 * @param {number} slot - The slot index (usually between 1 and 8).
 * @returns {boolean} - Returns true if "Open" should be shown, otherwise false.
 */
const shouldShowOpen = (item, slot) => {
  // For slots 1 to 6, allow "Open" only if data_type is "write" or the item is a folder.
  if ((slot >= 1 && slot <= 6) && (item.data_type === "write" || item.type === "folder")) {
    return true;
  }

  if (slot === 7 || slot === 8) {
    return (
      (
        (item.resourceType === "global" && item.shopping.trim().toLowerCase() === "no") ||
        item.resourceType === "company" ||
        item.resourceType === "personal"
      ) && item.data_type === "write"
    );
  }

  // By default, do not show "Open".
  return false;
};


const shouldShowBuyNow = (item, slot) => {
  const normalizedPurchase = item.shopping.trim().toLowerCase();
  return normalizedPurchase === "yes" && (slot === 7 || slot === 8);
};

const shouldShowDownload = (item, slot) => {
  if (slot >= 1 && slot <= 6 && item.type !== "folder") return true;
  if (slot === 7 || slot === 8) {
    return (
      (item.resourceType === "global" &&
        item.shopping.trim().toLowerCase() === "no" &&
        item.type !== "folder") ||
      item.resourceType === "company" ||
      item.resourceType === "personal"
    );
  }
  return false;
};

const shouldShowStatusButton = (item, slot) =>
  (item.resourceType === "company" && slot >= 1 && slot <= 8) ||
  item.resourceType === "personal" ||
  (item.resourceType === "global" && slot >= 1 && slot <= 6);

const shouldShowDelete = (item, slot) =>
  (item.resourceType === "global" && slot >= 1 && slot <= 3) ||
  (item.resourceType === "company" &&
    ((slot >= 1 && slot <= 3) || slot === 7 || slot === 8)) ||
  item.resourceType === "personal";

/**
 * @param {Object} item - The item object.
 * @param {string} item.resourceType - One of "global", "company", or "personal".
 * @param {string} item.type - Either "folder" or "document".
 * @param {number} slot - The slot number under consideration.
 * @returns {boolean} - Returns true if settings should be shown, false otherwise.
 */
const shouldShowSettings = (item, slot) => {
  const { resourceType } = item;

  // Allow "global" for slots 1–5
  if (resourceType === "global") {
    return slot >= 1 && slot <= 5;
  }
 
  // Allow "company" for slots 1–3 or 7–8
  if (resourceType === "company") {
    return (slot >= 1 && slot <= 3) || (slot >= 7 && slot <= 8);
  }

  // If resourceType === "personal", or anything else not handled above:
  return false;
};

// Main ActionButtons component
const ActionButtons = ({ item, slot, isActive, buttonHandleAction }) => {
  return (
    <div
      className="action-buttons d-flex flex-wrap"
      style={{ marginTop: "auto", paddingTop: "10px", gap: "0.25rem" }}
    >
      {shouldShowOpen(item, slot) && (
        <Button
          variant="outline-secondary"
          className="btn-sm flex-grow-1"
          as={Link}
          to={
            item.type === "folder"
              ? `/directories/${item.reference}/`
              : `/directories/${item.parent_reference || "directory"}/${
                  item.reference
                }/`
          }
        >
          <FaFolderOpen style={{ marginRight: "0.25rem" }} /> Open
        </Button>
      )}

      {shouldShowBuyNow(item, slot) && (
        <Button
          variant="outline-success"
          className="btn-sm flex-grow-1"
          onClick={() => buttonHandleAction("purchase")}
        >
          <FaCartPlus style={{ marginRight: "0.25rem" }} />
          Buy Now
        </Button>
      )}

      {shouldShowDownload(item, slot) && (
        <Button
          variant="outline-info"
          className="btn-sm flex-grow-1"
          onClick={() => buttonHandleAction("download")}
        >
          <FaDownload style={{ marginRight: "0.25rem" }} /> Download
        </Button>
      )}

      {shouldShowStatusButton(item, slot) && (
        <Button
          variant={isActive ? "outline-warning" : "outline-success"}
          className="btn-sm flex-grow-1"
          onClick={() => buttonHandleAction("changeStatus")}
        >
          {isActive ? (
            <>
              <FaPause style={{ marginRight: "0.25rem" }} /> Suspend
            </>
          ) : (
            <>
              <FaPlay style={{ marginRight: "0.25rem" }} /> Activate
            </>
          )}
        </Button>
      )}

      {shouldShowDelete(item, slot) && (
        <Button
          variant="outline-danger"
          className="btn-sm flex-grow-1"
          onClick={() => buttonHandleAction("delete")}
        >
          <FaTrash style={{ marginRight: "0.25rem" }} /> Delete
        </Button>
      )}

      {shouldShowSettings(item, slot) && (
        <Button
          variant="outline-secondary"
          size="sm"
          className="flex-grow-1"
          onClick={() => buttonHandleAction("settings")}
        >
          <FaCog style={{ marginRight: "0.25rem" }} /> Settings
        </Button>
      )}
    </div>
  );
};

// Prop Types
ActionButtons.propTypes = {
  item: PropTypes.shape({
    resourceType: PropTypes.string.isRequired,
    shopping: PropTypes.string.isRequired,
    data_type: PropTypes.string,
    reference: PropTypes.string.isRequired,
    parent_reference: PropTypes.string,
  }).isRequired,
  slot: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  buttonHandleAction: PropTypes.func.isRequired,
};

export default ActionButtons;
