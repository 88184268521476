/**
 * @file PaginationComponent.jsx
 * @description A pagination component to handle page navigation.
 */

import React from "react";
import { Pagination } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 * PaginationComponent displays pagination controls with the previous button
 * on the left, the next button on the right, and the page numbers centered.
 *
 * @param {number} currentPage - The current active page.
 * @param {number} totalPages - The total number of pages.
 * @param {Function} onPageChange - Callback function triggered when a page is selected.
 * @returns {JSX.Element} The rendered pagination component.
 */
const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  // Create an array of pages from 1 to totalPages.
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return (
    // Outer container set to full width with flex utilities to space content.
    <div className="d-flex justify-content-between align-items-center w-100">
      {/* Previous Button Section */}
      <div>
        <Pagination className="mb-0">
          <Pagination.Prev
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
        </Pagination>
      </div>

      {/* Page Numbers Section (centered) */}
      <div>
        <Pagination className="mb-0">
          {pages.map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      {/* Next Button Section */}
      <div>
        <Pagination className="mb-0">
          <Pagination.Next
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

PaginationComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PaginationComponent;
