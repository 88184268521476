import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FaEdit,
  FaHouseUser,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaUserTie,
  FaTimes,
  FaSave,
} from "react-icons/fa";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { runData } from "../context/processor";

/**
 * @file EditAuthority.jsx
 * @description A reusable component that displays an edit icon for authority details.
 * When clicked, it opens a centered and static modal to update authority details.
 * Upon saving, it makes its own API call via runData and dispatches a custom event to reload profiles.
 */
const EditAuthority = ({ profile }) => {
  const [show, setShow] = useState(false);
  const [authorityValues, setAuthorityValues] = useState({
    authority_name: profile.authority_name || "",
    authority_address: profile.authority_address || "",
    authority_phone: profile.authority_phone || "",
    authority_email: profile.authority_email || "",
    authority_website: profile.authority_website || "",
    authority_representative: profile.authority_representative || "",
  });

  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  useEffect(() => {
    setAuthorityValues({
      authority_name: profile.authority_name || "",
      authority_address: profile.authority_address || "",
      authority_phone: profile.authority_phone || "",
      authority_email: profile.authority_email || "",
      authority_website: profile.authority_website || "",
      authority_representative: profile.authority_representative || "",
    });
  }, [profile]);

  // Updates a given field in authorityValues.
  const handleChange = (field, value) => {
    setAuthorityValues({ ...authorityValues, [field]: value });
  };

  // Extended list of council names across the UK for suggestions.
  const councils = [
    // England
    "Birmingham City Council",
    "Manchester City Council",
    "Leeds City Council",
    "Liverpool City Council",
    "Sheffield City Council",
    "Bristol City Council",
    "Coventry City Council",
    "Nottingham City Council",
    "Leicester City Council",
    "Kingston upon Hull City Council",
    "Bradford City Council",
    "Stoke-on-Trent City Council",
    "Wolverhampton City Council",
    "Newcastle City Council",
    "Sunderland City Council",
    "Derby City Council",
    "Southampton City Council",
    "Portsmouth City Council",
    "Reading Borough Council",
    "Luton Borough Council",
    "Northampton Borough Council",
    "Swindon Borough Council",
    "Milton Keynes Council",
    "Basingstoke and Deane Borough Council",
    "Woking Borough Council",
    // Wales
    "Cardiff Council",
    "Swansea Council",
    "Newport City Council",
    "Carmarthenshire County Council",
    "Neath Port Talbot County Borough Council",
    "Bridgend County Borough Council",
    "Vale of Glamorgan Council",
    "Rhondda Cynon Taf Council",
    "Merthyr Tydfil County Borough Council",
    "Caerphilly County Borough Council",
    // Scotland
    "Glasgow City Council",
    "Edinburgh City Council",
    "Aberdeen City Council",
    "Dundee City Council",
    "Inverclyde Council",
    "Scottish Borders Council",
    "Fife Council",
    "North Lanarkshire Council",
    "South Lanarkshire Council",
    "Highland Council",
    "Argyll and Bute Council",
    // Northern Ireland
    "Belfast City Council",
    "Derry City and Strabane District Council",
    "Armagh City, Banbridge and Craigavon Borough Council",
    "Lisburn and Castlereagh City Council",
    "Newry, Mourne and Down District Council",
  ];

  // Determine if all required fields are filled.
  const isFormValid =
    authorityValues.authority_name.trim() !== "" &&
    authorityValues.authority_address.trim() !== "" &&
    authorityValues.authority_phone.trim() !== "" &&
    authorityValues.authority_email.trim() !== "" &&
    authorityValues.authority_website.trim() !== "";

  /**
   * Handles saving the updated authority details.
   *
   * Closes the modal immediately, shows a processing modal, and makes an API call via runData.
   * Depending on the outcome, it then displays a report modal with a success or error message.
   */
  const handleSave = async () => {
    setShow(false);
    setProcessing(true);
    try {
      const response = await runData(
        authorityValues,
        `/api/company/${profile.reference}/update-authority/`
      );
      if (response.status === 200) {
        window.dispatchEvent(new CustomEvent("profiles:reload"));
        setReport({
          show: true,
          message: "Authority details updated successfully.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message:
            response?.data?.message || "Failed to update authority details",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error updating authority details",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShow(true)}
        title="Edit Authority Details"
      >
        <FaEdit />
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Authority Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Authority Name Field using Typeahead for suggestions */}
            <Form.Group controlId="authority_name" className="mb-3">
              <Form.Label>
                <FaHouseUser className="me-1" />
                Authority Name
              </Form.Label>
              <Typeahead
                id="authority-name-typeahead"
                options={councils}
                placeholder="Enter authority name"
                onChange={(selected) => {
                  const value = selected[0];
                  if (typeof value === "object" && value !== null) {
                    handleChange("authority_name", value.label || "");
                  } else {
                    handleChange("authority_name", value || "");
                  }
                }}
                selected={
                  authorityValues.authority_name
                    ? [authorityValues.authority_name]
                    : []
                }
                allowNew
                newSelectionPrefix="Add: "
              />
            </Form.Group>

            {/* Authority Representative */}
            <Form.Group controlId="authority_representative" className="mb-3">
              <Form.Label>
                <FaUserTie className="me-1" />
                Authority Representative (Optional)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter authority representative"
                value={authorityValues.authority_representative}
                onChange={(e) =>
                  handleChange("authority_representative", e.target.value)
                }
              />
            </Form.Group>

            {/* Authority Address */}
            <Form.Group controlId="authority_address" className="mb-3">
              <Form.Label>
                <FaMapMarkerAlt className="me-1" />
                Authority Address
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter authority address"
                value={authorityValues.authority_address}
                onChange={(e) =>
                  handleChange("authority_address", e.target.value)
                }
              />
            </Form.Group>

            {/* Authority Phone */}
            <Form.Group controlId="authority_phone" className="mb-3">
              <Form.Label>
                <FaPhone className="me-1" />
                Authority Phone
              </Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter authority phone"
                value={authorityValues.authority_phone}
                onChange={(e) =>
                  handleChange("authority_phone", e.target.value)
                }
              />
            </Form.Group>

            {/* Authority Email */}
            <Form.Group controlId="authority_email" className="mb-3">
              <Form.Label>
                <FaEnvelope className="me-1" />
                Authority Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter authority email"
                value={authorityValues.authority_email}
                onChange={(e) =>
                  handleChange("authority_email", e.target.value)
                }
              />
            </Form.Group>

            {/* Authority Website */}
            <Form.Group controlId="authority_website" className="mb-3">
              <Form.Label>
                <FaGlobe className="me-1" />
                Authority Website
              </Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter authority website"
                value={authorityValues.authority_website}
                onChange={(e) =>
                  handleChange("authority_website", e.target.value)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setShow(false)}>
            <FaTimes className="me-1" />
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!isFormValid}
          >
            <FaSave className="me-1" />
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditAuthority.propTypes = {
  profile: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    authority_name: PropTypes.string,
    authority_address: PropTypes.string,
    authority_phone: PropTypes.string,
    authority_email: PropTypes.string,
    authority_website: PropTypes.string,
    authority_representative: PropTypes.string,
  }).isRequired,
};

export default EditAuthority;
