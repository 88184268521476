/**
 * @file ProfileCard.jsx
 * @description A component that displays a full-width company profile divided into sections.
 * Each section now uses a table to neatly organize profile data with cells always split 50/50 in width,
 * styled as borderless with striped rows. The header has consistent margins to align with the tables.
 *
 * @param {object} profile - The profile object containing all necessary company information.
 * @returns {JSX.Element} The ProfileCard component.
 */

import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Image, Table } from "react-bootstrap";
import EditBusinessName from "./EditBusinessName";
import EditLogo from "./EditLogo";
import EditBusinessDetails from "./EditBusinessDetails";
import EditAddress from "./EditAddress";
import EditAuthority from "./EditAuthority";

// Define a constant style to ensure cells are equally split (50/50) and have no borders.
const cellStyle = { width: "50%", border: "none" };

const ProfileCard = ({ profile }) => {
  return (
    <Card className="w-100 mb-3 bg-light" border="0">
      {/* Header with consistent padding and full width */}
      <Card.Header
        className="p-3 mt-2 w-100"
        style={{ backgroundColor: "#ffffff", border: "none" }}
      >
        <Row className="align-items-center">
          <Col>
            <div className="d-flex align-items-center">
              <h4 className="mb-0">{profile.business_name}</h4>
              {/* Edit business name icon */}
              <EditBusinessName profile={profile} />
            </div>
            <small className="text-muted">REF: {profile.reference}</small>
          </Col>
          {profile.logo && (
            <Col xs="auto" className="d-flex align-items-center">
              <div
                style={{
                  maxWidth: "80px",
                  maxHeight: "80px",
                  overflow: "hidden",
                }}
              >
                <Image
                  src={`${profile.logo}?v=${Date.now()}`}
                  alt={`${profile.business_name} logo`}
                  fluid
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              {/* Edit logo icon */}
              <EditLogo profile={profile} />
            </Col>
          )}
        </Row>
      </Card.Header>

      {/* Body */}
      <Card.Body className="p-3">
        {/* Business Details Section */}
        <div className="mb-4">
          <Row className="align-items-center mb-2">
            <Col>
              <h5 className="mb-0">Business Details</h5>
            </Col>
            <Col xs="auto">
              <EditBusinessDetails profile={profile} />
            </Col>
          </Row>
          <Table striped borderless hover responsive size="sm">
            <tbody>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Description</th>
                <td style={cellStyle}>{profile.business_description}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Manager</th>
                <td style={cellStyle}>{profile.business_manager || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Nominated</th>
                <td style={cellStyle}>{profile.business_nominated || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Email</th>
                <td style={cellStyle}>{profile.email || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Phone</th>
                <td style={cellStyle}>{profile.phone || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Mobile</th>
                <td style={cellStyle}>{profile.mobile || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Website</th>
                <td style={cellStyle}>
                  {profile.website ? (
                    <a
                      href={profile.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {profile.website}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        {/* Address Section */}
        <div className="mb-4">
          <Row className="align-items-center mb-2">
            <Col>
              <h5 className="mb-0">Address Details</h5>
            </Col>
            <Col xs="auto">
              <EditAddress profile={profile} />
            </Col>
          </Row>
          <Table striped borderless hover responsive size="sm">
            <tbody>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Address</th>
                <td style={cellStyle}>{profile.address || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>City</th>
                <td style={cellStyle}>{profile.city || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>County</th>
                <td style={cellStyle}>{profile.county || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Region</th>
                <td style={cellStyle}>{profile.region || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Postcode</th>
                <td style={cellStyle}>{profile.postcode || "N/A"}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        {/* Authority Details Section */}
        <div>
          <Row className="align-items-center mb-2">
            <Col>
              <h5 className="mb-0">Authority Details</h5>
            </Col>
            <Col xs="auto">
              <EditAuthority profile={profile} />
            </Col>
          </Row>
          <Table striped borderless hover responsive size="sm">
            <tbody>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Name</th>
                <td style={cellStyle}>{profile.authority_name || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Address</th>
                <td style={cellStyle}>{profile.authority_address || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Phone</th>
                <td style={cellStyle}>{profile.authority_phone || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Email</th>
                <td style={cellStyle}>{profile.authority_email || "N/A"}</td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Website</th>
                <td style={cellStyle}>
                  {profile.authority_website ? (
                    <a
                      href={profile.authority_website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {profile.authority_website}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr style={{ border: "none" }}>
                <th style={cellStyle}>Representative</th>
                <td style={cellStyle}>
                  {profile.authority_representative || "N/A"}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

ProfileCard.propTypes = {
  profile: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    business_name: PropTypes.string.isRequired,
    business_description: PropTypes.string.isRequired,
    business_manager: PropTypes.string,
    business_nominated: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    website: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    region: PropTypes.string,
    postcode: PropTypes.string,
    authority_name: PropTypes.string,
    authority_address: PropTypes.string,
    authority_phone: PropTypes.string,
    authority_email: PropTypes.string,
    authority_website: PropTypes.string,
    authority_representative: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
};

export default ProfileCard;
