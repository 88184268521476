import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FaFileUpload, FaPaperclip, FaTimes } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { runData } from "../../context/processor";
import { useProcessing } from "../../context/ProcessingModal";
import { useReport } from "../../context/ReportModal";
import PropTypes from "prop-types";

const FileUpload = ({ onFileSelect, iconOnly, threadId }) => { // added threadId prop
  const [showModal, setShowModal] = React.useState(false);
  const fileInputRef = React.useRef();
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  const uploadFile = async (file) => {
    if (!threadId) {
      setReport({
        show: true,
        message: "Cannot upload file without a valid thread.",
        type: "error",
      });
      return;
    }
    const formData = { attachment: file, threadId }; // include threadId
    setProcessing(true);
    try {
      const response = await runData(formData, "/api/composer/upload/");
      if (response.status === 200) {
        // Attach the file name if not provided by the server
        const uploadedData = { ...response.data, name: file.name };
        onFileSelect(uploadedData);
        setReport({ show: true, message: "File uploaded successfully.", type: "success" });
      } else {
        setReport({ show: true, message: response.data.message || "File upload failed", type: "error" });
      }
    } catch (error) {
      setReport({ show: true, message: "File upload error: " + error.message, type: "error" });
    } finally {
      setProcessing(false);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadFile(file);
      setShowModal(false);
    }
  };

  const handleDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uploadFile(file);
      setShowModal(false);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
  });

  return (
    <>
      {iconOnly ? (
        <Button
          variant="outline-secondary"
          onClick={() => setShowModal(true)}
          title="Attach File"
          className="mb-0 btn-tiny"
        >
          <FaFileUpload />
        </Button>
      ) : (
        <Button variant="outline-dark" size="sm" className="mb-0 btn-tiny" onClick={() => setShowModal(true)}>
          <FaFileUpload /> Upload
        </Button>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            {...getRootProps()}
            className="border rounded p-4 text-center"
            style={{
              cursor: "pointer",
              background: isDragActive
                ? "linear-gradient(135deg, #d0e1fd, #b0c4de)"
                : "linear-gradient(135deg, #ffffff, #e0e0e0)",
              border: "2px dashed #888",
              borderRadius: "8px",
              padding: "20px"
            }}
          >
            <input {...getInputProps()} accept=".pdf,.txt,.doc,.docx,.pptx" />
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>Drag & drop a file here, or click to select one</p>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FileUpload;

// Add prop validation
FileUpload.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  iconOnly: PropTypes.bool,
  threadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
