/**
 * @file InvoicesTable.jsx
 * @description Renders a table of invoices with inline filtering (by text and status), pagination, and actions using processing, report, and confirm modals.
 */

import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button, Badge, Form, Modal } from "react-bootstrap";
import { FaDownload, FaFileInvoice } from "react-icons/fa";
import PaginationComponent from "./PaginationComponent";
import { formatCurrency } from "../utils/formatCurrency";
import { saveAs } from "file-saver";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { slugify } from "../includes/slugify";
import { Link } from "react-router-dom";

const InvoicesTable = ({ invoices, currentUserSlot }) => {
  // Local state for invoices, pagination, and filters.
  const [invoiceList, setInvoiceList] = useState(invoices);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("All"); // New state for status filtering.
  const invoicesPerPage = 6;

  // State variables for the confirm modal when marking an invoice as paid.
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  // Context methods for processing and report modals.
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  /**
   * Updates local invoiceList if the prop invoices change.
   *
   * @param {Array} invoices - The updated array of invoices.
   */
  useEffect(() => {
    setInvoiceList(invoices);
  }, [invoices]);

  /**
   * Handles changes in the text filter input.
   *
   * @param {object} event - The event triggered by changing the input.
   */
  const handleFilterChange = (event) => {
    setFilterTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page when filtering.
  };

  /**
   * Handles changes in the status filter dropdown.
   *
   * @param {object} event - The event triggered by changing the dropdown.
   */
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setCurrentPage(1); // Reset to the first page when filtering.
  };

  /**
   * Handles page changes for pagination.
   *
   * @param {number} page - The page number to navigate to.
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  /**
   * Filters invoices based on text input and selected status.
   * The text filter checks invoice reference and company name (case-insensitive).
   * The status filter checks the invoice's status_comment.
   *
   * @returns {Array} The filtered list of invoices.
   */
  const filteredInvoices = useMemo(() => {
    let filtered = invoiceList;

    // Apply text filter if provided.
    if (filterTerm) {
      const lowerCaseTerm = filterTerm.toLowerCase();
      filtered = filtered.filter((invoice) => {
        const invoiceRef = invoice.reference.toString().toLowerCase();
        const companyName =
          invoice.company && invoice.company.name
            ? invoice.company.name.toLowerCase()
            : "";
        return (
          invoiceRef.includes(lowerCaseTerm) ||
          companyName.includes(lowerCaseTerm)
        );
      });
    }

    // Apply status filter if a specific status is selected.
    if (statusFilter !== "All") {
      filtered = filtered.filter(
        (invoice) =>
          invoice.status_comment &&
          invoice.status_comment.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    return filtered;
  }, [filterTerm, invoiceList, statusFilter]);

  // Calculate total pages based on the filtered invoices.
  const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);

  // Determine the invoices to display on the current page.
  const startIndex = (currentPage - 1) * invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(
    startIndex,
    startIndex + invoicesPerPage
  );

  /**
   * Handles downloading an invoice file.
   *
   * @async
   * @param {string} invoiceReference - The unique reference for the invoice.
   */
  const handleDownloadInvoice = async (invoiceReference) => {
    setProcessing(true);
    try {
      const data = { invoiceReference };
      const response = await runData(
        data,
        `/api/invoices/download/${invoiceReference}`,
        "blob"
      );
      if (response.status === 200) {
        const filename = slugify(`Invoice_${invoiceReference}`);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0];
        const defaultFilename = `InvoiceDownload_${formattedDate}`;
        saveAs(blob, filename || defaultFilename);
        setReport({
          show: true,
          message: "Invoice download has been successful.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message:
            response.data?.message || "An error occurred during the download.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message:
          error.message ||
          "An unexpected error occurred while downloading the invoice.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Opens the confirmation modal before marking an invoice as paid.
   *
   * @param {string} invoiceReference - The unique reference for the invoice.
   */
  const showConfirmMarkAsPaid = (invoiceReference) => {
    setSelectedInvoice(invoiceReference);
    setShowConfirmModal(true);
  };

  /**
   * Handles the confirmation to mark an invoice as paid.
   *
   * @async
   */
  const handleConfirmMarkAsPaid = async () => {
    setShowConfirmModal(false);
    setProcessing(true);
    try {
      const response = await runData(
        { invoiceReference: selectedInvoice },
        `/api/invoices/mark-paid/${selectedInvoice}`
      );
      // Update the local invoiceList to reflect the new status.
      setInvoiceList((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice.reference === selectedInvoice
            ? { ...invoice, status: 1, status_comment: "Paid" }
            : invoice
        )
      );
      setReport({
        show: true,
        message: `Invoice ${selectedInvoice} marked as paid successfully.`,
        type: "success",
      });
    } catch (error) {
      setReport({
        show: true,
        message:
          error.message ||
          "An error occurred while marking the invoice as paid.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Cancels the mark-as-paid confirmation modal.
   */
  const handleCancelMarkAsPaid = () => {
    setShowConfirmModal(false);
    setSelectedInvoice(null);
  };

  // Display message if no invoices are found.
  if (invoiceList.length === 0) {
    return (
      <div className="text-center my-5">
        <h4>No invoices found</h4>
      </div>
    );
  }

  return (
    <>
      <Card className="mb-4 shadow-sm mt-5">
        {/* Card Header with inline Title, Filters, and Pagination */}
        <Card.Header className="bg-light">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h5 className="me-2">
              {filteredInvoices.length} Invoice
              {filteredInvoices.length !== 1 && "s"}
            </h5>

            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                placeholder="Search by reference..."
                value={filterTerm}
                onChange={handleFilterChange}
                className="me-2"
              />
              <Form.Select
                value={statusFilter}
                onChange={handleStatusFilterChange}
                className="me-2"
              >
                <option value="All">All Statuses</option>
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>
                <option value="Processing">Processing</option>
              </Form.Select>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {/* Invoices Grid */}
          <Row className="g-4 mt-3 mb-4">
            {currentInvoices.map((invoice) => (
              <Col key={invoice.reference} sm={12} md={6} lg={4}>
                <Card className="h-100 shadow-sm">
                  <Card.Header className="d-flex justify-content-between align-items-center bg-light">
                    <div className="d-flex align-items-center">
                      <FaFileInvoice size={30} color="grey" />
                      <div className="ms-2">
                        <span className="fw-bold">{invoice.reference}</span>
                        <br />
                        <small className="text-muted">
                          Date:{" "}
                          {new Date(invoice.invoice_date).toLocaleDateString()}
                        </small>
                      </div>
                    </div>
                    <Badge bg="info">{invoice.status_comment}</Badge>
                  </Card.Header>
                  <Card.Body>
                    <div className="mb-2">
                      <small className="text-muted">Company:</small>
                      <div>
                        {invoice.company && invoice.company.name
                          ? invoice.company.name
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-2">
                      <small className="text-muted">Profile:</small>
                      <div>
                        {invoice.profile && invoice.profile.name
                          ? invoice.profile.name
                          : invoice.profile_reference || "-"}
                      </div>
                    </div>
                    <div className="mb-2">
                      <small className="text-muted">Due Date:</small>
                      <div>
                        {new Date(invoice.due_date).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="mb-2">
                      <small className="text-muted">Net Amount:</small>
                      <div className="fw-bold">
                        {formatCurrency(invoice.net_amount)}
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer className="bg-white border-top-0">
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                      <div className="flex-grow-1">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() =>
                            handleDownloadInvoice(invoice.reference)
                          }
                          className="w-100"
                        >
                          <FaDownload className="me-1" />
                          Download
                        </Button>
                      </div>
                      <div className="flex-grow-1">
                        {[1, 2, 4].includes(currentUserSlot) ? (
                          invoice.status === 1 ? (
                            <Button
                              variant="success"
                              size="sm"
                              disabled
                              className="w-100"
                            >
                              Completed
                            </Button>
                          ) : (
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() =>
                                showConfirmMarkAsPaid(invoice.reference)
                              }
                              className="w-100"
                            >
                              Mark as Paid
                            </Button>
                          )
                        ) : (
                          <Button
                            as={Link}
                            to="/support/"
                            variant="success"
                            size="sm"
                            className="w-100"
                          >
                            Get Support
                          </Button>
                        )}
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
        {totalPages > 1 && (
          <Card.Footer className="d-flex justify-content-center">
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Card.Footer>
        )}
      </Card>

      {/* Confirm Modal for Mark as Paid */}
      {showConfirmModal && (
        <Modal show={showConfirmModal} onHide={handleCancelMarkAsPaid} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Mark as Paid</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to mark invoice{" "}
            <strong>{selectedInvoice}</strong> as paid?
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="secondary" onClick={handleCancelMarkAsPaid}>
              Cancel
            </Button>
            <Button variant="success" onClick={handleConfirmMarkAsPaid}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUserSlot: PropTypes.number.isRequired,
};

export default InvoicesTable;
