import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Nav, Spinner, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useReport } from "../context/ReportModal";
import { isAuthenticated, logout } from "../utils/auth";
import { useProcessing } from "../context/ProcessingModal";
import { useUser } from "../hooks/useUser";
import LazyImage from "../utils/LazyImage";
import logo from "../assets/logo.png";
import userImage from "../assets/user-avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { List } from "react-bootstrap-icons";
import { runData } from "../context/processor";
import SearchModal from "./Search";
import { FaCog, FaSearch, FaSignInAlt, FaUsers } from "react-icons/fa";

/**
 * Custom hook to track the window width.
 * @returns {number} The current window width.
 */
const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return width;
};

/**
 * Header component that adapts to screen size.
 * The logo is always shown on the far left.
 * On very small screens (below 576px), only the logo and the menu toggle (aligned to the far right) are displayed.
 * On larger screens, the full header is rendered.
 * The "Get Started" button shows full text on screens ≥768px and, on screens between 576px and 768px,
 * it uses Bootstrap's text-truncate utility to limit the text width.
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.navClosed - Indicates if navigation is closed.
 * @param {Function} props.toggleNav - Function to toggle navigation.
 * @returns {JSX.Element}
 */
const Header = ({ navClosed, toggleNav }) => {
  // Unconditionally call hooks.
  const { user } = useUser();
  const [isReady, setIsReady] = useState(false);
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();
  const [modalShow, setModalShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalQuery, setModalQuery] = useState("");
  const location = useLocation();
  const authenticated = isAuthenticated();
  const windowWidth = useWindowWidth();

  // Breakpoints:
  // Minimal header for very small screens (<576px).
  const isMinimalScreen = windowWidth < 576;
  // For the Get Started button:
  // Show full text when >=768px, and truncated text when between 576px and 768px.
  const showFullButtonText = windowWidth >= 768;

  // Reference for main content.
  const mainContentRef = React.useRef(null);
  useEffect(() => {
    mainContentRef.current = document.querySelector(".main");
  }, []);

  // Simulate readiness.
  useEffect(() => {
    const timer = setTimeout(() => setIsReady(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  // Scroll to top on route change.
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  // (Optional) Monitor scroll events.
  useEffect(() => {
    const mainContent = mainContentRef.current;
    if (!mainContent) return;
    const handleScroll = () => {
      // Optional: update state for scroll-to-top button.
    };
    mainContent.addEventListener("scroll", handleScroll);
    return () => mainContent.removeEventListener("scroll", handleScroll);
  }, []);

  // Function to open the search modal.
  const openSearchModal = useCallback(() => {
    setModalQuery(searchQuery);
    setModalShow(true);
    setSearchQuery("");
  }, [searchQuery]);

  // Logout handler.
  const handleLogout = async () => {
    setProcessing(true);
    try {
      const data = {};
      const response = await runData(data, "/api/logout/");
      if (response.status === 200) {
        setReport({
          show: true,
          message:
            "You have successfully logged out. Thank you for visiting, and we look forward to welcoming you back soon.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message:
            response.data.message ||
            "An error occurred while processing logout.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred while processing logout.",
        type: "error",
      });
    } finally {
      logout();
      setProcessing(false);
    }
  };

  if (!isReady) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" size="lg" />
      </div>
    );
  }

  // Minimal header for very small screens.
  if (isMinimalScreen) {
    return (
      <header className="d-flex justify-content-between align-items-center px-3 py-2">
        <div className="header-left">
          <Link to={`/`}>
            <LazyImage
              src={logo}
              alt="Logo"
              className="ml-2 mr-2"
              style={{ width: navClosed ? "60px" : "130px" }}
            />
          </Link>
        </div>
        <div className="header-right">
          <List
            size={40}
            className="menuicn"
            onClick={toggleNav}
            style={{ marginRight: "0.5rem" }}
          />
        </div>
      </header>
    );
  }

  // Full header for larger screens.
  return (
    <header className="d-flex align-items-center justify-content-between px-3 py-2">
      {/* Left Section: Logo and menu toggle (next to logo) */}
      <div className="header-left d-flex align-items-center">
        <Link to={`/`}>
          <LazyImage
            src={logo}
            alt="Logo"
            className="ml-2 mr-2"
            style={{ width: navClosed ? "60px" : "130px" }}
          />
        </Link>
        <List
          size={40}
          className="menuicn"
          onClick={toggleNav}
          style={{ marginLeft: "0.5rem" }}
        />
      </div>

      {/* Middle Section: Search Input */}
      <div className="search-container flex-grow-1 mx-3">
        <input
          type="text"
          className="form-control search-input shadow-sm"
          placeholder="Search..."
          aria-label="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              openSearchModal();
            }
          }}
        />
        <FaSearch
          className="search-icon"
          onClick={openSearchModal}
          aria-label="Open Search Modal"
        />
      </div>

      {/* Right Section: Theme toggle, account link, user dropdown, and Get Started button */}
      <div className="head-right d-flex align-items-center">
        {[1, 2, 3, 4, 5, 6].includes(user.slot) && (
          <Link to="/customers/" title="Customers">
            <FaUsers size={20} style={{ verticalAlign: "middle" }} />
          </Link>
        )}
        {[1, 2, 3, 7, 8].includes(user.slot) && (
          <Link to="/settings/" title="Settings">
            <FaCog size={20} style={{ verticalAlign: "middle" }} />
          </Link>
        )}

        {authenticated ? (
          <Dropdown
            as={Nav.Item}
            align="end"
            className="ml-2"
            style={{ marginLeft: "0.5rem" }}
          >
            <Dropdown.Toggle
              as={Nav.Link}
              className="pt-1 px-0 d-flex align-items-center"
            >
              <div className="media d-flex align-items-center">
                <LazyImage
                  src={user?.avatar || userImage}
                  alt="User Avatar"
                  className="user-avatar md-avatar rounded-circle"
                  style={{ height: "35px", width: "35px" }}
                />
                <div
                  className={`media-body ms-2 d-flex align-items-center ${
                    navClosed ? "hide-name" : ""
                  }`}
                >
                  <span className="mb-0 fw-bold me-2 d-none d-md-block">
                    {user?.name || "You"}
                  </span>
                  <i className="bi bi-caret-down-fill"></i>
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown dropdown-menu-end mt-2">
              <Link to={`/profile/`} className="dropdown-item fw-bold">
                <FontAwesomeIcon icon={faUserCog} className="me-2" /> Profile
              </Link>
              <Dropdown.Divider />
              <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  className="me-2"
                  color="red"
                />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Link to={`/start/`} className="btn btn-outline-secondary">
            {showFullButtonText ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  maxWidth: "150px",
                }}
              >
                <span
                  className="text-truncate"
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Get Started
                </span>
                <FaSignInAlt />
              </div>
            ) : (
              <FaSignInAlt size={20} />
            )}
          </Link>
        )}
      </div>

      <SearchModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        initialQuery={modalQuery}
      />
    </header>
  );
};

Header.propTypes = {
  navClosed: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default React.memo(Header);
