import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa"; // New import for the edit icon
import { Typeahead } from "react-bootstrap-typeahead"; // New import for typeahead
import "react-bootstrap-typeahead/css/Typeahead.css"; // New CSS import
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

// Define suggestions like in EditBusinessDetails.
const healthCategories = [
  "Domiciliary Care",
  "Residential Care",
  "Nursing Care",
  "Supported Living",
  "Community Care",
  "Mental Health Services",
  "Disability Services",
  "Learning Disability Services",
  "Home Health Care",
  "Rehabilitation Services",
  "Adult Day Services",
  "Social Work Services",
  "Palliative Care",
  "Hospice Care",
  "Ambulance Service",
  "NHS Primary Care (GP Services)",
  "NHS Secondary Care (Hospital Services)",
  "Allied Health Professionals (Physio, OT, etc.)",
  "Children's Services (CAMHS, Paediatric Care)",
  "Sexual Health Services",
  "Substance Misuse Services",
  "Pharmacy Services",
  "Telehealth/Telemedicine",
  "Respite Care",
  "Assistive Technology Services",
  "Crisis Intervention Services",
  "GP Out-of-Hours Services",
  "Public Health Services",
  "NHS Continuing Healthcare",
  "Occupational Therapy Services",
  "Speech and Language Therapy",
  "Maternity Services",
  "Veterans' Health Services",
];

const EditCustomerModal = ({ customer }) => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  // Internal modal state
  const [modalShow, setModalShow] = useState(false);
  const [editName, setEditName] = useState("");
  const [editEmail, setEditEmail] = useState("");
  // Change editServices to be an array
  const [editServices, setEditServices] = useState([]);

  // Initialize fields when modal is opened
  useEffect(() => {
    if (modalShow && customer) {
      setEditName(
        `${customer.name}`
      );
      setEditEmail(customer.email);
      // If customer.services is a comma-separated string, convert it to an array; otherwise default to empty.
      setEditServices(
        customer.services
          ? customer.services.split(",").map((s) => s.trim())
          : []
      );
    }
  }, [modalShow, customer]);

  const handleSave = async () => {
    setProcessing(true);
    try {
      // Update payload; join services array into a comma-separated string if needed.
      const payload = { 
        name: editName, 
        email: editEmail, 
        services: editServices.join(", ") 
      };
      const response = await runData(payload, `/api/customers/${customer.reference}/edit/`);
      if (response.status === 200) {
        setReport({ show: true, message: "Customer updated.", type: "success" });
        window.dispatchEvent(new Event("customers:reload"));
      } else {
        setReport({
          show: true,
          message: response?.data?.message || "Error updating customer.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({ show: true, message: "Error: " + error.message, type: "error" });
    } finally {
      setProcessing(false);
      setModalShow(false);
    }
  };

  return (
    <>
      {/* Edit icon button rendered within the component */}
      <Button variant="link" onClick={() => setModalShow(true)} className="p-0">
        <FaEdit size={20} />
      </Button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="editEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={editEmail}
                onChange={(e) => setEditEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="editServices" className="mt-3">
              <Form.Label>Services</Form.Label>
              {/* Replace Form.Control with a Typeahead for multiple selections */}
              <Typeahead
                id="services-typeahead"
                multiple
                allowNew
                newSelectionPrefix="Add: "
                options={healthCategories}
                placeholder="Select or add services"
                onChange={(selected) => {
                  // selected can be an array of strings or objects; normalize to strings.
                  const selections = selected.map((item) =>
                    typeof item === "string" ? item : item.label || item
                  );
                  setEditServices(selections);
                }}
                selected={editServices}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditCustomerModal.propTypes = {
  customer: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    services: PropTypes.string,
  })
};

export default EditCustomerModal;
