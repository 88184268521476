import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FaLandmark,
  FaUserTie,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaHouseUser,
} from "react-icons/fa";

/**
 * StepAuthorityInfo Component.
 *
 * Renders the authority information step of the multi-step form.
 * The Authority Name field now suggests a comprehensive list of council names
 * as the user types.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.formData - The form data for the profile.
 * @param {function} props.handleChange - Callback to update the form data.
 * @returns {JSX.Element} The rendered step component.
 */
const StepAuthorityInfo = ({ formData, handleChange }) => {
  // Extended list of council names across the UK
  const councils = [
    // England
    "Birmingham City Council",
    "Manchester City Council",
    "Leeds City Council",
    "Liverpool City Council",
    "Sheffield City Council",
    "Bristol City Council",
    "Coventry City Council",
    "Nottingham City Council",
    "Leicester City Council",
    "Kingston upon Hull City Council",
    "Bradford City Council",
    "Stoke-on-Trent City Council",
    "Wolverhampton City Council",
    "Newcastle City Council",
    "Sunderland City Council",
    "Derby City Council",
    "Southampton City Council",
    "Portsmouth City Council",
    "Reading Borough Council",
    "Luton Borough Council",
    "Northampton Borough Council",
    "Swindon Borough Council",
    "Milton Keynes Council",
    "Basingstoke and Deane Borough Council",
    "Woking Borough Council",
    // Wales
    "Cardiff Council",
    "Swansea Council",
    "Newport City Council",
    "Carmarthenshire County Council",
    "Neath Port Talbot County Borough Council",
    "Bridgend County Borough Council",
    "Vale of Glamorgan Council",
    "Rhondda Cynon Taf Council",
    "Merthyr Tydfil County Borough Council",
    "Caerphilly County Borough Council",
    // Scotland
    "Glasgow City Council",
    "Edinburgh City Council",
    "Aberdeen City Council",
    "Dundee City Council",
    "Inverclyde Council",
    "Scottish Borders Council",
    "Fife Council",
    "North Lanarkshire Council",
    "South Lanarkshire Council",
    "Highland Council",
    "Argyll and Bute Council",
    // Northern Ireland
    "Belfast City Council",
    "Derry City and Strabane District Council",
    "Armagh City, Banbridge and Craigavon Borough Council",
    "Lisburn and Castlereagh City Council",
    "Newry, Mourne and Down District Council"
  ];

  return (
    <>
      <h5 className="mb-4">
        <FaLandmark className="me-2" />
        Authority Information
      </h5>
      <Row className="mt-4 mb-4">
        <Col md={6}>
          <Form.Group controlId="authority_name" className="mb-3">
            <Form.Label>
              <FaHouseUser className="me-1" />
              Authority Name
            </Form.Label>
            <Typeahead
              id="authority-name-typeahead"
              options={councils}
              placeholder="Enter authority name"
              onChange={(selected) =>
                handleChange("authority_name", selected[0] || "")
              }
              selected={
                formData.authority_name ? [formData.authority_name] : []
              }
              allowNew
              newSelectionPrefix="Add: "
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="authority_representative" className="mb-3">
            <Form.Label>
              <FaUserTie className="me-1" />
              Authority Representative (Optional)
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter authority representative"
              value={formData.authority_representative}
              onChange={(e) =>
                handleChange("authority_representative", e.target.value)
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          <Form.Group controlId="authority_address" className="mb-3">
            <Form.Label>
              <FaMapMarkerAlt className="me-1" />
              Authority Address
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter authority address"
              value={formData.authority_address}
              onChange={(e) =>
                handleChange("authority_address", e.target.value)
              }
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="authority_phone" className="mb-3">
            <Form.Label>
              <FaPhone className="me-1" />
              Authority Phone
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter authority phone"
              value={formData.authority_phone}
              onChange={(e) =>
                handleChange("authority_phone", e.target.value)
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group controlId="authority_email" className="mb-3">
            <Form.Label>
              <FaEnvelope className="me-1" />
              Authority Email
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter authority email"
              value={formData.authority_email}
              onChange={(e) =>
                handleChange("authority_email", e.target.value)
              }
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="authority_website" className="mb-3">
            <Form.Label>
              <FaGlobe className="me-1" />
              Authority Website
            </Form.Label>
            <Form.Control
              type="url"
              placeholder="Enter authority website"
              value={formData.authority_website}
              onChange={(e) =>
                handleChange("authority_website", e.target.value)
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default StepAuthorityInfo;
