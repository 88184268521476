/**
 * @file ActionModal.jsx
 * @description A modal component for confirming profile actions such as delete, verify, or toggle status.
 */

import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

/**
 * ActionModal Component.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls modal visibility.
 * @param {function} props.onHide - Callback to hide the modal.
 * @param {string} props.actionType - The type of action (e.g., "delete", "verify").
 * @param {Object} props.profile - The profile object to act upon.
 * @param {function} props.onConfirm - Callback when the action is confirmed.
 * @returns {JSX.Element} The rendered ActionModal component.
 */
const ActionModal = ({ show, onHide, actionType, profile, onConfirm }) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Confirm {actionType}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to {actionType} {profile?.description}?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="danger" onClick={onConfirm}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

ActionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  profile: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
};

export default ActionModal;
