import React from "react";
import PropTypes from "prop-types";
import { Card, Badge } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import EditEmail from "./EditEmail";
import DeleteUser from "./DeleteUser";
import ToggleStatus from "./ToggleStatus";
import ChangeProfile from "./ChangeProfile";

/**
 * ProfileCard component displays an individual team member.
 * It shows the member’s details and embeds self-contained action components:
 * <EditEmail user />, <ToggleStatus user />, and <DeleteUser user />.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.user - The team member object.
 * @returns {JSX.Element} A styled card displaying the member and actions.
 */
const ProfileCard = ({ user }) => {
  // Mapping slot numbers to role labels.
  const roleMapping = {
    1: "Developer",
    2: "Director",
    3: "Manager",
    4: "Finance",
    5: "Supervisor",
    6: "Staff",
    7: "Director",
    8: "Manager",
    9: "Supervisor",
    10: "Staff",
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {user.avatar ? (
            <img
              src={user.avatar}
              alt="User Avatar"
              className="rounded-circle me-2"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          ) : (
            <FaUser className="me-2" size={50} />
          )}
          <div>
            <h5 className="mb-0">
              {user.title ? `${user.title} ` : ""}
              {user.name} {user.surname}
            </h5>
            <small className="text-muted">REF: {user.reference}</small>
          </div>
        </div>
        <Badge bg="info">{roleMapping[user.slot] || "N/A"}</Badge>
      </Card.Header>
      <Card.Body>
        <p>
          <strong>Email:</strong> {user.email || "N/A"}
        </p>
        <p>
          <strong>Phone:</strong> {user.phone || "N/A"}
        </p>
        <p>
          <strong>Profile:</strong> {user.profile.business_name || "N/A"} -{" "}
          {user.profile.business_description || "N/A"}
        </p>
      </Card.Body>
      <Card.Footer className="d-flex gap-2">
        <ChangeProfile user={user} />
        <EditEmail user={user} />
        <ToggleStatus user={user} />
        <DeleteUser user={user} />
      </Card.Footer>
    </Card>
  );
};

ProfileCard.propTypes = {
  user: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    avatar: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    profile: PropTypes.shape({
      reference: PropTypes.string.isRequired,
      business_name: PropTypes.string,
      business_description: PropTypes.string,
    }),
    slot: PropTypes.number,
    status: PropTypes.number, // For example: 1 for active; otherwise suspended.
  }).isRequired,
};

export default ProfileCard;
