import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FaEdit,
  FaAlignLeft,
  FaUserTie,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaMobileAlt,
  FaGlobe,
  FaTimes,
  FaSave,
} from "react-icons/fa";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { runData } from "../context/processor";

/**
 * @file EditBusinessDetails.jsx
 * @description A reusable component that displays an edit icon for business details.
 * When clicked, it opens a centered and static modal to update business details.
 * Upon saving, it makes its own API call via runData and dispatches a custom event to reload profiles.
 */
const EditBusinessDetails = ({ profile }) => {
  const [show, setShow] = useState(false);
  const [businessValues, setBusinessValues] = useState({
    business_description: profile.business_description || "",
    business_manager: profile.business_manager || "",
    business_nominated: profile.business_nominated || "",
    email: profile.email || "",
    phone: profile.phone || "",
    mobile: profile.mobile || "",
    website: profile.website || "",
  });

  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  useEffect(() => {
    setBusinessValues({
      business_description: profile.business_description || "",
      business_manager: profile.business_manager || "",
      business_nominated: profile.business_nominated || "",
      email: profile.email || "",
      phone: profile.phone || "",
      mobile: profile.mobile || "",
      website: profile.website || "",
    });
  }, [profile]);

  // Updates a given field in businessValues.
  const handleChange = (field, value) => {
    setBusinessValues({ ...businessValues, [field]: value });
  };

  // Suggestions for the Description field.
  const healthCategories = [
    "Domiciliary Care",
    "Residential Care",
    "Nursing Care",
    "Supported Living",
    "Community Care",
    "Mental Health Services",
    "Disability Services",
    "Learning Disability Services",
    "Home Health Care",
    "Rehabilitation Services",
    "Adult Day Services",
    "Social Work Services",
    "Palliative Care",
    "Hospice Care",
    "Ambulance Service",
    "NHS Primary Care (GP Services)",
    "NHS Secondary Care (Hospital Services)",
    "Allied Health Professionals (Physio, OT, etc.)",
    "Children's Services (CAMHS, Paediatric Care)",
    "Sexual Health Services",
    "Substance Misuse Services",
    "Pharmacy Services",
    "Telehealth/Telemedicine",
    "Respite Care",
    "Assistive Technology Services",
    "Crisis Intervention Services",
    "GP Out-of-Hours Services",
    "Public Health Services",
    "NHS Continuing Healthcare",
    "Occupational Therapy Services",
    "Speech and Language Therapy",
    "Maternity Services",
    "Veterans' Health Services",
  ];

  // Determine if all fields are non-empty.
  // Use String(...).trim() to ensure the value is a string.
  const isFormValid =
    String(businessValues.business_description).trim() !== "" &&
    businessValues.business_manager.trim() !== "" &&
    businessValues.business_nominated.trim() !== "" &&
    businessValues.email.trim() !== "" &&
    businessValues.phone.trim() !== "" &&
    businessValues.mobile.trim() !== "" &&
    businessValues.website.trim() !== "";

  /**
   * Handles saving the updated business details.
   *
   * Closes the modal immediately, shows a processing modal, and makes an API call via runData.
   * Depending on the outcome, it displays a report modal with a success or error message.
   */
  const handleSave = async () => {
    setShow(false);
    setProcessing(true);
    try {
      const response = await runData(
        businessValues,
        `/api/company/${profile.reference}/update-business-details/`
      );
      if (response.status === 200) {
        window.dispatchEvent(new CustomEvent("profiles:reload"));
        setReport({
          show: true,
          message: "Business details updated successfully.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message:
            response?.data?.message || "Failed to update business details",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error updating business details",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShow(true)}
        title="Edit Business Details"
      >
        <FaEdit />
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Business Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Business Description using Typeahead with healthCategories */}
            <Form.Group controlId="business_description" className="mb-3">
              <Form.Label>
                <FaAlignLeft className="me-1" />
                Description
              </Form.Label>
              <Typeahead
                id="business-description-typeahead"
                options={healthCategories}
                placeholder="Select or type description"
                onChange={(selected) => {
                  const value = selected[0];
                  // If the value is an object (from allowNew), use its label property.
                  if (typeof value === "object" && value !== null) {
                    handleChange("business_description", value.label || "");
                  } else {
                    handleChange("business_description", value || "");
                  }
                }}
                selected={
                  businessValues.business_description
                    ? [businessValues.business_description]
                    : []
                }
                allowNew
                newSelectionPrefix="Add: "
              />
            </Form.Group>
            <Form.Group controlId="business_manager" className="mb-3">
              <Form.Label>
                <FaUserTie className="me-1" />
                Manager
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter manager name"
                value={businessValues.business_manager}
                onChange={(e) =>
                  handleChange("business_manager", e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId="business_nominated" className="mb-3">
              <Form.Label>
                <FaUser className="me-1" />
                Nominated
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter nominated individual"
                value={businessValues.business_nominated}
                onChange={(e) =>
                  handleChange("business_nominated", e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>
                <FaEnvelope className="me-1" />
                Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email address"
                value={businessValues.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="phone" className="mb-3">
              <Form.Label>
                <FaPhone className="me-1" />
                Phone
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                value={businessValues.phone}
                onChange={(e) => handleChange("phone", e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="mobile" className="mb-3">
              <Form.Label>
                <FaMobileAlt className="me-1" />
                Mobile
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter mobile number"
                value={businessValues.mobile}
                onChange={(e) => handleChange("mobile", e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="website" className="mb-3">
              <Form.Label>
                <FaGlobe className="me-1" />
                Website
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter website URL"
                value={businessValues.website}
                onChange={(e) => handleChange("website", e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setShow(false)}>
            <FaTimes className="me-1" />
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!isFormValid}
          >
            <FaSave className="me-1" />
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditBusinessDetails.propTypes = {
  profile: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    business_description: PropTypes.string,
    business_manager: PropTypes.string,
    business_nominated: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
};

export default EditBusinessDetails;
