/**
 * @file ViewModal.jsx
 * @description A modal component for viewing details of a company profile.
 */

import React from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col, Badge } from "react-bootstrap";

/**
 * ViewModal Component.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls modal visibility.
 * @param {function} props.onHide - Callback to hide the modal.
 * @param {Object} props.profile - The profile object to view.
 * @returns {JSX.Element} The rendered ViewModal component.
 */
const ViewModal = ({ show, onHide, profile }) => (
  <Modal size="lg" show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>{profile?.description} Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col md={6}>
          <h5>Contact Information</h5>
          <p>Email: {profile?.email}</p>
          <p>Phone: {profile?.phone}</p>
          <p>
            Address: {profile?.address}, {profile?.postcode}
          </p>
        </Col>
        <Col md={6}>
          <h5>Authority Details</h5>
          <p>Name: {profile?.authority_name}</p>
          <p>Contact: {profile?.authority_representative}</p>
          <p>Email: {profile?.authority_email}</p>
        </Col>
      </Row>
      <div className="mt-3">
        <Badge bg={profile?.verified ? "success" : "warning"}>
          {profile?.verified ? "Verified" : "Unverified"}
        </Badge>{" "}
        <Badge bg={profile?.status === 1 ? "primary" : "secondary"}>
          {profile?.status === 1 ? "Active" : "Inactive"}
        </Badge>
      </div>
    </Modal.Body>
  </Modal>
);

ViewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

export default ViewModal;
