import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import { runData } from "../context/processor";
import ChatHistory from "./ChatHistory";
import ChatMessages from "./ChatMessages";
import ChatInput from "./ChatInput";
import AttachmentsPanel from "./AttachmentsPanel";

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [activeThread, setActiveThread] = useState(null);
  const [newChatTitle, setNewChatTitle] = useState("");
  const [threads, setThreads] = useState([]);

  const endOfMessagesRef = useRef(null);
  const chatInputRef = useRef(null);
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();

  useEffect(() => {
    const fetchThreads = async () => {
      try {
        const res = await runData({}, "/api/composer/threads/");
        if (res.status === 200) setThreads(res.data.threads);
      } catch (err) {
        console.error("Error fetching threads", err);
      }
    };
    fetchThreads();
  }, []);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (!activeThread) return;
    const fetchAttachments = async () => {
      try {
        const response = await runData(
          { threadId: activeThread },
          "/api/composer/attachments/"
        );
        if (response.status === 200) setPreviewFiles(response.data.attachments);
      } catch (err) {
        console.error("Error fetching attachments", err);
      }
    };
    fetchAttachments();
  }, [activeThread]);

  const handleNewChat = () => {
    setMessages([]);
    setPreviewFiles([]);
    setActiveThread(null);
    setNewChatTitle("");
    setReport({
      show: true,
      message: "You can now start a new chat!",
      type: "success",
    });
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;
    if (!activeThread && (!newChatTitle.trim() || !inputMessage.trim())) {
      return setReport({
        show: true,
        message:
          "Please enter both a chat title and message to start a new conversation.",
        type: "error",
      });
    }
    const payload = { messageText: inputMessage, threadId: activeThread };
    if (!activeThread) payload.title = newChatTitle;
    const newMsg = {
      content: inputMessage,
      isUser: true,
      createdAt: moment().toISOString(),
    };
    setMessages((prev) => [...prev, newMsg]);
    setInputMessage("");
    setIsTyping(true);
    try {
      const response = await runData(payload, "/api/composer/compose/");
      if (response.status === 200 && response.data.code === 200) {
        if (response.data.thread) {
          setActiveThread(response.data.thread);
          const res = await runData({}, "/api/composer/threads/");
          if (res.status === 200) setThreads(res.data.threads);
        }
        const assistantMsg = {
          content: response.data.messages,
          isUser: false,
          createdAt: moment().toISOString(),
        };
        setMessages((prev) => [...prev, assistantMsg]);
      } else {
        setReport({
          show: true,
          message: response.data.message || "Unknown error from server.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: "Failed to get response from server.",
        type: "error",
      });
    } finally {
      setIsTyping(false);
    }
  };

  const handleCopy = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() =>
        setReport({
          show: true,
          message: "Message copied to clipboard.",
          type: "success",
        })
      )
      .catch(() =>
        setReport({
          show: true,
          message: "Failed to copy message.",
          type: "error",
        })
      );
  };

  const handleRegenerate = async (message) => {
    setIsTyping(true);
    try {
      const payload = { messageText: message, threadId: activeThread };
      if (!activeThread) payload.title = newChatTitle;
      const response = await runData(payload, "/api/composer/compose/");
      if (response.status === 200 && response.data.code === 200) {
        if (response.data.thread) setActiveThread(response.data.thread);
        const assistantMsg = {
          content: response.data.messages,
          isUser: false,
          createdAt: moment().toISOString(),
        };
        setMessages((prev) => [...prev, assistantMsg]);
      } else {
        setReport({
          show: true,
          message: response.data.message || "Unknown error from server.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: "Failed to get response from server.",
        type: "error",
      });
    } finally {
      setIsTyping(false);
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDeleteThread = async (threadId) => {
    setProcessing(true);
    try {
      const res = await runData({ threadId }, "/api/composer/thread/delete/");
      if (res.status === 200) {
        setThreads((prev) => prev.filter((t) => t.threadId !== threadId));
        if (activeThread === threadId) handleNewChat();
        setReport({
          show: true,
          message: res.data.message || "Thread deleted!",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message: res.data.message || "Failed to delete thread.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "Failed to delete thread.",
        type: "error",
      });
      console.error("Error deleting thread", err);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Chat Composer - Care Quality Support</title>
      </Helmet>
      <Row>
        {/* Left Panel: Chat History with sticky behavior */}
        <Col
          md={3}
          style={{
            position: "sticky",
            top: "1.5rem",
            bottom: "1.5rem",
            height: "80vh",
            borderRadius: "1rem",
          }}
        >
          <ChatHistory
            threads={threads}
            activeThread={activeThread}
            onNewChat={handleNewChat}
            onThreadSelect={(thread) => {
              const threadId = thread.threadId;
              setActiveThread(threadId);
              runData({ threadId }, "/api/composer/messages/").then(
                (res) => res.status === 200 && setMessages(res.data.messages)
              );
            }}
            onDeleteThread={(threadId) => {
              handleDeleteThread(threadId);
            }}
          />
        </Col>
        {/* Center Panel: Chat Messages and Chat Input */}
        <Col md={activeThread ? 6 : 9} className="mb-5">
          <ChatMessages
            messages={messages}
            onCopy={handleCopy}
            onRegenerate={handleRegenerate}
            endOfMessagesRef={endOfMessagesRef}
          />
          <ChatInput
            activeThread={activeThread}
            newChatTitle={newChatTitle}
            inputMessage={inputMessage}
            isTyping={isTyping}
            chatInputRef={chatInputRef}
            onNewChatTitleChange={(e) => setNewChatTitle(e.target.value)}
            onMessageChange={(e) => setInputMessage(e.target.value)}
            onSend={handleSend}
          />
        </Col>
        {/* Right Panel: Attachments with sticky behavior */}
        {activeThread && (
          <Col
            md={3}
            style={{
              position: "sticky",
              top: "1.5rem",
              bottom: "1.5rem",
              height: "80vh",
              borderRadius: "1rem",
            }}
          >
            <AttachmentsPanel
              threadId={activeThread} // added to pass the thread id on upload
              previewFiles={previewFiles}
              onRemoveFile={(index) => {
                const fileToRemove = previewFiles[index];
                setProcessing(true);
                runData({ fileId: fileToRemove.fileId }, "/api/composer/file/remove/")
                  .then((response) => {
                    if (response.status === 200) {
                      setPreviewFiles((prev) =>
                        prev.filter((_, i) => i !== index)
                      );
                      setReport({
                        show: true,
                        message: "File removed successfully.",
                        type: "success",
                      });
                    } else {
                      setReport({
                        show: true,
                        message:
                          response.data.message || "Failed to remove file.",
                        type: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    setReport({
                      show: true,
                      message: "Error removing file: " + error.message,
                      type: "error",
                    });
                  })
                  .finally(() => setProcessing(false));
              }}
              onFileSelect={(uploadedData) =>
                setPreviewFiles((prev) => [...prev, uploadedData])
              }
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ChatPage;
