import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FaEdit,
  FaHome,
  FaBuilding,
  FaMap,
  FaGlobe,
  FaMailBulk,
  FaTimes,
  FaSave,
} from "react-icons/fa";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { runData } from "../context/processor";

/**
 * @file EditAddress.jsx
 * @description A reusable component that displays an edit icon for the address.
 * When clicked, it opens a modal (centered and static) that allows the user to update the address details.
 * Upon saving, it makes its own API call via runData and dispatches a custom event to reload profiles.
 */
const EditAddress = ({ profile }) => {
  const [show, setShow] = useState(false);
  const [addressValues, setAddressValues] = useState({
    address: profile.address || "",
    city: profile.city || "",
    county: profile.county || "",
    region: profile.region || "",
    postcode: profile.postcode || "",
  });

  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  useEffect(() => {
    setAddressValues({
      address: profile.address || "",
      city: profile.city || "",
      county: profile.county || "",
      region: profile.region || "",
      postcode: profile.postcode || "",
    });
  }, [profile]);

  // Updates a given field in addressValues.
  const handleChange = (field, value) => {
    setAddressValues({ ...addressValues, [field]: value });
  };

  // Suggestion arrays.
  const regions = ["England", "Scotland", "Wales", "Northern Ireland"];
  const cities = [
    // England
    "London",
    "Birmingham",
    "Manchester",
    "Leeds",
    "Liverpool",
    "Sheffield",
    "Bristol",
    "Newcastle upon Tyne",
    "Nottingham",
    "Leicester",
    "Southampton",
    "Portsmouth",
    "Brighton",
    "Plymouth",
    "Reading",
    "Milton Keynes",
    "Northampton",
    "Derby",
    "Oxford",
    "Cambridge",
    "Stoke-on-Trent",
    "Wolverhampton",
    "York",
    "Norwich",
    "Bournemouth",
    "Peterborough",
    // Scotland
    "Glasgow",
    "Edinburgh",
    "Aberdeen",
    "Dundee",
    "Inverness",
    "Perth",
    "Stirling",
    "Ayr",
    "Dumfries",
    "Falkirk",
    // Wales
    "Cardiff",
    "Swansea",
    "Newport",
    "Bangor",
    "Wrexham",
    "St Davids",
    "Barry",
    // Northern Ireland
    "Belfast",
    "Derry",
    "Lisburn",
    "Newry",
    "Armagh",
    "Coleraine",
  ];
  const counties = [
    // England
    "Bedfordshire",
    "Berkshire",
    "Bristol",
    "Buckinghamshire",
    "Cambridgeshire",
    "Cheshire",
    "Cornwall",
    "County Durham",
    "Cumbria",
    "Derbyshire",
    "Devon",
    "Dorset",
    "East Riding of Yorkshire",
    "East Sussex",
    "Essex",
    "Gloucestershire",
    "Greater London",
    "Greater Manchester",
    "Hampshire",
    "Herefordshire",
    "Hertfordshire",
    "Isle of Wight",
    "Kent",
    "Lancashire",
    "Leicestershire",
    "Lincolnshire",
    "Merseyside",
    "Norfolk",
    "North Yorkshire",
    "Northamptonshire",
    "Northumberland",
    "Nottinghamshire",
    "Oxfordshire",
    "Rutland",
    "Shropshire",
    "Somerset",
    "South Yorkshire",
    "Staffordshire",
    "Suffolk",
    "Surrey",
    "Tyne and Wear",
    "Warwickshire",
    "West Midlands",
    "West Sussex",
    "West Yorkshire",
    "Wiltshire",
    "Worcestershire",
    // Wales
    "Anglesey",
    "Blaenau Gwent",
    "Bridgend",
    "Caerphilly",
    "Cardiff",
    "Carmarthenshire",
    "Ceredigion",
    "Conwy",
    "Denbighshire",
    "Flintshire",
    "Gwynedd",
    "Merthyr Tydfil",
    "Monmouthshire",
    "Neath Port Talbot",
    "Newport",
    "Pembrokeshire",
    "Powys",
    "Rhondda Cynon Taf",
    "Swansea",
    "Torfaen",
    "Vale of Glamorgan",
    "Wrexham",
    // Scotland
    "Aberdeenshire",
    "Angus",
    "Argyll and Bute",
    "Ayrshire",
    "Clackmannanshire",
    "Dumfries and Galloway",
    "Dunbartonshire",
    "East Lothian",
    "Fife",
    "Highland",
    "Inverclyde",
    "Midlothian",
    "Moray",
    "Orkney",
    "Perth and Kinross",
    "Renfrewshire",
    "Scottish Borders",
    "Shetland",
    "Stirlingshire",
    "West Lothian",
    // Northern Ireland
    "Antrim",
    "Armagh",
    "Down",
    "Fermanagh",
    "Londonderry",
    "Tyrone",
  ];

  // Determine if all required fields are non-empty.
  const isFormValid =
    addressValues.address.trim() !== "" &&
    addressValues.city.trim() !== "" &&
    addressValues.county.trim() !== "" &&
    addressValues.region.trim() !== "" &&
    addressValues.postcode.trim() !== "";

  /**
   * Handles saving the updated address.
   * Closes the modal immediately, shows a processing modal, and makes an API call via runData.
   * Depending on the outcome, it then displays a report modal with a success or error message.
   */
  const handleSave = async () => {
    setShow(false);
    setProcessing(true);
    try {
      const response = await runData(
        addressValues,
        `/api/company/${profile.reference}/update-address/`
      );
      if (response.status === 200) {
        window.dispatchEvent(new CustomEvent("profiles:reload"));
        setReport({
          show: true,
          message: "Address updated successfully.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message: response?.data?.message || "Failed to update address",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error updating address",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button variant="link" onClick={() => setShow(true)} title="Edit Address">
        <FaEdit />
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Address Line Field */}
            <Form.Group controlId="address" className="mb-3">
              <Form.Label>
                <FaHome className="me-1" />
                Address Line
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter building number, street, etc."
                value={addressValues.address}
                onChange={(e) => handleChange("address", e.target.value)}
              />
            </Form.Group>

            {/* City Field */}
            <Form.Group controlId="city" className="mb-3">
              <Form.Label>
                <FaBuilding className="me-1" />
                City
              </Form.Label>
              <Typeahead
                id="city-typeahead"
                options={cities}
                placeholder="Select or type city"
                onChange={(selected) => {
                  const value = selected[0];
                  if (typeof value === "object" && value !== null) {
                    handleChange("city", value.label || "");
                  } else {
                    handleChange("city", value || "");
                  }
                }}
                selected={addressValues.city ? [addressValues.city] : []}
                allowNew
                newSelectionPrefix="Add: "
              />
            </Form.Group>

            {/* County Field */}
            <Form.Group controlId="county" className="mb-3">
              <Form.Label>
                <FaMap className="me-1" />
                County
              </Form.Label>
              <Typeahead
                id="county-typeahead"
                options={counties}
                placeholder="Select or type county"
                onChange={(selected) => {
                  const value = selected[0];
                  if (typeof value === "object" && value !== null) {
                    handleChange("county", value.label || "");
                  } else {
                    handleChange("county", value || "");
                  }
                }}
                selected={addressValues.county ? [addressValues.county] : []}
                allowNew
                newSelectionPrefix="Add: "
              />
            </Form.Group>

            {/* Region Field */}
            <Form.Group controlId="region" className="mb-3">
              <Form.Label>
                <FaMap className="me-1" />
                Region
              </Form.Label>
              <Typeahead
                id="region-typeahead"
                options={regions}
                placeholder="Select or type region"
                onChange={(selected) => {
                  const value = selected[0];
                  if (typeof value === "object" && value !== null) {
                    handleChange("region", value.label || "");
                  } else {
                    handleChange("region", value || "");
                  }
                }}
                selected={addressValues.region ? [addressValues.region] : []}
                allowNew
                newSelectionPrefix="Add: "
              />
            </Form.Group>

            {/* Postcode Field */}
            <Form.Group controlId="postcode" className="mb-3">
              <Form.Label>
                <FaMailBulk className="me-1" />
                Postcode
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter postcode"
                value={addressValues.postcode}
                onChange={(e) =>
                  handleChange("postcode", e.target.value.toUpperCase())
                }
                maxLength="8"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setShow(false)}>
            <FaTimes className="me-1" />
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!isFormValid}
          >
            <FaSave className="me-1" />
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditAddress.propTypes = {
  profile: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    address: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    region: PropTypes.string,
    postcode: PropTypes.string,
  }).isRequired,
};

export default EditAddress;
