import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaEnvelope, FaTimes, FaSave } from "react-icons/fa";
import PropTypes from "prop-types";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

/**
 * EditEmail component renders a self-contained modal for editing a user's email.
 * It can be used as <EditEmail user={user} />.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.user - The user object.
 * @returns {JSX.Element} The EditEmail modal component.
 */
const EditEmail = ({ user }) => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  const [modalShow, setModalShow] = useState(false);
  const [email, setEmail] = useState(user.email);

  // Update email state when the user prop changes.
  useEffect(() => {
    setEmail(user.email);
  }, [user.email]);

  /**
   * Handles saving the updated email by making an API request.
   *
   * @async
   * @function handleSave
   */
  const handleSave = async () => {
    setModalShow(false);
    setProcessing(true);
    try {
      const payload = { reference: user.reference, email };
      const response = await runData(
        payload,
        "/api/team/update-email/"
      );
      if (response.status === 200) {
        setReport({
          show: true,
          message: response?.data?.message || "Email updated successfully.",
          type: "success",
        });
        // Dispatch event to reload team data.
        window.dispatchEvent(new Event("team:reload"));
      } else {
        setReport({
          show: true,
          message: response?.data?.message || "Failed to update email.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error updating email.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant="outline-info"
        size="sm"
        style={{ flex: 1 }}
        onClick={() => setModalShow(true)}
      >
        <FaEnvelope className="me-1" />
        Change
      </Button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Email Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formUserEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter new email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            <FaTimes className="me-1" />
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            <FaSave className="me-1" />
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditEmail.propTypes = {
  user: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditEmail;
