import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Alert, Pagination } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { NavLink, useNavigate } from "react-router-dom";
import { FaAngleLeft, FaUsers } from "react-icons/fa";
import PropTypes from "prop-types";
import { runData } from "../context/processor";
import { useUser } from "../hooks/useUser";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import ProfileCard from "./ProfileCard";
import CreateUser from "./CreateUser";

/**
 * TeamPage component manages the display of team members.
 *
 * @component
 * @returns {JSX.Element} The TeamPage component.
 */
const TeamPage = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const usersPerPage = 6;
  // Ref to track if the component is still mounted to avoid updating state after unmount
  const isMounted = useRef(true);

  /**
   * Validates the user slot and redirects if the user's role is not permitted.
   */
  useEffect(() => {
    if (user?.slot && ![1, 2, 3, 7, 8].includes(user.slot)) {
      navigate("/");
    }
  }, [user, navigate]);

  /**
   * Loads the team members for the authenticated user's company.
   *
   * @async
   * @function loadTeamMembers
   * @returns {Promise<void>}
   */
  const loadTeamMembers = async () => {
    setProcessing(true);
    setLoading(true);
    try {
      // The API endpoint returns the list of users in the "users" property.
      const response = await runData({}, "/api/team/");
      if (response.status === 200) {
        // Ensure the component is still mounted before updating state
        if (isMounted.current) {
          setTeamMembers(response.data.users);
          setFetchError(null);
        }
      } else {
        if (isMounted.current) {
          setFetchError(
            response.data.message || "Failed to load team members."
          );
        }
      }
    } catch (error) {
      if (isMounted.current) {
        setFetchError(
          error.message || "Unexpected error while loading team members."
        );
      }
    } finally {
      if (isMounted.current) {
        setProcessing(false);
        setLoading(false);
      }
    }
  };

  // Load team members when the component mounts.
  useEffect(() => {
    loadTeamMembers();
    // Cleanup function to mark the component as unmounted.
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Listen for the "team:reload" event to refresh the team list.
  useEffect(() => {
    const handleReload = () => {
      loadTeamMembers();
    };
    window.addEventListener("team:reload", handleReload);
    return () => {
      window.removeEventListener("team:reload", handleReload);
    };
  }, []);

  // Pagination calculation.
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = teamMembers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(teamMembers.length / usersPerPage);

  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Team Management - Care Quality Support</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center" style={{ gap: "1.5rem" }}>
          <NavLink
            title="Back"
            className="active"
            style={{
              textDecoration: "none",
              color: "inherit",
              borderRight: "solid 2px red",
              marginRight: "10px",
            }}
            to={`/`}
          >
            <FaAngleLeft
              style={{ marginRight: "0.5rem" }}
              className="text-primary"
            />
            Back
          </NavLink>
          <Col className="text-start">
            <h2 className="ms-2">
              Team Management <FaUsers className="ms-2" />
            </h2>
            <p className="ms-2">
              Manage your company users - update profile, email and status.
            </p>
          </Col>
        </div>
        {/* Render the self-managed CreateUser component */}
        <CreateUser />
      </div>
      <hr />
      {fetchError ? (
        <Row className="justify-content-center">
          <Col xs={12} md={8} className="text-center">
            <Alert variant="danger">{fetchError}</Alert>
          </Col>
        </Row>
      ) : teamMembers.length === 0 && !loading ? (
        <Row className="justify-content-center">
          <Col xs={12} md={8} className="text-center">
            <Alert variant="info">No team members found.</Alert>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="g-4">
            {currentUsers.map((member) => (
              <Col key={member.reference} xs={12} md={6} lg={4}>
                <ProfileCard user={member} />
              </Col>
            ))}
          </Row>
          {totalPages > 1 && (
            <div className="d-flex justify-content-center mt-4">
              <Pagination>
                <Pagination.Prev
                  onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
                  disabled={currentPage === 1}
                />
                {[...Array(totalPages)].map((_, idx) => (
                  <Pagination.Item
                    key={idx + 1}
                    active={idx + 1 === currentPage}
                    onClick={() => setCurrentPage(idx + 1)}
                  >
                    {idx + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setCurrentPage((p) => Math.min(totalPages, p + 1))
                  }
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

TeamPage.propTypes = {
  // Although not used as a prop in this component,
  // PropTypes is defined to assist future maintenance if props are introduced.
  user: PropTypes.object,
};

export default TeamPage;
