import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { FaBriefcase } from "react-icons/fa";
import DeleteUser from "./DeleteCustomer";
import ToggleStatus from "./ToggleStatus";
import AccessAccount from "./AccessAccount";
import EditCustomerModal from "./EditCustomerModal"; // New import

const ProfileCard = ({ customer }) => {
  return (
    <>
      <Card className="h-100 shadow-sm">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <FaBriefcase className="me-2" color="grey" size={50} />
            <div>
              <h5 className="mb-0">
                {customer.name}
              </h5>
              <small className="text-muted">REF: {customer.reference}</small>
            </div>
          </div>
          {/* The edit functionality is now internal */}
          <EditCustomerModal customer={customer} />
        </Card.Header>
        <Card.Body>
          <p>
            <strong>Email:</strong> {customer.email || "N/A"}
          </p>
          {customer.services && (
            <p>
              <strong>Services:</strong> {customer.services}
            </p>
          )}
        </Card.Body>
        <Card.Footer className="d-flex gap-2">
          <AccessAccount customer={customer} />
          <ToggleStatus customer={customer} />
          <DeleteUser customer={customer} />
        </Card.Footer>
      </Card>
    </>
  );
};

ProfileCard.propTypes = {
  customer: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    avatar: PropTypes.string,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    services: PropTypes.string,
    slot: PropTypes.number,
    status: PropTypes.number,
  }).isRequired,
};

export default ProfileCard;
