import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddCustomerModal from "./AddCustomerModal";

const AddCustomer = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        Add Customer
      </Button>
      <AddCustomerModal show={showModal} onHide={() => setShowModal(false)} />
    </>
  );
};

export default AddCustomer;
