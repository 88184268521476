import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FaUserCog, FaTimes, FaSave } from "react-icons/fa";
import PropTypes from "prop-types";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { useUser } from "../hooks/useUser";

/**
 * ChangeProfile component renders a self-contained modal for updating a user's
 * company profile and role.
 *
 * The selected user (whose profile/role is being updated) is passed via props,
 * while the logged‐in user's slot (renamed as currentUserSlot) is used to determine
 * which roles are allowed.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.user - The selected user object to be updated.
 * @returns {JSX.Element} The ChangeProfile modal component.
 */
const ChangeProfile = ({ user }) => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  // Retrieve the logged-in user to determine allowed roles.
  const { user: currentUser } = useUser();
  const currentUserSlot = currentUser.slot;

  const [modalShow, setModalShow] = useState(false);
  const [allProfiles, setAllProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);

  // Full mapping of roles.
  const roles = [
    { slot: 1, label: "Developer" },
    { slot: 2, label: "Director" },
    { slot: 3, label: "Manager" },
    { slot: 4, label: "Finance" },
    { slot: 5, label: "Supervisor" },
    { slot: 6, label: "Staff" },
    { slot: 7, label: "Director" },
    { slot: 8, label: "Manager" },
    { slot: 9, label: "Supervisor" },
    { slot: 10, label: "Staff" },
  ];

  // Determine allowed roles based on the logged-in user's slot.
  const allowedRoles = useMemo(() => {
    if ([1, 2, 3].includes(currentUserSlot)) {
      return roles.filter((role) => role.slot >= 1 && role.slot <= 6);
    } else if ([7, 8].includes(currentUserSlot)) {
      return roles.filter((role) => role.slot >= 7 && role.slot <= 10);
    }
    return roles;
  }, [currentUserSlot]);

  /**
   * When the modal is shown, load available profiles from the API.
   * Pre-select the selected user's current profile if a match is found.
   */
  useEffect(() => {
    if (modalShow) {
      const loadProfiles = async () => {
        setProcessing(true);
        try {
          const response = await runData({}, "/api/company/");
          if (response.status === 200) {
            const profiles = response.data.profiles;
            setAllProfiles(profiles);
            // Compare as strings for a reliable match.
            const currentProfile = profiles.find(
              (p) =>
                p.reference.toString() === user.profile.reference.toString()
            );
            if (currentProfile) {
              setSelectedProfile([currentProfile]);
            } else {
              // Do not default to any profile if no match is found.
              setSelectedProfile([]);
            }
          }
        } catch (error) {
          // Optionally log or handle the error.
        } finally {
          setProcessing(false);
        }
      };
      loadProfiles();
    }
  }, [modalShow, setProcessing, user.profile]);

  /**
   * When the modal is shown, default the role selection to the selected user's current role
   * only if no role has already been selected.
   */
  useEffect(() => {
    if (modalShow && selectedRole.length === 0) {
      const currentRole = roles.find((role) => role.slot === user.slot);
      if (currentRole) {
        setSelectedRole([currentRole]);
      }
    }
  }, [modalShow, user.slot]);

  /**
   * Handles submitting the updated profile and role via an API call.
   */
  const handleSubmit = async () => {
    if (selectedProfile.length > 0) {
      setModalShow(false);
      setProcessing(true);
      try {
        const payload = {
          reference: user.reference,
          profile: selectedProfile[0].reference,
          slot: selectedRole.length > 0 ? selectedRole[0].slot : user.slot,
        };
        const response = await runData(payload, "/api/team/update-profile/");
        if (response.status === 200) {
          setReport({
            show: true,
            message: "Profile and role updated successfully.",
            type: "success",
          });
          // Dispatch event to reload team data.
          window.dispatchEvent(new Event("team:reload"));
        } else {
          setReport({
            show: true,
            message:
              response?.data?.message || "Failed to update profile and role.",
            type: "error",
          });
        }
      } catch (error) {
        setReport({
          show: true,
          message: error.message || "Error updating profile and role.",
          type: "error",
        });
      } finally {
        setProcessing(false);
      }
    }
  };

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        style={{ flex: 1 }}
        onClick={() => setModalShow(true)}
      >
        <FaUserCog className="me-1" />
        Profile
      </Button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Profile & Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="role" className="mb-3">
            <Form.Label>User Role</Form.Label>
            <Typeahead
              id="role-typeahead"
              options={allowedRoles}
              placeholder="Select a role..."
              labelKey="label"
              onChange={(selected) => setSelectedRole(selected)}
              selected={selectedRole}
              clearButton
            />
          </Form.Group>
          <Form.Group controlId="profile">
            <Form.Label>Company Profile</Form.Label>
            <Typeahead
              id="profile-typeahead"
              labelKey={(option) =>
                `${option.business_name} - ${option.business_description}`
              }
              options={allProfiles}
              placeholder="Select a profile..."
              onChange={(selected) => setSelectedProfile(selected)}
              selected={selectedProfile}
              disabled={allProfiles.length === 1}
              clearButton
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            <FaTimes className="me-1" />
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={selectedProfile.length === 0}
          >
            <FaSave className="me-1" />
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ChangeProfile.propTypes = {
  user: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    profile: PropTypes.shape({
      reference: PropTypes.string.isRequired,
      business_name: PropTypes.string,
      business_description: PropTypes.string,
    }).isRequired,
    slot: PropTypes.number.isRequired,
  }).isRequired,
};

export default ChangeProfile;
