// src/components/Navigation.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faDashboard,
  faFolder,
  faLifeRing,
  faRobot,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { logout } from "../utils/auth";
import { Button } from "react-bootstrap";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import { runData } from "../context/processor";

const Navigation = ({ navClosed, toggleNav }) => {
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();

  // Handle user logout
  const handleLogout = async () => {
    setProcessing(true);
    try {
      const data = {};
      const response = await runData(data, "/api/logout/");

      if (response.status === 200) {
        // Show the logout success message before logging out
        setReport({
          show: true,
          message:
            "You have successfully logged out. Thank you for visiting, and we look forward to welcoming you back soon.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message:
            response.data.message || "An error occurred while logging out.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred while logging out.",
        type: "error",
      });
    } finally {
      logout(); // Calls the logout function to clear session
      setProcessing(false);
    }
  };

  // State to determine if the view is on a mobile device
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to determine heading text visibility based on the layout state
  const getHeadingText = (title) => {
    if (isMobile) {
      return title;
    }
    return navClosed ? "" : title;
  };

  return (
    <div
      className={`navcontainer ${navClosed ? "navclose" : ""}`}
      style={{
        boxShadow: "10px 0 15px -5px rgba(0, 0, 0, 0.3)",
        minWidth: "100px",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      <nav className="nav" style={{ boxSizing: "border-box" }}>
        {/* Navigation Options */}
        <div className="nav-options mt-2">
          {/* Dashboard Section */}
          {[
            { to: "/", label: "Dashboard", icon: faDashboard },
            { to: "/directories/", label: "Directories", icon: faFolder },
            { to: "/composer/", label: "Composer", icon: faRobot },
          ].map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={`nav-option option text-truncate ${navClosed ? "hidden" : ""}`}
              onClick={isMobile ? toggleNav : null}
            >
              <FontAwesomeIcon icon={link.icon} className="nav-img" />
              <h3 className="mb-0 ms-2 fw-normal link-text">
                {getHeadingText(link.label)}
              </h3>
            </NavLink>
          ))}

          {/* Getting Help Section */}
          <h6 className="mt-5">{getHeadingText("System Help")}</h6>
          {[
            { to: "/tutorials/", label: "Tutorials", icon: faBook },
            { to: "/support/", label: "Support", icon: faLifeRing },
          ].map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={`nav-option option text-truncate ${navClosed ? "hidden" : ""}`}
              onClick={isMobile ? toggleNav : null}
            >
              <FontAwesomeIcon icon={link.icon} className="nav-img" />
              <h3 className="mb-0 ms-2 fw-normal link-text">
                {getHeadingText(link.label)}
              </h3>
            </NavLink>
          ))}
        </div>

        {/* Sticky Logout Button */}
        <Button
          onClick={handleLogout}
          className={`nav-option option logout-button mt-4 py-4`}
          style={{
            borderRadius: "0",
            width: "100%",
            boxSizing: "border-box",
            overflow: "hidden",
          }}
        >
          <FontAwesomeIcon icon={faSignOutAlt} className="nav-img" />
          <h3 className={`${navClosed ? "hidden" : ""} mb-0 ms-2 fw-normal link-text`}>
            Logout
          </h3>
        </Button>
      </nav>
    </div>
  );
};

// Add PropTypes validation for the 'navClosed' and 'toggleNav' props
Navigation.propTypes = {
  navClosed: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func,
};

export default Navigation;
