import React, { useState, useEffect } from "react";
import { Container, Modal, Button, Form } from "react-bootstrap";
import { runData } from "../context/processor";
// Import context hooks for processing and report modals
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

/**
 * EditBanking component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.details - The initial banking details.
 * @param {Function} props.onUpdate - Callback function invoked with updated details.
 * @returns {JSX.Element} Rendered EditBanking component.
 */
const EditBanking = ({ details, onUpdate }) => {
  // Local state for modal visibility and banking details.
  const [showFormModal, setShowFormModal] = useState(false);
  const [bankingDetails, setBankingDetails] = useState(details);

  // Context methods for processing and report modals.
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  // Update banking details state when details prop changes.
  useEffect(() => {
    setBankingDetails(details);
  }, [details]);

  /**
   * Validates the banking details.
   *
   * @function validateBankingDetails
   * @returns {boolean} Returns true if all required fields are filled.
   */
  const validateBankingDetails = () => {
    const { accountName, bankName, sortCode, accountNumber, swiftBic, iban } =
      bankingDetails;

    // Check for any required field that is empty or only whitespace.
    if (
      !accountName?.trim() ||
      !bankName?.trim() ||
      !sortCode?.trim() ||
      !accountNumber?.trim() ||
      !swiftBic?.trim() ||
      !iban?.trim()
    ) {
      // Trigger the report modal to show an error message.
      setReport({
        show: true,
        message: "All fields are required.",
        type: "error",
      });
      // Return false to indicate validation failure.
      return false;
    }
    // Additional field validations (e.g., regex validations) can be added here.
    return true;
  };

  /**
   * Handles input field changes.
   *
   * @function handleInputChange
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  /**
   * Handles saving the updated banking details.
   *
   * @async
   * @function handleSave
   */
  const handleSave = async () => {
    // 1) Validate form fields locally first
    if (!validateBankingDetails()) {
      // If validation fails, close the form and show the report error
      setShowFormModal(false);
      return;
    }

    // 2) Close the form and show processing
    setShowFormModal(false);
    setProcessing(true);

    try {
      // Assuming '/api/banking/update/' is your endpoint for updating details.
      const response = await runData(bankingDetails, "/api/banking/update/");
      if (response.status === 200) {
        // On success, trigger report modal with success message.
        setReport({
          show: true,
          message: "Banking details updated successfully.",
          type: "success",
        });
        // Notify the parent component of the updated details.
        if (onUpdate) onUpdate(bankingDetails);
      } else {
        // On failure, trigger report modal with error message.
        setReport({
          show: true,
          message:
            response?.data?.message ||
            "An error occurred while updating details.",
          type: "error",
        });
      }
    } catch (error) {
      // Trigger report modal for unexpected errors.
      setReport({
        show: true,
        message: error.message || "Unexpected error occurred.",
        type: "error",
      });
    } finally {
      // Ensure processing modal is hidden after the process.
      setProcessing(false);
    }
  };

  /**
   * Opens the banking details form modal.
   *
   * @function handleOpenModal
   */
  const handleOpenModal = () => {
    setShowFormModal(true);
  };

  /**
   * Closes the banking details form modal.
   *
   * @function handleCloseModal
   */
  const handleCloseModal = () => {
    setShowFormModal(false);
  };

  return (
    <Container>
      <Button variant="primary" onClick={handleOpenModal}>
        Edit Banking Details
      </Button>

      {/* Render the form modal only if it is active */}
      {showFormModal && (
        <Modal show={showFormModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Banking Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="accountName" className="mb-3">
                <Form.Label>Account Name</Form.Label>
                <Form.Control
                  type="text"
                  name="accountName"
                  value={bankingDetails.accountName}
                  onChange={handleInputChange}
                  placeholder="Enter account name"
                />
              </Form.Group>

              <Form.Group controlId="bankName" className="mb-3">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  name="bankName"
                  value={bankingDetails.bankName}
                  onChange={handleInputChange}
                  placeholder="Enter bank name"
                />
              </Form.Group>

              <Form.Group controlId="sortCode" className="mb-3">
                <Form.Label>Sort Code</Form.Label>
                <Form.Control
                  type="text"
                  name="sortCode"
                  value={bankingDetails.sortCode}
                  onChange={handleInputChange}
                  placeholder="Enter sort code"
                />
              </Form.Group>

              <Form.Group controlId="accountNumber" className="mb-3">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  name="accountNumber"
                  value={bankingDetails.accountNumber}
                  onChange={handleInputChange}
                  placeholder="Enter account number"
                />
              </Form.Group>

              <Form.Group controlId="swiftBic" className="mb-3">
                <Form.Label>SWIFT/BIC</Form.Label>
                <Form.Control
                  type="text"
                  name="swiftBic"
                  value={bankingDetails.swiftBic}
                  onChange={handleInputChange}
                  placeholder="Enter SWIFT/BIC"
                />
              </Form.Group>

              <Form.Group controlId="iban" className="mb-3">
                <Form.Label>IBAN</Form.Label>
                <Form.Control
                  type="text"
                  name="iban"
                  value={bankingDetails.iban}
                  onChange={handleInputChange}
                  placeholder="Enter IBAN"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default EditBanking;
