import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  ChevronBarUp,
  Facebook,
  Linkedin,
  TwitterX,
  Youtube,
} from "react-bootstrap-icons";
import { Container } from "react-bootstrap";

/**
 * Footer component for Marmasco Technologies.
 * Renders a responsive footer with a solid background, refined typography,
 * social media icons, internal navigation links, copyright information,
 * and a smooth scroll-to-top button.
 *
 * @component
 * @example
 * return (
 *   <Footer />
 * )
 */
const Footer = React.memo(() => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const location = useLocation();
  const mainContentRef = useRef(null);
  const SCROLL_THRESHOLD = 100;

  // Set reference to the main content element.
  useEffect(() => {
    mainContentRef.current = document.querySelector(".main");
  }, []);

  // Toggle scroll-to-top button visibility based on main content scroll.
  useEffect(() => {
    const mainContent = mainContentRef.current;
    if (!mainContent) return;
    const handleScroll = () => {
      setShowScrollToTop(mainContent.scrollTop > SCROLL_THRESHOLD);
    };
    mainContent.addEventListener("scroll", handleScroll);
    return () => mainContent.removeEventListener("scroll", handleScroll);
  }, []);

  // Smoothly scroll to top of main content.
  const scrollToTop = useCallback(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  // Scroll to top when the route changes.
  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const scrollButtonStyles = {
    position: "fixed",
    bottom: "50px",
    right: "50px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "50%",
    color: "#fff",
    backgroundColor: "var(--secondary)", // Using our secondary color
    border: "none",
    cursor: "pointer",
    zIndex: 1000,
  };

  return (
    <Container fluid className="mt-5">
      <footer role="contentinfo">
        <div className="row py-2 px-2 mt-2">
          {/* Social Media Icons */}
          <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
            <ul
              className="list-unstyled d-flex flex-wrap mb-0"
              style={{ fontSize: "0.75rem" }}
            >
              <li className="me-3 mb-2">
                <a
                  href="https://www.facebook.com/Carequalitysupport/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                  className="text-reset"
                >
                  <Facebook size={20} />
                </a>
              </li>
              <li className="me-3 mb-2">
                <a
                  href="https://www.linkedin.com/company/100574366/admin/page-posts/published/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                  className="text-reset"
                >
                  <Linkedin size={20} />
                </a>
              </li>
              <li className="me-3 mb-2">
                <a
                  href="https://x.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="X (formerly Twitter)"
                  className="text-reset"
                >
                  <TwitterX size={20} />
                </a>
              </li>
              <li className="me-3 mb-2">
                <a
                  href="https://youtube.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Youtube"
                  className="text-reset"
                >
                  <Youtube size={20} />
                </a>
              </li>
            </ul>
          </div>

          {/* Navigation Links */}
          <div className="col-12 col-md-4 d-flex justify-content-center mb-3 mb-md-0">
            <ul
              className="list-unstyled d-flex flex-wrap mb-0 justify-content-center"
              style={{ fontSize: "0.75rem" }}
            >
              <li className="me-3 mb-2">
                <a
                  href="https://carequalitysupport.co.uk/about-us/"
                  className="text-reset"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="About Us"
                >
                  Contact Us
                </a>
              </li>
              <li className="me-3 mb-2">
                <a
                  href="https://carequalitysupport.co.uk/contact-us/"
                  className="text-reset"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Contact Us"
                >
                  Contact Us
                </a>
              </li>
              <li className="me-3 mb-2">
                <a
                  href="https://carequalitysupport.co.uk/terms-and-conditions-a/"
                  className="text-reset"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>

          {/* Copyright */}
          <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end align-items-center">
            <small style={{ fontSize: "0.75rem" }}>
              &copy; {new Date().getFullYear()} Care Quality Support. All rights
              reserved.
            </small>
          </div>
        </div>

        {/* Scroll-to-top Button */}
        {showScrollToTop && (
          <button
            onClick={scrollToTop}
            style={scrollButtonStyles}
            aria-label="Scroll to Top"
          >
            <ChevronBarUp size={30} />
          </button>
        )}
      </footer>
    </Container>
  );
});

export default Footer;
