import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Alert, Pagination, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { NavLink, useNavigate } from "react-router-dom";
import { FaAngleLeft, FaUsers } from "react-icons/fa";
import { runData } from "../context/processor";
import { useUser } from "../hooks/useUser";
import { useProcessing } from "../context/ProcessingModal";
import ProfileCard from "./ProfileCard";
import AddCustomer from "./AddCustomer";

/**
 * CustomersPage component manages the display of customers.
 *
 * @component
 * @returns {JSX.Element} The CustomersPage component.
 */
const CustomersPage = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { setProcessing } = useProcessing();

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const customersPerPage = 12;
  // New state for search and sorting
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState("name"); // options: name, email, status, reference
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  // Ref to track if the component is still mounted to avoid updating state after unmount
  const isMounted = useRef(true);

  /**
   * Validates the customer slot and redirects if the customer's role is not permitted.
   */
  useEffect(() => {
    if (user?.slot && ![1, 2, 3, 7, 8].includes(user.slot)) {
      navigate("/");
    }
  }, [user, navigate]);

  /**
   * Loads the customers for the authenticated customer's company.
   *
   * @async
   * @function loadCustomers
   * @returns {Promise<void>}
   */
  const loadCustomers = async () => {
    setProcessing(true);
    setLoading(true);
    try {
      // Changed API endpoint to fetch customers.
      const response = await runData({}, "/api/customers/");
      if (response.status === 200) {
        if (isMounted.current) {
          // Use the "customers" property from the response.
          setCustomers(response.data.customers);
          setFetchError(null);
        }
      } else {
        if (isMounted.current) {
          setFetchError(
            response.data.message || "Failed to load customers."
          );
        }
      }
    } catch (error) {
      if (isMounted.current) {
        setFetchError(
          error.message || "Unexpected error while loading customers."
        );
      }
    } finally {
      if (isMounted.current) {
        setProcessing(false);
        setLoading(false);
      }
    }
  };

  // Load customers when the component mounts.
  useEffect(() => {
    loadCustomers();
    // Cleanup function to mark the component as unmounted.
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Listen for the "customers:reload" event to refresh the customers list.
  useEffect(() => {
    const handleReload = () => {
      loadCustomers();
    };
    window.addEventListener("customers:reload", handleReload);
    return () => {
      window.removeEventListener("customers:reload", handleReload);
    };
  }, []);

  // Process filtering and sorting
  const filteredCustomers = customers.filter(c =>
    c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    c.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    String(c.reference).includes(searchTerm)
  );

  const sortedCustomers = filteredCustomers.sort((a, b) => {
    let fieldA = a[sortField];
    let fieldB = b[sortField];
    if (typeof fieldA === "string") fieldA = fieldA.toLowerCase();
    if (typeof fieldB === "string") fieldB = fieldB.toLowerCase();
    if (fieldA < fieldB) return sortOrder === "asc" ? -1 : 1;
    if (fieldA > fieldB) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = sortedCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);
  const totalPages = Math.ceil(sortedCustomers.length / customersPerPage);

  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Customers Management - Care Quality Support</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center" style={{ gap: "1.5rem", flexGrow: 1 }}>
          <NavLink
            title="Back"
            className="active"
            style={{
              textDecoration: "none",
              color: "inherit",
              borderRight: "solid 2px red",
              marginRight: "10px",
            }}
            to={`/`}
          >
            <FaAngleLeft style={{ marginRight: "0.5rem" }} className="text-primary" />
            Back
          </NavLink>
          <div className="text-start">
            <h2 className="ms-2">
              Customers Management <FaUsers className="ms-2" />
            </h2>
            <p className="ms-2">
              Manage your customers and their access to the platform.
            </p>
          </div>
        </div>
        {/* AddCustomer button container */}
        <div>
          <AddCustomer />
        </div>
      </div>
      <hr />
      {/* New controls: Search and Sorting */}
      <Row className="mb-5 g-2 align-items-center">
        <Col md={4}>
          <Form.Control 
            placeholder="Search customers..." 
            value={searchTerm} 
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }} 
          />
        </Col>
        <Col md={4}>
          <Form.Select value={sortField} onChange={(e) => setSortField(e.target.value)}>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="status">Status</option>
            <option value="reference">Reference</option>
          </Form.Select>
        </Col>
        <Col md={4}>
          <Form.Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </Form.Select>
        </Col>
      </Row>
      {/* End of new controls */}
      {fetchError ? (
        <Row className="justify-content-center">
          <Col xs={12} md={8} className="text-center">
            <Alert variant="danger">{fetchError}</Alert>
          </Col>
        </Row>
      ) : sortedCustomers.length === 0 && !loading ? (
        <Row className="justify-content-center">
          <Col xs={12} md={8} className="text-center">
            <Alert variant="info">No customers found.</Alert>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="g-4">
            {currentCustomers.map((member) => (
              <Col key={member.reference} xs={12} md={6} lg={4}>
                <ProfileCard customer={member} />
              </Col>
            ))}
          </Row>
          {totalPages > 1 && (
            <div className="d-flex justify-content-center mt-4">
              <Pagination>
                <Pagination.Prev
                  onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
                  disabled={currentPage === 1}
                />
                {[...Array(totalPages)].map((_, idx) => (
                  <Pagination.Item
                    key={idx + 1}
                    active={idx + 1 === currentPage}
                    onClick={() => setCurrentPage(idx + 1)}
                  >
                    {idx + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => setCurrentPage((p) => Math.min(totalPages, p + 1))}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default CustomersPage;
