import React from "react";
import { useDropzone } from "react-dropzone";

/**
 * LogoDropzone Component.
 *
 * Renders a drop zone area for uploading an image file for the logo.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {File|null} props.file - The currently selected file.
 * @param {function} props.onFileAccepted - Callback invoked when a file is dropped or selected.
 * @returns {JSX.Element} The rendered LogoDropzone component.
 */
const LogoDropzone = ({ file, onFileAccepted }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        onFileAccepted(acceptedFiles[0]);
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      style={{
        border: "2px dashed rgb(173, 110, 106)",
        borderRadius: "4px",
        padding: "10px",
        textAlign: "center",
        cursor: "pointer",
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isDragActive ? "#e9f7fd" : "#ffffff",
      }}
    >
      <input {...getInputProps()} />
      {file ? (
        <p style={{ margin: 0 }}>Selected file: {file.name}</p>
      ) : (
        <p style={{ margin: 0 }}>
          {isDragActive ? "Drop here..." : "Drag & drop or select logo"}
        </p>
      )}
    </div>
  );
};

export default LogoDropzone;
