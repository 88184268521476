/**
 * @file BankingPage.jsx
 * @description A production-ready React component that allows customers to manage their banking settings
 *              and download invoices with pagination (6 invoices per page). Access is restricted to users
 *              with slots 1, 2, 3, 4, 7, or 8, and editing banking details is only available to slots 1, 2, or 4.
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { FaAngleLeft, FaWallet } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import EditBanking from "./EditBanking";
import InvoicesTable from "./InvoicesTable";
import { runData } from "../context/processor";
import { useUser } from "../hooks/useUser";

const BankingPage = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  // Allowed user slots for page access and edit functionality.
  const allowedSlots = [1, 2, 3, 4, 7, 8];
  const allowedEditSlots = [1, 2, 4];

  // Redirect if user slot is not permitted.
  useEffect(() => {
    if (user?.slot && !allowedSlots.includes(user.slot)) {
      navigate("/");
    }
  }, [user, navigate]);

  // State management for banking details and invoices.
  const [invoices, setInvoices] = useState([]);
  const [bankingDetails, setBankingDetails] = useState({
    accountName: "",
    bankName: "",
    sortCode: "",
    accountNumber: "",
    swiftBic: "",
    iban: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Fetches banking details and invoices from the backend.
   *
   * @async
   * @function fetchData
   * @returns {Promise<void>}
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch banking details
        const bankingResponse = await runData({}, "/api/banking/details/");
        if (bankingResponse.status === 200) {
          setBankingDetails(bankingResponse.data.bankingData);
        }
        // Fetch invoices
        const invoicesResponse = await runData({}, "/api/billing/invoices/");
        if (invoicesResponse.status === 200) {
          setInvoices(invoicesResponse.data.invoices);
        }
      } catch (err) {
        console.error("Error fetching banking data:", err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  /**
   * Callback to update banking details when changes are made via the EditBanking component.
   *
   * @param {Object} updatedDetails - The updated banking details.
   */
  const handleBankingUpdate = (updatedDetails) => {
    setBankingDetails(updatedDetails);
  };

  /**
   * Renders the banking details card with dynamic data.
   *
   * @returns {JSX.Element}
   */
  const renderBankingSettingsCard = () => (
    <div className="mb-5">
      <h3 className="mb-4">Banking Details</h3>
      <Row>
        <Col md={4}>
          <strong>Account Name:</strong>
          <p>{bankingDetails.accountName || "-"}</p>
        </Col>
        <Col md={4}>
          <strong>Bank Name:</strong>
          <p>{bankingDetails.bankName || "-"}</p>
        </Col>
        <Col md={4}>
          <strong>Sort Code:</strong>
          <p>{bankingDetails.sortCode || "-"}</p>
        </Col>
        <Col md={4}>
          <strong>Account Number:</strong>
          <p>{bankingDetails.accountNumber || "-"}</p>
        </Col>
        <Col md={4}>
          <strong>SWIFT/BIC:</strong>
          <p>{bankingDetails.swiftBic || "-"}</p>
        </Col>
        <Col md={4}>
          <strong>IBAN:</strong>
          <p>{bankingDetails.iban || "-"}</p>
        </Col>
      </Row>
    </div>
  );

  if (isLoading) {
    return (
      <Container fluid className="py-4 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Billing Management - Care Quality Support</title>
      </Helmet>

      {/* Page Header */}
      <Row className="align-items-center mb-3">
        <Col md={8}>
          <div className="d-flex align-items-center" style={{ gap: "1.5rem" }}>
            <NavLink
              title="Back"
              className="active"
              style={{
                textDecoration: "none",
                color: "inherit",
                borderRight: "solid 2px red",
                marginRight: "10px",
              }}
              to="/"
            >
              <FaAngleLeft
                style={{ marginRight: "0.5rem" }}
                className="text-primary"
              />
              Back
            </NavLink>
            <div>
              <h2>
                Billing Management <FaWallet className="ms-2" />
              </h2>
              <p>Banking information and invoice management.</p>
            </div>
          </div>
        </Col>
        <Col md={4} className="text-end">
          {/* Render EditBanking only for permitted user slots */}
          {user?.slot && allowedEditSlots.includes(user.slot) && (
            <EditBanking
              details={bankingDetails}
              onUpdate={handleBankingUpdate}
            />
          )}
        </Col>
      </Row>
      <hr />

      {renderBankingSettingsCard()}

      {/* Invoices Table with Pagination */}
      <InvoicesTable invoices={invoices} currentUserSlot={user?.slot || 0} />
    </Container>
  );
};

BankingPage.propTypes = {
  user: PropTypes.object,
};

export default BankingPage;
