import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import Directories from "../directories/Directories";
import ChatPage from "../composer/ChatPage";
import ProfileDisplay from "../account/profile";
import SupportPage from "../support/support";
import Shortcodes from "../dashboard/shortcodes";
import Dashboard from "../dashboard/Dashboard";
import DocumentDetails from "../directories/DirectoryFile";
import DocumentEditor from "../directories/DirectoryFileEditor";
import GetStarted from "../get-started/GetStarted";
import FourOFour from "../includes/Four0Four";
import BillingPage from "../finance/Billing";
import FormBuilder from "../assessments/FormBuilder";
import TeamPage from "../team/TeamPage";
import CompanyPage from "../company/CompanyPage";
import CustomersPage from "../customers/CustomersPage";

/**
 * Main App component that handles routing and authentication.
 * @component
 * @returns {JSX.Element}
 */
const App = () => {

  return (
    <Router>
      <Layout>
        <Routes>
          <Route
            path="/get-started/"
            element={<PublicRoute element={<GetStarted />} />}
          />
          <Route
            exact
            path="/"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            path="/directories/"
            element={<ProtectedRoute element={<Directories />} />}
          />
          <Route
            path="/directories/:directory/"
            element={<ProtectedRoute element={<Directories />} />}
          />
          <Route
            path="/directories/:directory/:document/"
            element={<ProtectedRoute element={<DocumentDetails />} />}
          />
          <Route
            path="/directories/:directory/:document/edit/"
            element={<ProtectedRoute element={<DocumentEditor />} />}
          />
          <Route
            path="/profile/"
            element={<ProtectedRoute element={<ProfileDisplay />} />}
          />
          <Route
            path="/notifications/"
            element={<ProtectedRoute element={<ProfileDisplay />} />}
          />
          <Route
            path="/billing/"
            element={<ProtectedRoute element={<BillingPage />} />}
          />
          <Route
            path="/assessments/"
            element={<ProtectedRoute element={<FormBuilder />} />}
          />
          <Route
            path="/placeholders/"
            element={<ProtectedRoute element={<Shortcodes />} />}
          />
          <Route
            path="/team/"
            element={<ProtectedRoute element={<TeamPage />} />}
          />
          <Route
            path="/company/"
            element={<ProtectedRoute element={<CompanyPage />} />}
          />

          <Route
            path="/customers/"
            element={<ProtectedRoute element={<CustomersPage />} />}
          />

          <Route
            path="/composer/"
            element={<ProtectedRoute element={<ChatPage />} />}
          />
         
          <Route
            path="*"
            element={<ProtectedRoute element={<FourOFour />} />}
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
