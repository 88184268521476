import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import MultiStepProfileModal from "./creator/MultiStepProfileModal";

/**
 * CreateProfile Component.
 *
 * Renders a "Create Profile" button that, when clicked, opens the multi-step modal form.
 * It also adds an event listener to listen for the custom "profiles:reload" event,
 * which can be used to trigger a reload of the profiles list.
 *
 * @component
 * @returns {JSX.Element} The rendered CreateProfile component.
 */
const CreateProfile = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    business_name: "",
    manager: "",
    nominated_individual: "",
    description: "",
    logo: null, // File object for the logo image
    website: "",
    email: "",
    phone: "",
    mobile: "",
    address: "",
    city: "",
    county: "",
    region: "",
    postcode: "",
    authority_name: "",
    authority_representative: "",
    authority_address: "",
    authority_phone: "",
    authority_email: "",
    authority_website: "",
  });

  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  /**
   * Handles the submission of the form.
   *
   * Immediately hides the create profile modal to avoid overlapping modals while processing.
   * On a successful API call, shows a success report, dispatches the reload event, and resets the form data.
   * In case of an error, displays an error report and, upon report confirmation, reopens the modal.
   *
   * @param {Event} e - The event object.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data object for API submission.
    const data = {
      business_name: formData.business_name,
      manager: formData.manager,
      nominated_individual: formData.nominated_individual,
      description: formData.description,
      website: formData.website,
      email: formData.email,
      phone: formData.phone,
      mobile: formData.mobile,
      address: formData.address,
      city: formData.city,
      county: formData.county,
      region: formData.region,
      postcode: formData.postcode,
      authority_name: formData.authority_name,
      authority_representative: formData.authority_representative,
      authority_address: formData.authority_address,
      authority_phone: formData.authority_phone,
      authority_email: formData.authority_email,
      authority_website: formData.authority_website,
      logo: formData.logo,
    };

    // Hide the create profile modal immediately.
    setShowModal(false);
    setProcessing(true);
    try {
      const response = await runData(data, "/api/company/create-profile/");
      if (response.status === 200) {
        setReport({
          show: true,
          message: "Profile created successfully.",
          type: "success",
        });
        // Dispatch the custom event to signal that profiles should be reloaded.
        window.dispatchEvent(new CustomEvent("profiles:reload"));

        // Reset form data after a successful submission.
        setFormData({
          business_name: "",
          manager: "",
          nominated_individual: "",
          description: "",
          logo: null,
          website: "",
          email: "",
          phone: "",
          mobile: "",
          address: "",
          city: "",
          county: "",
          region: "",
          postcode: "",
          authority_name: "",
          authority_representative: "",
          authority_address: "",
          authority_phone: "",
          authority_email: "",
          authority_website: "",
        });
      } else {
        setReport({
          show: true,
          message:
            response?.data?.message ||
            "An error occurred while creating the profile.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred while creating the profile.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Opens the create profile modal.
   */
  const handleShow = () => {
    setShowModal(true);
  };

  /**
   * Closes the create profile modal.
   */
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <FaPlus /> Add Profile
      </Button>
      <MultiStepProfileModal
        show={showModal}
        onHide={handleClose}
        onSubmit={handleSubmit}
        formData={formData}
        setFormData={setFormData}
      />
    </>
  );
};

export default CreateProfile;
