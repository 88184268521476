import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { setUserProfile } from "../account/profileData";
import { setSession } from "../utils/auth";

const AccessAccount = ({ customer }) => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [modalShow, setModalShow] = useState(false);

  const handleConfirmAccess = async () => {
    setModalShow(false);
    setProcessing(true);
    try {
      const response = await runData(
        {},
        `/api/customers/${customer.reference}/access/`
      );
      if (response.status === 200) {
        const { user } = response.data;
        await setUserProfile(user);
        setSession(true);
        setReport({ show: true, message: "Access granted.", type: "success" });
        // Redirect to home page to load the new user’s session.
        window.location.href = "/";
      } else {
        setReport({
          show: true,
          message: response.data.message || "Access failed.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: "Error: " + error.message,
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button variant="secondary" size="sm" onClick={() => setModalShow(true)}>
        Access Account
      </Button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Access Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to access account for {customer.name}?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmAccess}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AccessAccount.propTypes = {
  customer: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default AccessAccount;
