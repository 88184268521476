import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import DOMPurify from "dompurify";
import Restricted from "../includes/Restricted";
import ErrorPage from "../includes/ErrorPage";
import Processing from "../includes/Processing";
import DirectoryFolders from "./DirectoryFolders";
import BlankPage from "./DirectoryBlank";
import { useUser } from "../hooks/useUser";
import {
  checkRefreshTime,
  getDirectoryData,
  saveDirectoryData,
} from "./directoriesData";
import { runData } from "../context/processor";

const Directories = () => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    directories: [],
    documents: [],
    isRestricted: false,
  });
  const location = useLocation();
  const { directory } = useParams(); // Extract directory parameter from the URL
  const [pageTitle, setPageTitle] = useState("Opening"); // Directory title
  const [pageParent, setPageParent] = useState(null); // Directory parent
  const slot = useUser().slot; // Get user's slot information

  // Add this useEffect to listen for a direct server refresh event
  useEffect(() => {
    // Define the event handler that fetches fresh data from the server
    const handleServerRefresh = async () => {
      const parent = getParentDirectory(directory);
      // Always fetch data from the API, bypassing local cache
      await fetchAndApplyData(parent);
    };

    // Attach the event listener for "serverRefresh"
    window.addEventListener("serverRefresh", handleServerRefresh);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("serverRefresh", handleServerRefresh);
    };
  }, [directory]);

  useEffect(() => {
    const fetchDirectoriesData = async () => {
      try {
        // Use the helper function to determine the parent directory
        const parent = getParentDirectory(directory);

        // Check if the refresh time for the cached data is expired
        const needsRefresh = await checkRefreshTime(parent);

        if (needsRefresh) {
          // Fetch data from the API and apply it
          await fetchAndApplyData(parent);
        } else {
          // Use cached data since it is still recent
          const cachedData = await getDirectoryData(parent);
          if (cachedData) {
            applyCachedData(cachedData.data);
          } else {
            // Fallback if no cached data is found, make one last attempt to fetch from the API
            await fetchAndApplyData(parent);
          }
        }
      } catch (err) {
        handleFetchError(err);
      }
    };

    fetchDirectoriesData();
  }, [location, directory, slot]);

  // Listen to directory change event to re-fetch directories from local database
  useEffect(() => {
    const handleDirectoryChange = async () => {
      const parent = getParentDirectory(directory);
      const cachedData = await getDirectoryData(parent);
      if (cachedData) {
        applyCachedData(cachedData.data);
      } else {
        // Fallback if no cached data is found, make one last attempt to fetch from the API
        await fetchAndApplyData(parent);
      }
    };

    window.addEventListener("directoryChanged", handleDirectoryChange);

    return () => {
      window.removeEventListener("directoryChanged", handleDirectoryChange);
    };
  }, [directory]);

  // Helper function to determine parent directory
  const getParentDirectory = (directory) => {
    return typeof directory === "string" &&
      directory.length > 0 &&
      directory !== "directory"
      ? directory
      : "root";
  };

  // Helper function to apply cached data to the state
  const applyCachedData = (cachedData) => {
    setPageTitle(cachedData?.current?.title || "Directories");
    setPageParent(cachedData?.parent?.reference);

    setState((prevState) => ({
      ...prevState,
      directories: cachedData.directories,
      documents: cachedData.documents,
      loading: false,
    }));
  };

  // Helper function to fetch data from API and apply it
  const fetchAndApplyData = async (parent) => {
    const data = { parent };
    const responseData = await runData(data, `/api/directories/`);

    if (responseData?.data?.code === 200) {
      applyFetchedData(responseData.data);
      await saveDirectoryData(parent, responseData.data);
    } else {
      handleApiError(responseData);
    }
  };

  // Helper function to apply fetched data to the state
  const applyFetchedData = (data) => {
    setPageTitle(data?.current?.title || "Directories");
    setPageParent(data?.parent?.reference);

    setState((prevState) => ({
      ...prevState,
      directories: data.directories,
      documents: data.documents,
      loading: false,
    }));
  };

  // Helper function to handle fetch error
  const handleFetchError = (err) => {
    setPageTitle("Error");
    setState((prevState) => ({
      ...prevState,
      isRestricted: err.status === 402,
      error: err.status !== 402 ? DOMPurify.sanitize(err.message) : null,
      loading: false,
    }));
  };

  // Helper function to handle API errors
  const handleApiError = (responseData) => {
    setPageTitle("Error");
    const errorMessage = responseData?.data
      ? DOMPurify.sanitize(responseData.data.message)
      : "An error occurred while fetching data.";

    setState((prevState) => ({
      ...prevState,
      error: errorMessage,
      loading: false,
    }));
  };

  const { directories, documents, loading, error, isRestricted } = state;

  if (loading) {
    return <Processing />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isRestricted) {
    return <Restricted product="" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle} - Care Quality Support</title>
      </Helmet>
      <Container className="mb-4 mt-4" fluid>
        <Row>
          {/* Main Area */}
          <Col>
            {directories.length === 0 && documents.length === 0 ? (
              <BlankPage parent={pageParent} />
            ) : (
              <DirectoryFolders
                directories={directories}
                parent={pageParent}
                title={pageTitle}
                documents={documents}
              />
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Directories;
