import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FaMap, FaMapMarkerAlt, FaBuilding, FaMailBulk, FaHome } from "react-icons/fa";

/**
 * StepAddressInfo Component.
 *
 * Renders the address information step of the multi-step form arranged according to
 * typical UK address conventions. The fields are arranged as:
 *   - Row 1: Address Line (building number/name and street)
 *   - Row 2: City and County
 *   - Row 3: Region and Postcode (with Postcode as the final field)
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.formData - The form data for the profile.
 * @param {function} props.handleChange - Callback to update the form data.
 * @returns {JSX.Element} The rendered component.
 */
const StepAddressInfo = ({ formData, handleChange }) => {
  // List of regions (UK countries)
  const regions = ["England", "Scotland", "Wales", "Northern Ireland"];

  // List of major cities in the UK
  const cities = [
    // England
    "London",
    "Birmingham",
    "Manchester",
    "Leeds",
    "Liverpool",
    "Sheffield",
    "Bristol",
    "Newcastle upon Tyne",
    "Nottingham",
    "Leicester",
    "Southampton",
    "Portsmouth",
    "Brighton",
    "Plymouth",
    "Reading",
    "Milton Keynes",
    "Northampton",
    "Derby",
    "Oxford",
    "Cambridge",
    "Stoke-on-Trent",
    "Wolverhampton",
    "York",
    "Norwich",
    "Bournemouth",
    "Peterborough",
    // Scotland
    "Glasgow",
    "Edinburgh",
    "Aberdeen",
    "Dundee",
    "Inverness",
    "Perth",
    "Stirling",
    "Ayr",
    "Dumfries",
    "Falkirk",
    // Wales
    "Cardiff",
    "Swansea",
    "Newport",
    "Bangor",
    "Wrexham",
    "St Davids",
    "Barry",
    // Northern Ireland
    "Belfast",
    "Derry",
    "Lisburn",
    "Newry",
    "Armagh",
    "Coleraine"
  ];

  // List of UK counties and equivalent areas
  const counties = [
    // England
    "Bedfordshire",
    "Berkshire",
    "Bristol",
    "Buckinghamshire",
    "Cambridgeshire",
    "Cheshire",
    "Cornwall",
    "County Durham",
    "Cumbria",
    "Derbyshire",
    "Devon",
    "Dorset",
    "East Riding of Yorkshire",
    "East Sussex",
    "Essex",
    "Gloucestershire",
    "Greater London",
    "Greater Manchester",
    "Hampshire",
    "Herefordshire",
    "Hertfordshire",
    "Isle of Wight",
    "Kent",
    "Lancashire",
    "Leicestershire",
    "Lincolnshire",
    "Merseyside",
    "Norfolk",
    "North Yorkshire",
    "Northamptonshire",
    "Northumberland",
    "Nottinghamshire",
    "Oxfordshire",
    "Rutland",
    "Shropshire",
    "Somerset",
    "South Yorkshire",
    "Staffordshire",
    "Suffolk",
    "Surrey",
    "Tyne and Wear",
    "Warwickshire",
    "West Midlands",
    "West Sussex",
    "West Yorkshire",
    "Wiltshire",
    "Worcestershire",
    // Wales
    "Anglesey",
    "Blaenau Gwent",
    "Bridgend",
    "Caerphilly",
    "Cardiff",
    "Carmarthenshire",
    "Ceredigion",
    "Conwy",
    "Denbighshire",
    "Flintshire",
    "Gwynedd",
    "Merthyr Tydfil",
    "Monmouthshire",
    "Neath Port Talbot",
    "Newport",
    "Pembrokeshire",
    "Powys",
    "Rhondda Cynon Taf",
    "Swansea",
    "Torfaen",
    "Vale of Glamorgan",
    "Wrexham",
    // Scotland
    "Aberdeenshire",
    "Angus",
    "Argyll and Bute",
    "Ayrshire",
    "Clackmannanshire",
    "Dumfries and Galloway",
    "Dunbartonshire",
    "East Lothian",
    "Fife",
    "Highland",
    "Inverclyde",
    "Midlothian",
    "Moray",
    "Orkney",
    "Perth and Kinross",
    "Renfrewshire",
    "Scottish Borders",
    "Shetland",
    "Stirlingshire",
    "West Lothian",
    // Northern Ireland
    "Antrim",
    "Armagh",
    "Down",
    "Fermanagh",
    "Londonderry",
    "Tyrone"
  ];

  return (
    <>
      <h5 className="mb-3">
        <FaMapMarkerAlt className="me-2" />
        Address Information
      </h5>

      {/* Row 1: Full Address Line */}
      <Row className="mb-3 mt-4">
        <Col md={12}>
          <Form.Group controlId="address" className="mb-3">
            <Form.Label>
              <FaHome className="me-1" />
              Address Line
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter building number, street, etc."
              value={formData.address}
              onChange={(e) => handleChange("address", e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 2: City and County */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="city" className="mb-3">
            <Form.Label>
              <FaBuilding className="me-1" />
              City
            </Form.Label>
            <Typeahead
              id="city-typeahead"
              options={cities}
              placeholder="Select or type city"
              onChange={(selected) => handleChange("city", selected[0] || "")}
              selected={formData.city ? [formData.city] : []}
              allowNew
              newSelectionPrefix="Add: "
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="county" className="mb-3">
            <Form.Label>
              <FaMap className="me-1" />
              County
            </Form.Label>
            <Typeahead
              id="county-typeahead"
              options={counties}
              placeholder="Select or type county"
              onChange={(selected) => handleChange("county", selected[0] || "")}
              selected={formData.county ? [formData.county] : []}
              allowNew
              newSelectionPrefix="Add: "
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 3: Region and Postcode */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="region" className="mb-3">
            <Form.Label>
              <FaMap className="me-1" />
              Region
            </Form.Label>
            <Typeahead
              id="region-typeahead"
              options={regions}
              placeholder="Select or type region"
              onChange={(selected) =>
                handleChange("region", selected[0] || "")
              }
              selected={formData.region ? [formData.region] : []}
              allowNew
              newSelectionPrefix="Add: "
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="postcode" className="mb-3">
            <Form.Label>
              <FaMailBulk className="me-1" />
              Postcode
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter postcode"
              value={formData.postcode}
              onChange={(e) =>
                handleChange("postcode", e.target.value.toUpperCase())
              }
              maxLength="8"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default StepAddressInfo;
