import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  FaTimes,
  FaSave,
  FaArrowLeft,
  FaArrowRight,
  FaUserPlus,
} from "react-icons/fa";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { runData } from "../context/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { useUser } from "../hooks/useUser";

/**
 * CreateUser component renders a modal for creating a new user.
 * The form is divided into two steps and, on submission, the modal is hidden immediately
 * while an API call is made using runData. This component is self-contained and can be used as <CreateUser />.
 *
 * @component
 * @returns {JSX.Element} The CreateUser modal component.
 */
const CreateUser = () => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const { user } = useUser();

  // Local state for modal visibility.
  const [modalShow, setModalShow] = useState(false);

  // Local state to control the current form step.
  const [currentStep, setCurrentStep] = useState(1);

  // Define the full mapping of roles.
  const roles = [
    { slot: 1, label: "Developer" },
    { slot: 2, label: "Director" },
    { slot: 3, label: "Manager" },
    { slot: 4, label: "Finance" },
    { slot: 5, label: "Supervisor" },
    { slot: 6, label: "Staff" },
    { slot: 7, label: "Director" },
    { slot: 8, label: "Manager" },
    { slot: 9, label: "Supervisor" },
    { slot: 10, label: "Staff" },
  ];

  // Determine allowed roles based on the current user's slot.
  let allowedRoles = [];
  if ([1, 2, 3].includes(user.slot)) {
    allowedRoles = roles.filter((role) => role.slot >= 1 && role.slot <= 6);
  } else if ([7, 8].includes(user.slot)) {
    allowedRoles = roles.filter((role) => role.slot >= 7 && role.slot <= 10);
  }

  // Title options for the Typeahead component.
  const titleOptions = ["Mr", "Mrs", "Ms", "Dr", "Prof"];

  // State for available profiles.
  const [allProfiles, setAllProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState([]);

  // State for role selection using Typeahead.
  const [selectedRole, setSelectedRole] = useState([]);

  // State for form data.
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    slot: "",
  });

  /**
   * Loads available profiles when the modal is shown.
   *
   * @async
   * @function loadProfiles
   */
  useEffect(() => {
    if (modalShow) {
      const loadProfiles = async () => {
        setProcessing(true);
        try {
          const response = await runData({}, "/api/company/");
          if (response.status === 200) {
            const profiles = response.data.profiles;
            setAllProfiles(profiles);
            // Pre-select profile if only one is available.
            if (profiles.length === 1) {
              setSelectedProfile(profiles);
            }
          }
        } catch (error) {
          // Optional: add error logging or additional error handling here.
        } finally {
          setProcessing(false);
        }
      };
      loadProfiles();
    }
  }, [modalShow, setProcessing]);

  /**
   * Resets form data, selections, and current step each time the modal is shown.
   */
  useEffect(() => {
    if (modalShow) {
      setFormData({
        title: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
        slot: "",
      });
      setSelectedProfile([]);
      setSelectedRole([]);
      setCurrentStep(1);
    }
  }, [modalShow]);

  /**
   * Listens for the "team:create" event to open the modal.
   */
  useEffect(() => {
    const handleCreate = () => {
      setModalShow(true);
    };
    window.addEventListener("team:create", handleCreate);
    return () => {
      window.removeEventListener("team:create", handleCreate);
    };
  }, []);

  /**
   * Handles changes for form input fields.
   *
   * @param {string} field - The name of the field to update.
   * @param {string} value - The new value for the field.
   */
  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  /**
   * Moves to the next step in the form.
   */
  const handleNext = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  /**
   * Moves to the previous step in the form.
   */
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Validation for Step 1: first name, surname, and email are required.
  const isStepOneValid =
    formData.name.trim() !== "" &&
    formData.surname.trim() !== "" &&
    formData.email.trim() !== "";

  // Validation for Step 2: role and profile (if available) are required.
  const isStepTwoValid =
    selectedRole.length > 0 &&
    (allProfiles.length > 0 ? selectedProfile.length > 0 : true);

  // Overall form validation.
  const isFormValid = isStepOneValid && isStepTwoValid;

  /**
   * Handles form submission to create a new user.
   * The modal is hidden immediately to avoid overlapping displays.
   *
   * @async
   * @function handleSubmit
   */
  const handleSubmit = async () => {
    // Hide the modal immediately.
    setModalShow(false);
    setProcessing(true);
    try {
      const profileValue =
        selectedProfile.length > 0 ? selectedProfile[0] : null;
      // Convert the selected role slot to a number.
      const slotNumber =
        selectedRole.length > 0 ? parseInt(selectedRole[0].slot, 10) : null;

      // Sanitize phone number by removing any whitespace characters.
      const sanitizedPhone = formData.phone
        ? formData.phone.replace(/\s+/g, "")
        : "";

      // Construct the new user object with sanitized data.
      const newUser = {
        ...formData,
        phone: sanitizedPhone,
        slot: slotNumber,
        profile: profileValue.reference,
      };

      // Make the API call using runData.
      const response = await runData(newUser, "/api/team/create-user/");
      if (response.status === 200) {
        setReport({
          show: true,
          message: response?.data?.message || "User created successfully.",
          type: "success",
        });
        // Dispatch an event to reload team members.
        window.dispatchEvent(new Event("team:reload"));
      } else {
        setReport({
          show: true,
          message:
            response?.data?.message ||
            "An error occurred while creating the user.",
          type: "error",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error creating user.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Renders the appropriate form fields for the current step.
   *
   * @function renderFormStep
   * @returns {JSX.Element} The form content for the current step.
   */
  const renderFormStep = () => {
    if (currentStep === 1) {
      return (
        <>
          <Form.Group controlId="title" className="mb-3">
            <Form.Label>Title (optional)</Form.Label>
            <Typeahead
              id="title-typeahead"
              options={titleOptions}
              placeholder="Choose a title..."
              onChange={(selected) => handleChange("title", selected[0] || "")}
              selected={formData.title ? [formData.title] : []}
              clearButton
            />
          </Form.Group>
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter first name"
              value={formData.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="surname" className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter surname"
              value={formData.surname}
              onChange={(e) => handleChange("surname", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="phone" className="mb-3">
            <Form.Label>Phone (optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter phone number"
              value={formData.phone}
              onChange={(e) => handleChange("phone", e.target.value)}
            />
          </Form.Group>
        </>
      );
    }
    if (currentStep === 2) {
      return (
        <>
          <Form.Group controlId="role" className="mb-3">
            <Form.Label>User Role</Form.Label>
            <Typeahead
              id="role-typeahead"
              options={allowedRoles}
              placeholder="Select a role..."
              labelKey="label"
              onChange={(selected) => {
                setSelectedRole(selected);
                handleChange(
                  "slot",
                  selected.length > 0 ? selected[0].slot.toString() : ""
                );
              }}
              selected={selectedRole}
              clearButton
            />
          </Form.Group>
          <Form.Group controlId="profile" className="mb-3">
            <Form.Label>Company Profile</Form.Label>
            <Typeahead
              id="profile-typeahead"
              labelKey={(option) =>
                `${option.business_name} - ${option.business_description}`
              }
              options={allProfiles}
              placeholder="Select a profile..."
              onChange={(selected) => setSelectedProfile(selected)}
              selected={selectedProfile}
              disabled={allProfiles.length === 1}
              clearButton
            />
          </Form.Group>
        </>
      );
    }
    return null;
  };

  /**
   * Renders the modal footer buttons based on the current step.
   *
   * @function renderFooterButtons
   * @returns {JSX.Element} The modal footer content.
   */
  const renderFooterButtons = () => {
    if (currentStep === 1) {
      return (
        <>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            <FaTimes className="me-1" />
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleNext}
            disabled={!isStepOneValid}
          >
            <FaArrowRight className="me-1" />
            Next
          </Button>
        </>
      );
    }
    if (currentStep === 2) {
      return (
        <>
          <Button variant="secondary" onClick={handleBack}>
            <FaArrowLeft className="me-1" />
            Back
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!isStepTwoValid || !isFormValid}
          >
            <FaSave className="me-1" />
            Create User
          </Button>
        </>
      );
    }
  };

  return (
    <>
      {/* Optionally, you can add a button here to open the modal manually */}
      <Button variant="primary" onClick={() => setModalShow(true)}>
        <FaUserPlus /> Add User
      </Button>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>{renderFormStep()}</Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {renderFooterButtons()}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateUser;
