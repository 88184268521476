import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { FaFile, FaTrash } from "react-icons/fa";
import FileUpload from "./components/FileUpload";

const AttachmentsPanel = ({ threadId, previewFiles, onRemoveFile, onFileSelect }) => {
  return (
    <div>
      {/* Header */}
      <div style={{ background: "#fff", padding: "0.5rem 0", marginBottom: "1rem" }} className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Attachments</h5>
        <FileUpload iconOnly={false} threadId={threadId} onFileSelect={onFileSelect} />
      </div>
      {/* List */}
      <div style={{ overflowY: "auto", maxHeight: "calc(80vh - 3rem)" }} className="small">
        {previewFiles.length > 0 ? (
          previewFiles.map((file, index) => (
            <div key={index} className="d-flex align-items-center border rounded p-2 mb-3">
              <div className="me-2">
                <FaFile className="text-muted" size={20} />
              </div>
              <span className="text-truncate" style={{ flex: 1, marginRight: "0.5rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={file.name}>
                {file.name}
              </span>
              <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); onRemoveFile(index); }}>
                <FaTrash />
              </Button>
            </div>
          ))
        ) : (
          <p className="text-center">No attachments selected.</p>
        )}
      </div>
    </div>
  );
};

AttachmentsPanel.propTypes = {
  threadId: PropTypes.any.isRequired,
  previewFiles: PropTypes.array.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
};

export default AttachmentsPanel;
